import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Icon, message , Modal } from 'antd'
import Button from 'antd/es/button';
import Menu from 'antd/es/menu'
import Input from 'antd/es/input'
import back from '../../assets/images/login-registor-image.png'
import lingdaima from '../../assets/images/2429495A-4968-4a48-B4E2-55632BD8EEF7.png'
import account from '../../assets/images/B3EAD484-D801-41bb-BFC5-D477D51DF290.png'
import password from '../../assets/images/00FF5FB9-39CF-4771-98D7-8354D5F090DB.png'
import select from '../../assets/images/select.png'
import line from '../../assets/images/1.png'
import './Choose.less'
import { Ajax } from '../../utils/ajax';
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import {Link} from 'react-router-dom';

message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
  });

  const { confirm } = Modal;
class Choose extends Component {
    state = {
        current: '7',
        forget: false,
        zhuce: false,
        fill: false,
        visible:false,
        merchantName:"",
        menuId:''
    };
    componentDidMount() {
    }
    componentWillMount() {
    }

    isLogin(menuId){
        let token = sessionStorage.getItem("backToken")
        console.log(token)
        if(token == null){
            message.error('您还没有登陆')
            window.location.href = "/login" 
        }
        else{
            // this.props.history.push(`/CreateShop/${menuId}`);
            this.setState({
                visible:true,
                merchantName:"",
                menuId:menuId
            })
        }
    }
    onChange(e){
        console.log(e.target.value)
        this.setState({
            merchantName:e.target.value
        })
    }
    handle(){
        let token = sessionStorage.getItem("backToken")
        console.log(token)
        if(token == null){
            message.error('您还没有登陆')
            window.location.href = "/login" 
            return false;
        }
        if(!this.state.menuId){
            message.error('请选择店铺版本')
            return false;
        }
        if(!this.state.merchantName){
            message.error('请输入店铺名称')
            return false;
        }
        Ajax.POST('/ldmshop/merchant/add', { merchantName: this.state.merchantName,mainServiceClass:'电商',menuId:this.state.menuId })
        .then(res => {
            if(res && res.code === 0){
                this.setState({
                    visible:false,
                })
                this.showConfirm(res.data.merchantId);
            }
            else {
                message.error(res.errmsg,2)
            }
        })
        .catch(error => {
            message.error(error)
        })
    }
    showConfirm(id) {
        let that = this;
        confirm({
          title: "提示",
          content: "店铺创建成功，马上去查看效果",
          cancelText:"取消",
          okText:"确认",
          onOk() {
            that.props.history.push("/admin/testh5",{id:id});
            sessionStorage.setItem("key", 14);
          },
          onCancel() {
            that.props.history.push("/admin/listPage");
          },
        });
      }
    render() {
        return (
            <div id="zuiwai">
                <Header type="home" active="home" cur="home"></Header>
                <div className="zuiwaitab">
                        <div>创建店铺</div>
                        <div className="line">
                            <img src={line}></img>
                        </div>
                        <div>添加商品</div>
                        <div className="line">
                            <img src={line}></img>
                        </div>
                        <div>发布小程序</div>
                    </div>
                <div id="choose">
                    <div className="level">
                        <p className="title">基础版</p>
                        <div className="label1">最简单的商品交易店铺</div>
                        <div className="content-box">
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>商品交易、购物车、消息通知</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>限时促销</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>免费试用7天</div>
                            </div>
                        </div>
                        <div className="price" style={{ marginTop: '20px' }}>
                             <div>98元/月</div> 
                             <div>980元/年(83折)</div> 
                        </div>
                        <div className="shiyong" onClick={this.isLogin.bind(this,1)}>
                            {/* <a href='/choosemodel'>试用</a> */}
                            <span style={{color:'white',fontSize:'20px',fontFamily:'Microsoft YaHei'}}>免费试用</span>
                        </div>
                    </div>
                    <div className="level">
                        <p className="title">分销版</p>
                        <div className="label1">分销+VIP体系，分享裂变</div>
                        <div className="content-box">
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>商品交易、购物车、消息通知</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>商品交易、购物车、消息通知</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>分销体系、VIP体系、佣金提现</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>数据分析</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>免费试用7天</div>
                            </div>
                        </div>
                        <div className="price" style={{ marginTop: '20px' }}>
                            <div>198元/月</div> 
                            <div>1980元/年(83折)</div>
                        </div>
                        <div className="shiyong" onClick={this.isLogin.bind(this,2)}>
                        <span style={{color:'white',fontSize:'20px',fontFamily:'Microsoft YaHei'}}>免费试用</span>
                        </div>
                    </div>
                    <div className="level">
                        <div className="title-2">
                            <p className="title">直播版</p>
                            <div className="hot"><p>推荐</p></div>
                        </div>
                        <div className="label1">直播+VIP体系，直观，快速转化</div>
                        <div className="content-box">
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>商品交易、购物车、消息通知</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>限时促销、专题聚合、集市分享</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>分销体系、VIP体系、佣金提现</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>数据分析</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>直播带货</div>
                            </div>
                            <div>
                                <div> <img src={select} ></img> </div>
                                <div>免费试用7天</div>
                            </div>
             
                        </div>
                        
                        <div className="price" style={{ marginTop: '10px' }}>
                            <div>298元/月</div>
                            <div>2980元/年(83折)</div>
                        </div>
                        <div className="shiyong" onClick={this.isLogin.bind(this,3)}>
                            <span style={{color:'white',fontSize:'20px',fontFamily:'Microsoft YaHei'}}>免费试用</span>
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div className="wechatCode">
                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/sifshopAuth.jpeg"></img>
                    <div>微信扫码看案例</div>
                </div>
                <Footer></Footer>
                <Modal
                    title="请输入店铺名称完成创建"
                    visible={this.state.visible}
                    onOk={this.handle.bind(this)}
                    onCancel={()=>{this.setState({visible:false})}}
                    okText="确认"
                    cancelText="取消"
                    >
                        <Input onChange={this.onChange.bind(this)} value={this.state.merchantName}></Input>
                </Modal>
            </div>
        )
    }
}
export default Choose;