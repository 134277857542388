

import  './modal.css'
var React = require("react");
var ReactDom = require("react-dom");
var createReactClass = require('create-react-class');
const $ = window.$;
export  const Modal = createReactClass({
    getDefaultProps: function () {
        return {
            theme : '',
            text : '',
            iconfont : 'icon-caozuoshibai'
        }
    },

    componentDidMount: function () {
        this.show();
        $(document).on("messageTip", function (ev, data) {
            var Id = data.Id;
            console.log(data)
            if(data.Tip) $(Id + ' .modal-header-left').html(data.Tip);

            if(data.icon) $(Id + ' .save-tip .save-img span').addClass(data.icon); 

            if(data.errorTxt) $(Id + ' .save-tip .save-text').html(data.errorTxt);

            $(Id).modal('show');

        })


    },

    componentDidUpdate: function () {
        this.show();
    },

   
    theme(){
        if(this.props.theme == 'theme-1'){
            return( <div className='save-tip'>
                        <div className='save-img'>
                            <span className={'iconfont '+ this.props.iconfont}>
                            </span>
                        </div>
                        <div className='save-text'>
                            {this.props.text}
                        </div>
                        <div className='tip-success-btn'>
                            <div data-dismiss="modal" onClick={this.props.confirmEnvent?this.props.confirmEnvent : ''}>{this.props.successText ? this.props.successText : '确认'}</div>
                        </div>
                   </div>
            )   
        }
    },
    show(){
        if(this.props.theme =='theme-1') $('#'+this.props.id).addClass('')
        if(this.props.isshow)  $('#'+this.props.id).modal('show');
    },  
    render: function () {
         var Class ='';
         Class = this.props.Class ? this.props.Class : '';
        return (
            <div className={'modal fade models-layer'} data-theme={this.props.theme} id={this.props.id} role="dialog ">
                <div className="modal-dialog"  role="document">
                    <div className="modal-content">
                        <div className='modal-header'>
                            <div className='modal-header-left'>{this.props.tip}</div>
                            <div className='modal-header-right'>
                                <a className='add-components' onClick={this.props.clear} title="关闭" className="add-tianjia" data-dismiss="modal">
                                    <span className="icon iconfont icon-quxiao"></span>
                                </a>
                            </div>
                        </div>
                        <div className='modal-body'>
                            {this.theme()}
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
})


