import Header from '../../common/Header'
import Footer from '../../common/Footer'

import './index.css';
const $ = window.$;
var React = require("react");

var createReactClass = require('create-react-class');

export default createReactClass({

    componentDidMount () {
        $('.web').scrollTop(0);
    },
    componentWillUnmount () {
    },
    render: function () {
        return (
            <div id="legalnotice-Page" className='legalnotice-page'>
                <Header type="legalnotice" active="legalnotice" cur="legalnotice"></Header>
                <div  id='aboutus' className='container legalnotice-container'>

                    <div className="row">

                        <div className='legal-notice col-md-12 col-sm-12 col-xs-12 center-block'>
                           <div  className=' legalnotice-header col-md-12 col-sm-12 col-xs-12'>
                                <h3 className='col-md-4 col-sm-6 col-xs-12 '>法律声明</h3>
                            </div>
                            <div className='legalnotice-list'>

                                <p>1、在进入茜芙科技平台后或使用茜芙科技平台各项服务前，请您务必仔细阅读并透彻理解本声明，您可以选择不使用茜芙科技平台服务，但如果您使用茜芙科技平台服务的，您的使用行为将被视为对本声明全部内容的认可，“茜芙科技平台”指由杭州茜芙科技科技有限公司（简称“茜芙科技公司”）运营的各产品服务系统平台，域名为 sifshop.cn以及茜芙科技公司启用的其他域名。</p>
                                <p className='subbit'>2、鉴于：</p>

                                <p className='subbit'>茜芙科技公司提供的服务属于企业自助搭建服务，用户在茜芙科技平台注册后，即可通过茜芙科技平台搭建网站、创建小程序等，且通过茜芙科技平台生成的网站、微信小程序上发布的相关资讯、产品、服务（包括但不限于企业名称、联系人及联络信息，产品/服务的描述和说明，图片、视频，视讯等）的信息均由用户自行提供上传，茜芙科技公司仅提供信息存储空间服务，但由于茜芙科技公司无法对用户所上传的内容是否违法、侵权进行有效判断，为了维护互联网秩序、维护广大权利人的合法权益，因此：</p>

                                <p className='subbit'>(1) 茜芙科技公司保证用户所上传内容的完整性，不对其进行任何形式的编辑、更改、篡改；</p>

                                <p className='subbit'>(2) 用户依法应当保证所上传的内容不违反法律规定、不损害他人的合法权益，否则应承担全部、最终责任；</p>

                                <p className='subbit'>(3) 茜芙科技公司有权配合网络监管部门对用户所上传的内容进行关键字、关键图片等关键信息的筛查，并对违法、侵权网站、微信小程序等作出关闭措施，或删除违法、侵权信息，或断开违法、侵权信息链接等；</p>

                                <p>(4) 若用户所上传的内容侵权的，权利人可通过茜芙科技平台公布的邮箱、地址等联系方式，向茜芙科技公司发出书面通知，要求茜芙科技公司关闭违法、侵权网站，或删除违法、侵权信息，或断开违法、侵权信息链接，同时提交权属证明、详细的侵权情况说明以及身份证明（自然人提供身份证复印件、法人提供营业执照复印件加盖公章），茜芙科技公司在收到上述材料后会采取相应措施，防止侵权损害的进一步扩大，权利人（通知人）应当保证用户侵权的真实性，否则应当承担由此造成的全部法律责任；</p>

                                <p>3、除茜芙科技平台注明之服务条款外，用户在使用茜芙科技平台服务过程中发生的任何意外、疏忽、合约毁坏、诽谤、版权或知识产权侵权及其所造成的损失（包括因下载而感染电脑病毒），茜芙科技公司不承担任何法律责任。</p>

                                <p>4、茜芙科技产品中的部分字体、图片等涉及第三人的知识产权且该第三人已许可茜芙科技公司使用，若您在使用茜芙科技产品时需要用到该部分作品的，请您向知识产权人取得授权，否则您将可能侵害知识产权人的合法权利，由此造成的纠纷，您应当自行与知识产权人解决，与茜芙科技公司无关。若因您侵害第三人的权利导致茜芙科技公司向第三人承担责任的，茜芙科技公司有权向您追偿。</p>

                                <p className='subbit'>5、茜芙科技公司保留随时修改茜芙科技服务条款及法律声明之权利，并通过网络于茜芙科技平台公示修改之后内容，不另行个别通知，若您于服务条款、法律声明修改后仍继续使用茜芙科技平台的，即表示您同意修改后之内容。</p>

                                <p>以上声明，您可随时在茜芙科技平台阅读、复制或下载。</p>

                            </div>
                        </div>

                    </div>

                </div>
                

                <Footer cur="legalnotice"> </Footer>
            </div>
        );
    }
});
