// import '../../utils/upload'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import UploadImgs from '../../components/upload/UploadImgs'
require('./msg.css');

var createReactClass = require('create-react-class');
var React = require("react");
var login = require("../../common/login")

// var UploadButton =require('./imgupload');


const $ = window.$;
const location = window.location
export default createReactClass({

    getInitialState: function () {
        return {
            msgerror: '',
            nameerrorr: '',
            emailerror: '',
            imgerrror: '',
            errorId: '*',
            photolist: []
        };
    },

    checkoutMsg() {
        var msg = $(this.refs["msgbacktxt"]);
        if (msg.val()) {
            this.setState({ msgerror: "" });
            return true;
        } else {
            this.setState({ msgerror: "亲，请输入反馈原因" });
        }
    },

    checkoutName() {
        var name = $(this.refs["name"]);
        if (name.val()) {
            this.setState({ nameerror: "" });
            return true;
        } else {
            this.setState({ nameerror: "亲，请输入你的名字" });
        }
    },

    checkoutEmail() {
        var email = $(this.refs["email"]);
        if (!email) {
            this.setState({ emailerror: "请输入您的邮箱账号" })
        }
        else if (!/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(email.val())) {
            this.setState({ emailerror: "邮箱格式不正确" })
        } else {
            this.setState({ emailerror: "" });
            return true;
        }
    },


    componentDidMount: function () {

        var self = this;

        var msg = $(this.refs["msgbacktxt"]);

        var name = $(this.refs["name"]);

        var email = $(this.refs["email"]);

        console.log(login.getUser());
        // if (login.isLogin()) {
        //     var user = login.getUser();
        //     name.val(user.username);
        //     if (user.email.indexOf("@") != -1) {
        //         email.val(user.email);
        //     }
        // }


        msg.on("blur", function () {
            self.checkoutMsg()
        })

        name.on("blur", function () {
            self.checkoutName()
        })


        email.on("blur", function () {
            self.checkoutEmail()
        })

        this.uploadmsgbackimg();


    },

    valid: function () {
        var self = this;
        self.checkoutMsg()
        self.checkoutName()
        self.checkoutEmail()
        if (self.state.msgerror == "" && self.state.nameerror == "" && self.state.emailerror == "") {
            return true
        }
    },

    uploadmsgbackimg: function () {

        var self = this;

        let url = '/json/tester/msgback/msgbackimg/';
        // var $input = $("#msgbackimg").html5_upload({
        //     url: url,
        //     sendBoundary: window.FormData || $.browser.mozilla,
        //     onStart: function (event, total) {
        //         var imgerror = ''
        //         let iMaxFilesize = 4 * 1024 * 1024; //3MB

        //         var oFile = document.getElementById('msgbackimg').files[0];

        //         // oFile.name = oFile.name.split('.')[0]+'_12'+oFile.name.split('.')[1]

        //         let rFilter = /^(image\/gif|image\/jpeg|image\/png)$/i;

        //         if (!rFilter.test(oFile.type)) {
        //             document.getElementById('msgbackimg').value = '';
        //             self.setState({
        //                 imgerror: '图片类型出错'
        //             })
        //             self.forceUpdate();

        //             return false;
        //         } else {
        //             self.setState({
        //                 imgerror: ''
        //             })
        //         }

        //         if (oFile.size > iMaxFilesize) {
        //             document.getElementById('msgbackimg').value = '';
        //             self.setState({
        //                 imgerror: '图片超过4M大小限定'
        //             })

        //             return false;
        //         } else {
        //             self.setState({
        //                 imgerror: ''
        //             })
        //         }
        //         self.start()
        //         return true;
        //     },
        //     fieldName: "msgback",
        //     onProgress: function (event, progress, name, number, total) {
        //         // console.log(progress, number);
        //     },
        //     setName: function (text) {
        //         // $("#progress_report_name").text(text);
        //     },
        //     setStatus: function (text) {
        //         // $("#progress_report_status").text(text);
        //     },
        //     setProgress: function (val) {
        //         // $("#progress_report_bar").css('width', Math.ceil(val*100)+"%");
        //         var val = val * 100 + "%";
        //         self.showProcess(val);
        //     },
        //     onFinishOne: function (event, response, name, number, total) {
        //         var result = JSON.parse(response);
        //         self.forceUpdate();
        //         self.removeImg({imgsrc: "#placehold"})
        //         self.addImg(result.data.imgsrc);
        //         return false;
        //     },
        //     onError: function (event, name, error) {
        //         alert('error while uploading file ' + name);
        //         self.error()
        //     }
        // });

    },
    uploadSuccess(data){
        console.log(data)
        let arr = [];
        data.forEach(item => {
            arr.push(item.response || item.url);
        });
        this.setState({
            headImg: arr,
        });
    },


    submit() {
        var self = this;
        var props = {};

        props.msg = $(this.refs["msgbacktxt"]).val();

        props.name = $(this.refs["name"]).val();

        props.email = $(this.refs["email"]).val();

        if (this.state.errorId) {
            props.id = this.state.errorId
        }

        var imglist = '';

        this.state.photolist.forEach(function (item, index) {
            // imglist.push({imgsrc: item.imgsrc.split('/')[3]})
            imglist += item.imgsrc.split('/')[3] + ',';
        });


        props.img = imglist;

        if (!this.valid()) {
            return;
        }
        $.post('/json/tester/msgback', props, function (result) {
            if (result.success) {

                self.toggleInfo()
            }
        })

    },

    toggleInfo: function () {
        var infoModal = $(this.refs['info-modal']);
        var self = this;
        infoModal.modal('show');

    },

    hide() {
        var infoModal = $(this.refs['info-modal']);
        infoModal.modal('hide');
        setTimeout(function () {
            location.reload()
        }, 300);
    },

    renderModal: function () {
        return <div onClick={this.hide}
            ref="info-modal"
            id="info-modal"
            className=" modal fade"
            role="dialog"
            data-backdrop="true"
            aria-labelledby="infoLabel"
            aria-hidden="true">
            <div className="modal-dialog tip-success" role="document">
                <div className="modal-content">
                    <div className='tip-body'>
                        <div className='tip-success-img'>
                            <img src='http://lingdaima.dotlinkface.com/tip-sucess.png' />
                        </div>
                        <div className='tip-success-txt'>
                            <h3>
                                反馈意见提交成功
                            </h3>
                        </div>
                        <div className='tip-success-btn' onClick={this.hide}>
                            <div>
                                确认
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    },

    start() {
        this.addImg("#placehold", 123)
    },

    addImg(url) {

        var urls = [{ imgsrc: url }].concat(this.state.photolist);
        this.setState({ photolist: urls })
        this.forceUpdate();

    },

    removeImg(url) {

        for (var i = 0; i < this.state.photolist.length; i++) {
            if (this.state.photolist[i].imgsrc == url.imgsrc) {
                this.state.photolist.splice(i, 1)
                this.forceUpdate();
            }
        }
    },

    deleteImg(e) {
        let self = this;
        e.stopPropagation();
        var target = $(e.target).parents(".img");
        var url = target.attr("data-url")
        self.delPic({ imgsrc: url })
    },

    delPic: function (url) {
        var imgname = url.imgsrc.split('/')[3];
        var serverurl = '/json/tester/msgback/msgbackimg/ ' + imgname;
        var self = this;
        $.ajax({
            url: serverurl,
            method: "delete",
            dataType: "json",
            success: function () {
                self.removeImg(url)
            }
        })
    },

    showProcess(num) {
        $("#img-upload-placehold span.num").html(num)
    },


    error: function () {
        this.removeImg({ imgsrc: "#placehold", id: 123 })
    },

    


    imgList() {

        var self = this;

        var result = [];
        var imglist = this.state.photolist;
        for (var i = 0; i < imglist.length; i++) {
            var item = imglist[i];
            if (imglist[i].imgsrc == "#placehold") {

                result.push(<div className="img" id="img-upload-placehold" key={i}>

                    <span className="num"></span>
                </div>)

            }
            else {

                result.push(<div className="img" data-id={item.id} data-url={item.imgsrc}
                    style={{ backgroundImage: "url(" + item.imgsrc + ")" }}>
                    <i className="delete icon iconfont icon-shanchu" onClick={self.deleteImg}></i>
                </div>)
            }
        }
        return result;
    },

    render: function () {

        var self = this;


        var error = function (url) {
            self.error(url);
        }


        return (
            <div id='msgback' className='container msg-back'>
                <Header type="home" active="home" cur="home"></Header>
                <div className="row">
                    <div className="col-md-8 col-xs-12 col-center-block">
                        <div className='msgback-header text-center' style={{ fontSize: '24px', color: '#262626', marginBottom: '37px' }}>
                            意见反馈
                        </div>
                        <div className='msgback-deatil text-center' style={{ fontSize: '14px', color: '#4d4a4a', marginBottom: '54px' }}>
                            请填写你的反馈信息，我们会认真对待你的反馈，第一时间回复你，非常感谢！
                        </div>
                        <div className=" msgback-from">
                            <div className="form-row">
                                <div className="">
                                    <label htmlFor="msgbacktxt">反馈内容：</label>
                                    <textarea placeholder="请尽可能的做详细反馈，方便我们快速处理问题。" rows="3" className='msgbacktxt'
                                        style={{ height: '123px', width: '803px', verticalAlign: 'top', padding: '6px 12px', resize: 'none' }}
                                        id='msgbacktxt' ref='msgbacktxt'></textarea>
                                </div>
                                <div className="err" style={{ marginTop: '5px', marginLeft: '110px', height: '15px' }}>
                                    <span className="err-msg" style={{ color: '#f00', fontSize: '14px' }}>{this.state.msgerror}</span>
                                </div>
                            </div>
                            <div className="form-row " style={{ marginTop: '20px' }}>
                                <div className="">
                                    <label htmlFor="name">你的姓名：</label>
                                    <input placeholder="" className='name' id='name' ref='name'
                                        style={{ padding: '6px 12px' }} />
                                </div>
                                <div className="err" style={{ marginTop: '5px', marginLeft: '110px', height: '15px' }}>
                                    <span className="err-msg" style={{ color: '#f00', fontSize: '14px' }}>{this.state.nameerror}</span>
                                </div>
                            </div>
                            <div className="form-row " style={{ marginTop: '20px' }}>
                                <div className="">
                                    <label htmlFor="email">你的邮箱：</label>
                                    <input placeholder="处理结果将以邮箱的形式反馈给你" className='email' id='email' ref='email'
                                        style={{ padding: '6px 12px' }} />
                                </div>
                                <div className="err" style={{ marginTop: '5px', marginLeft: '110px', height: '15px' }}>
                                    <span className="err-msg" style={{ color: '#f00', fontSize: '14px' }}>{this.state.emailerror}</span>
                                </div>
                            </div>
                            <div className="form-row ">
                                <div className="">
                                    <label htmlFor="email1">附件截图：</label>
                                    <UploadImgs
                                        data={this.state.headImg}
                                        uploadSuccess={this.uploadSuccess.bind(this)}
                                        isEditor={true}
                                        fileLength={9}
                                    >
                                    
                                    </UploadImgs>
                                    {/* <div className="upload">
                                        <p>+</p>
                                        <form id="" ref="" enctype="multipart/form-data" accept="image/*">
                                            <input type="file" name="msgbackimg" ref='msgbackimg' id="msgbackimg"/>
                                        </form>
                                        <div className="err">
                                            <span className="err-msg">{this.state.imgerror}</span>
                                        </div>
                                    </div>

                                    <div className={this.state.photolist.length != 0 ? 'img-list' : 'img-list hide'}
                                         ref='img-list'>
                                        {this.imgList()}
                                    </div> */}

                                </div>
                            </div>

                            <div className="upload-msg" onClick={this.submit}
                                style={{ width: '160px', height: '45px', backgroundColor: '#333333', fontSize: '14px', color: '#fff', margin: '80px auto 250px', textAlign: 'center', lineHeight: '45px', cursor: 'pointer' }}
                            >确认</div>

                        </div>
                    </div>
                </div>
                {this.renderModal()}
                <Footer></Footer>
            </div>
        );
    }
});
