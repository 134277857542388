import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import SlideModal from "../../../common/SlideModal";
import PromptModal from '../../../common/PromptModal'
import JSEncrypt from 'jsencrypt';
import { Ajax } from '../../../utils/ajax';
import { message } from 'antd'
var React = require("react"); 
var createReactClass = require('create-react-class');

require('./signup.css');
const $ = window.$;
const location = window.location
message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
  });
export default createReactClass({
    getInitialState: function () {
        return {
            codeActive: false,
            phoneError: "",
            passwordError: "",
            repasswordError: "",
            nameError: "",
            codeError: "",
            allError: "",
            usertype: 0,
            emailState: 1,
            showState: 0,
            sendState: 0,
            disabled: false,
            stateTime: 60,
            successState: true,
            showModal: false,
            gouxuanonOff: "true",
            ruaAllErrors: ""
        }
    },
    sureGouXuan() {
        //注册下面的勾选
        let self = this
        if (self.state.gouxuanonOff) {
            $(self.refs["rua-signup-gouxuan"]).addClass("add-rua-signup-radio").next().css({ "color": "#d5a256" })
            self.setState({
                gouxuanonOff: ""
            })
            console.log(123)
        }
        else {
            $(self.refs["rua-signup-gouxuan"]).removeClass("add-rua-signup-radio").next().css({ "color": "#999999" })
            self.setState({
                gouxuanonOff: "true"
            })
        }

    },
    ruaJudge() {
        var email = $(this.refs["phone-email"]);
        var password = $(this.refs["password"])
        var repeatPassword = $(this.refs["password-again"]);
        var password = $(this.refs["password"])
        // var companyName = $(this.refs['companyName']).val().trim();
        var self = this;
        this.state.ruaAllErrors = ""
        $(this.refs["phone-err"]).hide();
        if (!email.val().trim()) {
            this.setState({ ruaAllErrors: "手机号码不能为空" })
            $(this.refs["phone-err"]).show();
        }
        else if (!/^1[0-9]{10}$/.test(email.val()) ) {
            this.setState({ ruaAllErrors: "手机号码格式不正确" })
            $(this.refs["phone-err"]).show();
        }
        else if (!password.val().trim()) {
            this.setState({ ruaAllErrors: "密码不能为空" })
            $(this.refs["phone-err"]).show();
        }
        else if (!/^[a-zA-Z0-9\W_]{8,16}$/.test(password.val())) {
            this.setState({ ruaAllErrors: "密码由至少8位字母、数字或符号组成" })
            $(this.refs["phone-err"]).show();
        } else if (/^[0-9]*$/.test(password.val())) {
            this.setState({ ruaAllErrors: "密码由至少8位字母、数字或符号组成" })
            $(this.refs["phone-err"]).show();
        }
        else if (password.val() !== repeatPassword.val()) {
            this.setState({ ruaAllErrors: "两次输入的密码不相等" })
            $(this.refs["phone-err"]).show();
        }
        // else if (!companyName) {
        //     this.setState({ ruaAllErrors: '公司名称不能为空' });
        //     $(this.refs["phone-err"]).show();
        // }
        // else if (companyName.length < 3 || companyName.length > 15) {
        //     this.setState({ ruaAllErrors: '公司名称由3-15位字符组成' })
        //     $(this.refs["phone-err"]).show();
        // }

        if (this.state.ruaAllErrors) {
            return false;
        }
        else {
            return true;
        }
    },
    checkoutPhone: function (callback) {
        var self = this;
        var email = $(this.refs["phone-email"]);//1
        var promise1 = new Promise(function (resolve, reject) {
            $.ajax({
                async: false,
                type: "POST",
                url: '/json/user/isregistered',
                dataType: 'json',
                data: { account: email.val().trim() },
                success: function (data) {
                    resolve(data);
                }
            });
        });

        promise1.then(function (data) {
            if (data.success) {
                callback && callback(true)
            } else {
                self.setState({ ruaAllErrors: "该账号已被注册！" });
                $(self.refs["phone-err"]).show();
                callback && callback(false)

            }
        }, function (err) {
            console.log(err); // 不执行
        })


    },

    checkoutPassword: function () {

        var password = $(this.refs["password"])
        $(this.refs["pass-err"]).hide();

        if (!password.val().trim()) {

            this.setState({ ruaAllErrors: "密码不能为空" });
            $(this.refs["phone-err"]).show();

        }
        else if (!/^[a-zA-Z0-9\W_]{8,16}$/.test(password.val())) {

            this.setState({ ruaAllErrors: "密码由至少8位字母、数字或符号组成" });
            $(this.refs["phone-err"]).show();

        }
        else {
            this.setState({ ruaAllErrors: "" })
            $(this.refs["phone-err"]).hide();
            return true;
        }

    },
    checkoutRepassword: function () {
        var repeatPassword = $(this.refs["password-again"]);
        var password = $(this.refs["password"])
        $(this.refs["phone-err"]).hide();

        if (password.val() !== repeatPassword.val()) {
            this.setState({ ruaAllErrors: "两次输入的密码不相等" })

            $(this.refs["phone-err"]).show();
        }
        else {
            this.setState({ ruaAllErrors: "" })
            $(this.refs["phone-err"]).hide();
            return true;
        }

    },

    checkoutCode: function (callback) {
        var email = $(this.refs["phone-email"]);
        var code = $(this.refs["code"])
        var self = this;
        var promise1 = new Promise(function (resolve, reject) {
            $.ajax({
                async: false,
                type: "POST",
                url: "/json/user/verfycode/" + email.val().trim(),
                dataType: 'json',
                data: { code: code.val().trim() },
                success: function (data) {
                    resolve(data);
                }
            });
        });

        promise1.then(function (result) {
            if (result.success) {
                callback && callback(true)
            }
            else {
                self.setState({ ruaAllErrors: result.error })
                $(this.refs["phone-err"]).show();
                callback && callback(false)
            }
        }, function (err) {
            console.log(err); // 不执行
        })
    },


    checkoutCompanyName: function () {
        var companyName = $(this.refs['companyName']).val().trim();
        $(this.refs["phone-err"]).hide()

        if (!companyName) {
            this.setState({ ruaAllErrors: '公司名称不能为空' });
            $(this.refs["phone-err"]).show()
        }

        else if (companyName.length < 3 || companyName.length > 15) {
            this.setState({ ruaAllErrors: '公司名称由3-15位字符组成' })
            $(this.refs["phone-err"]).show()
        }

        else if (companyName) {
            this.setState({ ruaAllErrors: '' });
            $(this.refs["phone-err"]).hide()
            return true;
        }
        return false;
    },

    checkoutDesignerName: function () {
        var designerName = $(this.refs['designerName']).val().trim();

        if (!designerName) {
            this.setState({ nameError: '设计师名称不能为空' });
        }

        if (designerName) {
            this.setState({ nameError: '' });
            return true;
        }
        return false;
    },

    componentDidMount: function () {
        $('.web').scrollTop(0);
        var email = $(this.refs["phone-email"]);//1
        var username = $(this.refs["username"]);
        var password = $(this.refs["password"])//2
        var code = $(this.refs["code"])
        var repeatPassword = $(this.refs["password-again"]);//3
        var companyName = $(this.refs['companyName']);//4
        var designerName = $(this.refs['designerName']);
        var ruaGouXuan = $(this.refs["rua-signup-gouxuan"])
        this._hasErrors = 0;
        var self = this;
        this.getkey()
        //   ruagouxuan: function() {
        //     console.log(1)
        //     $(this.refs["rua-signup-gouxuan"]).addCalss('add-rua-signup-radio');
        //     console.log(2)
        // }
        ruaGouXuan.on('click', function () {
            self.sureGouXuan()
        })
        

        email.on("blur", function () {
            self.ruaJudge()
            // self.checkoutPhone((result) => {
            //     if (result) {
            //         self.ruaJudge()
            //         this.setState({ codeActive: true })
            //     }
            // });
        })

        //   email.on('input', function () {
        //       self.checkoutPhone();
        //   });

        code.on("blur", function () {
            self.ruaJudge()
            // self.checkoutCode((result) => {
            //     if (result) {
            //         self.ruaJudge()
            //     }
            // })

        })

        password.on("blur", function () {

            var result = self.ruaJudge()
        })

        repeatPassword.on("blur", function () {

            var result = self.ruaJudge()
        })

        email.on("focus", () => {
            this.setState({ codeActive: true })

        })

        companyName.on('blur', function () {

            var result = self.ruaJudge()
        });

        designerName.on('blur', function () {
            self.checkoutDesignerName();
        });

    },

    getkey: function(){
        Ajax.GET(`/ldmshop/admin/getKey`)
        .then(res => {
            console.log(res)
            this.setState({
                publicKey: res.data.key
            })
        })
        .catch(error => {

        })
    },
    valid: function () {
        var self = this;
        return self.ruaJudge()

    },
    sendCodeBind: function () {
        var code = $(this.refs["code"])
        

    },
    sendCode: function (ev) {
        ev && ev.preventDefault();
        var self = this;
        var email = $(this.refs["phone-email"]);
        // var value = this.checkoutPhone;
        // if (!value) {
        //     return;
        // }
        console.log(email.val().trim())
        if(email.val().trim() == ''){
            message.error('请输入手机号码')
            return 
        }
        this.setState({ disabled: true });


        Ajax.POST('ldmshop/admin/sendCode', { phone: email.val().trim() })
            .then(res => {
                if(res && res.code === 0){
                    self.setState({ "sendState": 2 })
                    self.state.stateTime = 60
                    var time = setInterval(function () {
                        var nowNum = self.state.stateTime;
                        if (nowNum == 0) {
                            clearInterval(time);
                            self.setState({ "sendState": 0, "disabled": false, stateTime: 60 })
                        }
                        else {
                            self.setState({ "stateTime": nowNum - 1 })
                        }
    
                    }, 1000)
                }
                
                
            })
            .catch(error => {
                console.log(error)
            })

        // $.post("/json/user/sendcode/" + email.val().trim(), function (result) {
        //     if (result.success) {
        //         self.setState({ "sendState": 2 })
        //         self.state.stateTime = 60
        //         var time = setInterval(function () {
        //             var nowNum = self.state.stateTime;
        //             if (nowNum == 0) {
        //                 clearInterval(time);
        //                 self.setState({ "sendState": 0, "disabled": false, stateTime: 60 })
        //             }
        //             else {
        //                 self.setState({ "stateTime": nowNum - 1 })
        //             }

        //         }, 1000)
        //     }
        // })

    },
    submitLogin: function (ev) {
        var self = this;
        ev && ev.preventDefault();
        var emailValue = $(this.refs["phone-email"]).val();
        // var username = $(this.refs['designerName']).val();
        // var companyName = $(this.refs['companyName']).val();
        var password = $(this.refs["password"]).val();
        var code = $(this.refs["code"]).val();

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(this.state.publicKey);
        let passwordEncrypt = encrypt.encrypt(password);

        if (!this.valid()) {

            return;
        }
        if(this.state.gouxuanonOff == 'true'){
            message.error('您还没有勾选选项')
            return
        }

        // if(password!==repeatPassword){
        //     password.val("")
        //     repeatPassword.val("")
        //     alert("两次输入的密码不相等，请重新输入")
        //     return;
        // }
        var data = {
            account: emailValue,
            password: passwordEncrypt,
            usertype: this.state.usertype
        };

        if (code.length > 0) {
            data.code = code;
        }

        // if (username) {
        //     data.username = username;
        // } else {
        //     data.companyName = companyName;
        // }

        Ajax.POST('ldmshop/admin/registered', {phone:emailValue,password:passwordEncrypt,code:code,repeatPassword:passwordEncrypt})
            .then(res => {
                if(res && res.code === 0){
                    self.setState({ showModal: true, account: data.account });
                    message.success('注册成功')
                    setTimeout(() => {
                        window.location.href = "/login" 
                    }, 200)

                }
            })
            .catch(errmsg => {
                this.setState({ ruaAllErrors:  errmsg});
            $(this.refs["phone-err"]).show();
            })
           

        // $.post("/json/user/signup", data, (result) => {
        //     if (result.success) {
        //         self.setState({ showModal: true, account: data.account });
        //     }
        //     else {
        //         if (result.errors && result.errors.length) {
        //             $(self.refs["errors"]).text(result.errors)
        //         }
        //         else if (Object.keys(result.errfor).length !== 0) {
        //             if (result.errfor.testererror) {
        //                 self.setState({ testerError: result.errfor.testererror })
        //             }
        //             if (result.errfor.phoneerror || result.errfor.email || result.errfor.emailerror || result.errfor.accounterror) {
        //                 self.setState({ phoneError: result.errfor.phoneerror || result.errfor.email || result.errfor.emailerror || result.errfor.accounterror })
        //             }
        //             if (result.errfor.passworderror) {
        //                 self.setState({ passwordError: result.errfor.passwordError })
        //             }
        //             if (result.errfor.codeerror) {
        //                 self.setState({ codeError: result.errfor.codeError })
        //             }
        //             if (result.errfor.usernameerror) {
        //                 self.setState({ nameError: result.errfor.usernameerror })
        //             }

        //         }

        //     }
        // })
    },




    companyType: function () {
        this.setState({ usertype: 0 });
    },

    designerType: function () {
        this.setState({ usertype: 1 });
    },

    renderSignup: function () {
        var codeActive = this.state.codeActive
        var displayStyle = { display: codeActive ? "inline-block" : "none" }
        var className1 = ["active-signup", ""];
        var className2 = ['', 'active-signup'];
        return <div className="sign-up">
            <div className="signup-left-image">
                <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/login-registor-image.png" alt=""></img>
            </div>
            <div className="signup-box">


                <div className="signup-form clearfix">
                    <div className="form-row clearfix">
                        <div className="error-info">
                            <div className="form-row-error" ref="phone-err">
                                <span className="err-msg" >{this.state.ruaAllErrors}</span>
                                <a href="/home" className="tester-msg">{this.state.testerError ? this.state.testerError + '>>' : ''}</a>
                            </div>
                        </div>
                        <div className="form-row-content">
                            <label htmlFor="tel-email" className="signup-icon"><i className="icon iconfont icon-yonghu"></i></label>
                            <input type="text" ref="phone-email" className="tel-email" id="tel-email"  autoComplete='off' placeholder="请输入您的手机号码" />
                        </div>
                    </div>
                    <div className={'form-row clearfix show'}>
                        <div className="form-row-error">
                            <span className="err-msg" >{this.state.codeError}</span>
                        </div>
                        <div className="form-row-content">
                            <label htmlFor="validate" className="signup-icon"><i className="icon iconfont icon-yanzhengma"></i></label>
                            <div className="code-wrapper">
                                <input type="text" ref="code" className="validate" id="validate" autoComplete="off" placeholder="输入验证码" />
                                <button ref="getCode" type="button" disabled={this.state.disabled} className={this.state.sendState !== 2 ? "validate-btn" : "code-button"} onClick={this.sendCode}>{this.state.sendState !== 2 ? '获取验证码' : this.state.stateTime + 's后重新获取'}</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-row clearfix">
                        {/* <div className="error-info">
                            <div className="form-row-error" ref="pass-err">
                            <span className="err-msg"  >{this.state.passwordError}</span>
                            </div>
                        </div> */}
                        <div className="form-row-content">
                            <label htmlFor="password" className="signup-icon"><i className="icon iconfont icon-mima"></i></label>
                            <input type="password" ref="password" className="password" id="password" autoComplete="off" placeholder="请输入您的密码，8～16个字符" />
                        </div>
                    </div>
                    <div className="form-row clearfix">
                        {/* <div className="error-info">
                            <div className="form-row-error" ref="repass-err">
                            <span className="err-msg">{this.state.repasswordError}</span>
                            </div>
                        </div> */}
                        <div className="form-row-content">
                            <label htmlFor="password" className="signup-icon"><i className="icon iconfont icon-mima"></i></label>
                            <input type="password" ref="password-again" className="password" id="password-again" autoComplete="off" placeholder="请再次确认您的密码" />
                        </div>
                    </div>
                    <div ref="company" className={this.state.usertype === 0 ? 'form-row clearfix show' : 'form-row clearfix hide'}>
                        {/* <div className="error-info">
                            <div className="form-row-error" ref="comp-err">
                                <span className="err-msg" >{this.state.nameError}</span>
                            </div>
                        </div> */}
                        {/* <div className="form-row-content">
                            <label htmlFor="company" className="signup-icon"><i className="icon iconfont icon-mima"></i></label>
                            <input type="text" ref="companyName" className="username" id="username" autoComplete="off" placeholder="请输入公司名称" />
                        </div> */}
                    </div>
                    <div ref="designer" className={this.state.usertype === 1 ? 'form-row clearfix show' : 'form-row clearfix hide'}>
                        <div className="form-row-error">
                            <span className="err-msg" >{this.state.nameError}</span>
                        </div>
                        <div className="form-row-content">
                            <label htmlFor="designer" className="signup-icon designer-name"></label>
                            <input type="text" ref="designerName" className="username" id="designer" autoComplete="off" placeholder="请输入设计师大名" />
                        </div>
                    </div>
                    <div className=" form-row clearfix signup-relative">
                        <div className="rua-signup-radio" ref="rua-signup-gouxuan"></div>
                        <div className="signup-agree">完成注册，即代表同意我们的服务条款。</div>
                    </div>

                    <div className="form-row clearfix sign-resigner">
                        <div className="signup-btn">
                            <button className="black-btn" type="button" onClick={this.submitLogin}>注册</button>
                        </div>
                    </div>
                    
                    <div className="form-row login-button">
                        <a href="/login">点击登录</a>
                    </div>
                    
                    {/* <div className="form-row ">
                                <div className="  form-bottom">
                                    <span className="lines  "></span>
                                    <span className="bottom-link  "><a href="/user/login">有账号，登录</a></span>
                                    <span className="lines  "></span>
                                </div>
                    </div> */}
                </div>
            </div>
        </div>
    },

    closeModal: function () {
        this.setState({ showModal: false });

        if (this.state.emailState&&false) {
            location.href = '/user/email/signup/' + this.state.account;

        } else {
            location.href = "/";
        }
    },



    render: function () {
        var children = (
            <div className="signup-modal">
                <div className="modal-row">
                    <img src='https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/authSuccess.png' alt="确定" />
                </div>
                <div className="modal-row">
                    <span className="signup-modal-tips">注册成功！</span>
                </div>
            </div>
        );

        return (
            <div className="signup-body-wrap">
                <Header active="signup" type="signup" cur="register"></Header>
                <div className="signup-wrapper">
                    <div className="container signup-page">
                        {this.renderSignup()}
                    </div>
                </div>
                <Footer></Footer>
                <PromptModal role="company" text={this.state.resText} id="prompt-modal" infoType={this.state.infoType}></PromptModal>
                <SlideModal children={children} modalTitle="注册" closeModal={this.closeModal} showModal={this.state.showModal}></SlideModal>
            </div>
        );
    }
});
