import React, { Component } from 'react';
import Menu from '../../../components/Menu/Menu'
import { phoneReg } from '../../../utils/regexp'
import { Ajax } from '../../../utils/ajax'
import './alipayConfig.less'
import { Input, Button, message } from 'antd';
import * as Admin from '../store/admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'
class alipayConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alipay: "",
            phone: "",
            code: "",
            time: 0
        }
    }
    componentDidMount() {
        this.getConfig();
    }
    onChange(type, e) {
        this.setState({
            [type]: e.target.value
        })
    }
    getConfig(){
        // /ldmshop/withdraw/merchant/queryMerchantWithdraw?merchantId=11
        Ajax.GET(`/ldmshop/withdraw/merchant/queryMerchantWithdraw?merchantId=${this.props.adminReducer.shopData.merchantId}`)
            .then(res => {
                if (res.code == 0) {
                    this.setState({
                        alipay:res.data.aliNumber
                    })
                } else {
                    message.error(res.errmsg,2)
                }
            })
            .catch(err=>{
                message.error(err,2)
            })
    }
    getCode() {

        const { phone, time } = this.state;
        if (!!time) {
            return false;
        }
        if (!phoneReg.test(phone)) {
            message.error("请输入正确手机号码！", 2);
            return false;
        }

        Ajax.POST(`/ldmshop/merchant/aliSend?phone=${phone}&merchantId=${this.props.adminReducer.shopData.merchantId}`)
            .then(res => {
                if (res.code == 0) {
                    message.success("发送成功", 2);
                    this.setState({
                        time: 60
                    }, () => {
                        if (!!this.setInit) {
                            clearInterval(this.setInit)
                        }
                        this.setInit =  setInterval(() => {
                            if (this.state.time == 0) {
                                clearInterval(this.setInit)
                            } else {
                                this.setState({
                                    time: this.state.time - 1
                                })
                            }
                        }, 1000)
                    })
                } else {
                    message.error(res.errmsg,2)
                }
            })
            .catch(err=>{
                message.error(err,2)
            })
    }
    submit(){
        const { alipay , phone ,code } = this.state;
        if(!alipay){
            message.error("请输入支付宝账号！", 2);
            return false;
        }
        if (!phoneReg.test(phone)) {
            message.error("请输入正确手机号码！", 2);
            return false;
        }
        if(!code){
            message.error("请输入验证码！", 2);
            return false;
        }
        let param = {
            "aliNumber": alipay,
            "code": code,
            "merchantId": this.props.adminReducer.shopData.merchantId,
            "phone": phone
        }
        Ajax.POST(`/ldmshop/merchant/ali`,param)
            .then(res => {
                if (res.code == 0) {
                    message.success("修改成功", 2);
                   
                } else {
                    message.error(res.errmsg,2)
                }
            })
            .catch(err=>{
                message.error(err,2)
            })
    }
    render() {
        return <section>
            <Menu></Menu>
            <div className="alipayConfig">
                <div className="title">
                    提现帐号设置
                </div>
                <div className="content">
                    <div className="list">
                        <div>
                            支付宝帐号:
                        </div>
                        <div className="width">
                            <Input value={this.state.alipay} onChange={this.onChange.bind(this, "alipay")} ></Input>
                        </div>
                    </div>
                    <div className="tips">
                        网页版的订单金额将提现到此帐号
                    </div>
                    <div className="list">
                        <div>
                            手机号码:
                        </div>
                        <div className="width">
                            <Input type="tel" value={this.state.phone} onChange={this.onChange.bind(this, "phone")} ></Input>
                        </div>
                    </div>
                    <div className="list">
                        <div>
                            验证码:
                        </div>
                        <div >
                            <Input value={this.state.code} onChange={this.onChange.bind(this, "code")}></Input>
                        </div>
                        <div onClick={this.getCode.bind(this)}>{this.state.time == 0 ? `获取验证码` : `${this.state.time}s`}</div>
                    </div>
                    <Button type="primary" className="button" onClick={this.submit.bind(this)}>确定</Button>
                </div>
            </div>
        </section>
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(alipayConfig));