import React, { Component } from 'react';
import './common/base.css';
import './App.less';
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux';
import Reducers from './redux/reducers/common';
import { helloSaga } from './pages/Admin/store/sagas'
import Index from './pages/Index'
import "../src/assets/font/iconfont.css"
require('../src/iconfont/face/iconfont.css')
require('../src/iconfont/iconfont.css')
require('../src/iconfont/face/iconfont.js')

const sagaMiddleware = createSagaMiddleware(helloSaga);
const store = createStore(Reducers, applyMiddleware(sagaMiddleware));
class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Index />
        </BrowserRouter>
      </Provider>
    );
  }
}


export default App;
