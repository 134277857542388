import {Link} from 'react-router-dom';

var login = require("./login")
require("./BackHeader.css")
var React = require("react");
var createReactClass = require('create-react-class');
const $ = window.$;
const location = window.location
export default createReactClass({
    getInitialState: function () {
        return {}
    },
    componentDidMount: function () {
        var user = login.getUser();
        var isLogin = login.isLogin()
        console.log(user)
        this.setState({
            role: user.role,
            user: user,
            isLogin: isLogin
        });
        this.forceUpdate();
    },
    logout: function (ev) {
        ev.preventDefault();
        debugger
        // var logout = $(this.refs["logout"]);
      alert(123)
        var self = this;
        $.get("/json/user/logout", function (result) {
            if (result) {
                login.logout();
                self.forceUpdate();
                location.href="/home"
            } else {
                alert("登出失败")
            }
        })
    },

    // renderLoginInfo: function () {

    //     if (this.state.isLogin) {
    //         var user = login.getUser();
    //         return (
    //             <p className="navbar-text navbar-right login-status">
    //                 <span>
    //                     <Link to="/my/userSetting">
    //                     <img src={(user.logo || 'http://lingdaima.dotlinkface.com/avatar-default.png')} id='userlogo'
    //                          alt="avatar"/>
    //                     </Link>
    //                     <Link to="/my/userSetting" className="navbar-Link">{user.username || '天宏集团'}</Link>
    //                     <Link to="#" className={this.state.role == 0 ? "free-img" : "hide"}></Link>
    //                     <Link ref="logout" onClick={()=>{this.logout()}} to="/json/user/logout" className="exit">
    //                         <span className="oi oi-account-logout">退出</span>
    //                     </Link>
    //                 </span>
    //             </p>
    //         )

    //     } else {
    //         return <p className="navbar-text navbar-right signup">
    //             <span className="">
    //                 <Link to="/user/login" className="navbar-Link">登录</Link>
    //                 <Link to="/user/signup" className="navbar-Link">注册</Link>
    //             </span>
    //         </p>
    //     }
    // },
    
    // renderNavInfo: function() {
    //     var user = this.state.user || {};
    //     return (
    //         <div id="user-setting-box" aria-labelledby="user-setting-menu">
    //             <ul role="menu">
    //                 <li className="nav-link" role="presentation">
    //                     {/* <a role="menuitem" tabindex="-1" href="#">nav-backava</a> */}
    //                     <Link className="">{user.username || "test"}</Link>
    //                 </li>
    //                 <li className="nav-link" role="presentation">
    //                     <Link to={ user.role == 0 ? "/my/app" : "/my/templatePage/1" }>{ user.role == 0 ? '网站管理' : '作品管理' } </Link>
    //                     {/* 测试 */}
    //                     {/* <Link to={ user.role == 0 ? "/my/templatePage/1" : "/my/app" }>{ user.role == 0 ? '作品管理' : '网站管理' } </Link>                         */}
    //                 </li>
    //                 <li className="nav-link" role="presentation">
    //                     {/* <a role="menuitem" tabindex="-1" href="#">nav-backava</a> */}
    //                     <Link className="" to="/my/userSetting">用户设置</Link>
    //                 </li>
    //                 <li className="nav-link" role="presentation">
    //                     <Link to="/json/user/logout" ref="logout" onClick={()=>{this.logout()}}>退出登录 </Link>                     
    //                 </li>
    //                 <li role="presentation" className="divider"></li>
    //             </ul>
    //         </div>
    //     )
    // },

    render: function () {
        var user = this.state.user || {}
        console.log('BackHeader')
        console.log(user)
        console.log(login.isLogin())
        return (
            <div id="nav-back" className={this.props.role + ' navbar'} style={{
                backgroundColor: user.role == 1 ? "#d6e9f0" : " #eee"
            }} role="navigation">
                <div className="back-header container">
                    <div className="user-center-logo">
                        <Link to="/home"><img src="http://lingdaima.dotlinkface.com/new-user-logo.png" /></Link>
                        <span>{user.role == 1 ? '设计师中心' : '用户中心'}</span>
                    </div>
                    <div className="user-right-concat">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/user-center-call.png" /><span>建站专线：400-800-4647</span>
                    </div>
                    <div className="navbar-header">   
                        <div id="droppdown-box" className="dropdown">                
                            <div id="user-setting-menu" data-toggle="dropdown" style={{verticalAlign:"middle"}}>
                                <img src={(user.logo || 'http://dotlinkface-licence.oss-cn-shanghai.aliyuncs.com/avatar-default.png')}
                                    className="avatar-center user-logo"/>
                                <span className="caret" style={{position: "static"}}></span>
                            </div>
                            <ul className="dropdown-menu pull-right" role="menu" 
                                aria-labelledby="dropdownMenu">
                                <li className="nav-link" role="presentation">
                                    {/* <a role="menuitem" tabindex="-1" href="#">nav-backava</a> */}
                                    <Link className=""><span>{user.username || "test"}<img style={{float: 'right', width: "12px", height: "12px", marginTop: "3px"}} src="http://lingdaima.dotlinkface.com/no-vip.png" /></span></Link>
                                </li>
                                <li className="nav-link" role="presentation">
                                    <a href="#" onClick={()=>{this.logout()}}><span>退出登录2</span></a>                     
                                </li>
                            </ul>
                        </div>    
                        <Link className="navbar-brand" to="/home">
                            {/* <img className="back-logo" src={user.role == 1 ? 'http://dotlinkface-licence.oss-cn-shanghai.aliyuncs.com/designer-logo.png' : 'http://lingdaima.dotlinkface.com/qiye/qiye-logo.png'}/> */}
                            <img className="back-logo" src={'http://lingdaima.dotlinkface.com/new-user-logo.png'}/>  
                            <span>{user.role == 1 ? '设计师中心' : '用户中心'}</span>                                                                       
                        </Link>
                    </div>
                </div>
            </div>

        );
    }
});