import React, { Component } from 'react';
import { Upload, Modal, Icon, message } from 'antd'
import http from '../../http/http'
class UploadImgs extends Component {
  constructor(props) {
    super(props)
    this.state= {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      isEditor: false,     
      length:9
    }
  }
  componentDidMount() {
    // this.setState({
    //   isEditor:this.props.isEditor
    // })
  }
  componentWillReceiveProps(nextprops) {
    // let fileLength = nextprops.fileLength || 9;
    // this.setState({
    //   length:fileLength
    // })
    // console.log(nextprops);
    // if(nextprops.data&&nextprops.data.length ) {
    //   console.log(nextprops);
    //   let arr = []     
    //   nextprops.data.forEach((item, index) => {
    //     console.log(item,"item")
    //     let obj = {
    //       uid: index,
    //       status: 'done',
    //       url: item,
    //     }
    //     arr.push(obj)
    //   })
    //   this.setState({
    //     fileList: arr,
    //     isEditor: nextprops.isEditor
    //   })
    // }
  }
  handleCancel = () => this.setState({ previewVisible: false })
  beforeUpload = (file) => { 
    return new Promise((resolve, reject) => {
      let fileName = file.name,
      index = fileName.lastIndexOf('.'),
      str = fileName.substr(index)
      if (str !== '.png' && str !== '.jpg' && str !== '.jpeg' && str !== '.bmp' && str !== '.gif') {
        message.error('只能上传图片！')
        this.setState({
          fileList: []
        })
        reject()
      }
      resolve()
    }) 
  }
  customRequest = (detail) => {
    console.log(detail.onSuccess);
    let file = detail.file
    http.get('/ldmshop/oss/getSign')
    .then(res => {
      if(res.code!=0){
        return false
      }
      // const serverURL = res.host.replace("http", "https")
      const serverURL = res.data.host     //
      const xhr = new XMLHttpRequest()
      const fd = new FormData()
      const key = `${new Date().getTime()}`;
      fd.append('OSSAccessKeyId', res.data.accessid);     //
      fd.append('policy', res.data.policy);     //
      fd.append('Signature', res.data.signature);     //
      fd.append('key', key);     //
      fd.append('success_action_status', '200');
      const successFn = (response) => {
        detail.onSuccess(serverURL  + '/'+key)   //
        console.log(serverURL,key)
        let data = this.props.fileList;
        data.push({
          uid: data.length,
          status: 'done',
          url: `${serverURL}/${key}`,
        })
        this.props.uploadSuccess(data)   
      }
      const progressFn = (event) => {
        // 上传进度发生变化时调用param.progress
        let percent = event.loaded / event.total * 100
        detail.onProgress ({
          percent
        })
      }
      const errorFn = (response) => {
        // 上传发生错误时调用param.error
        detail.onError()
      }
      xhr.upload.addEventListener("progress", progressFn, false)
      xhr.addEventListener("load", successFn, false)
      xhr.addEventListener("error", errorFn, false)
      xhr.addEventListener("abort", errorFn, false)
      fd.append('file', file)
      xhr.open('POST', serverURL, true)
      xhr.send(fd)
    })
    return false
  }
  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleChange = (data) => {
    if(data.fileList.length >= 9) data.fileList = data.fileList.splice(0, 9)
    this.setState({ fileList: data.fileList })
  }
  onRemove = (file) => {
    let list = this.state.fileList
    this.props.uploadSuccess(list)   
  }
  render() {
    const { previewVisible, previewImage } = this.state;
    const {children,fileList,length} = this.props;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return <div style={{overflow: "hidden"}}>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={this.handlePreview}
        customRequest={this.customRequest}
        onChange={this.handleChange}
        onRemove={this.onRemove}
        beforeUpload={this.beforeUpload}
        // multiple
        accept="image/*"
      >
        {fileList.length >= length ? null : !children ?  uploadButton:children}

      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  }
}
export default UploadImgs