import {Link} from 'react-router-dom';
import { Ajax } from '../utils/ajax';
var login = require("./login")
var React = require("react");
var createReactClass = require('create-react-class');
const $ = window.$;
const location = window.location

export default createReactClass({
    getInitialState: function () {
        return {
            cur: 0,
            navmobile: false,
            titleInfo: {
                home: '首页',
                onetoone: '专属服务',
                weapp: '小程序',
                market: '设计师作品',
                casechannel: '客户案例',
                aboutus: '关于我们',
                termservice: '服务条款',
                copyright: '软件著作权',
                legalnotice: '法律声明',
                faq: '帮助中心',
                msgback: '意见反馈',
                // serviceupgrade: '服务升级',
                register: true,
                login: true,
                userName: null,
                headImg: null 
            }                            
        }
    },
    resize() {
      
    },
    renderTitle() {
        var info = this.state.titleInfo;
        var propsInfo = this.props.cur;
        // if($(window).width() <= 768) {
        if(info[propsInfo]) {            
            if(propsInfo == 'home' || propsInfo == 'login' || propsInfo == 'register') {
                return  <span>
                            <Link to="/home" className="logo-wrapper phone-title-show" >
                                <img src='https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/logo.png' alt="logo" />
                            </Link>
                            <Link to="/home" className="logo-wrapper pc-title-show">
                                <img src='https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/logo.png' alt="logo" />
                            </Link>
                        </span>
            }
            return  <div>
                        <div className="phone-title-show">{info[propsInfo]}</div>
                        <Link to="/home" className="logo-wrapper pc-title-show" >
                            <img src='https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/logo.png' alt="logo" />
                        </Link>
                    </div>
        }
    },
    componentDidMount: function () {
        var ref = $(this.refs[this.props.type]);
        this.resize()
        ref.addClass('active');
        this.setState({
            user: login.getUser(),
            // isLogin: login.isLogin()
            isLogin:true
        })
        var userName = sessionStorage.getItem("phoneNumber")
        var name = sessionStorage.getItem("name") !=='null' ? sessionStorage.getItem("name") : ''
        let headImg = sessionStorage.getItem("headImg") != 'null' ? sessionStorage.getItem("headImg") : ''
       
        this.setState({
            userName,
            name,
            headImg
        },()=>{console.log(this.state.headImg)})
        $(window).on('resize', this.resize)
        if($(window).width() <= 768) {
            this.setState({
                navmobile: true
            })
        }
        $('.navbar-toggle.x.header-menu').on('click', function() {
            setTimeout(() => {
                $('.navbar-mobile-collapse').css({
                    marginLeft: 0
                })
            }, 200)
        })
        $('.navbar-collapse').on('click', function(e) {
            if($(e.target).hasClass('navbar-collapse')) {
                $('.navbar-mobile-collapse').css({
                    marginLeft: '-100%'
                })
                setTimeout(() => {
                    $(this).removeClass('in')
                }, 500)
                
            }
        })
        // $.get('/json/my/userSetting', (result) => {
        //     if (result.success) {
        //         this.setState({
        //             userImg: result.data.logo,
        //             userName: result.data.username
        //         })
        //     }
        // });
    },

    // linkTo: function (e) {
    //     e.preventDefault();

    //     var user = login.getUser();
    //     if (user.role == 0) {
    //         location.href = '/my/app?page=1';
    //     }

    //     if (user.role == 1) {
    //         location.href = '/my/templatePage';
    //     }
    // },


    current: function (index) {
        this.setState({cur: index});
    },
    logout: function () {
        var self = this;

        Ajax.GET(`/ldmshop/admin/logout`)
            .then(res => {
                if(res && res.code === 0){
                    self.forceUpdate();
                    sessionStorage.removeItem("backToken")
                    sessionStorage.removeItem("phoneNumber")
                    sessionStorage.removeItem("headImg")
                    sessionStorage.removeItem("name")
                    sessionStorage.removeItem("shopData")
                    location.href="/home"
                }
                
            })
            .catch(error => {

            })

        // $.get("/json/user/logout", function (result) {
        //     if (result.success) {
        //         login.logout();
        //         self.forceUpdate();
        //         location.href="/"
        //     } else {
        //         login.logout();
        //         self.forceUpdate();
        //         location.href="/"
        //     }
        // });
    },
    personal(user){
        return (
            <div id="droppdown-box" className="dropdown">
                <div id="dropdownMenu" data-toggle="dropdown"  style={{verticalAlign:"middle"}}>
                    {/* 修改之前user.logo */}
                    <img src={(this.state.headImg == '' ?  'http://dotlinkface-licence.oss-cn-shanghai.aliyuncs.com/avatar-default.png' : this.state.headImg)}
                        className="avatar-center"/>
                    <span className="caret" style={{position: "static"}}></span>
                </div>
                
                <ul className="dropdown-menu pull-right" role="menu" 
                    aria-labelledby="dropdownMenu">
                    <li className="nav-link" role="presentation">
                        {/* <Link to={ user.role == 0 ? "/my/app" : "/my/templatePage/1" }>{ user.role == 0 ? '网站管理' : '作品管理' } </Link> */}
                        {/* 测试 */}
                        {/* <Link to={ user.role == 0 ? "/my/templatePage/1" : "/my/app" }>{ user.role == 0 ? '作品管理' : '网站管理' } </Link>                         */}
                    </li>
                    <li className="nav-link" role="presentation">
                        {/* <a role="menuitem" tabindex="-1" href="#">nav-backava</a> */}
                        {/* <Link className=""  to={ user.role == 0 ? "/my/userSetting" : "/my/designerSetting" }>用户设置</Link> */}
                    </li>
                    <li className="nav-link" role="presentation">
                        <a onClick={()=>{this.logout()}}><span>退出登录</span> </a>                     
                    </li>
                </ul>
            </div>
        )
    },

    renderItem: function () {
        var result = [];
        var user = this.state.user || {}
        // var isLogin = this.state.isLogin
        var isLogin = sessionStorage.getItem("backToken") ? true :false
        
        var items = {                                                               //移动端
            "home": <Link ref="home" className="home" to="/home" >首页</Link>,            
            // "market": <Link ref="market" className="market" to="/template/market/all">设计师作品</Link>,         
            // "onetoone": <Link ref="onetoone" className="ontoone-btn" to="/onetoone">专属服务</Link>,   
            "freeuse": <Link ref="freeuse" className="ontoone-btn" to="/Choose" style={{fontWeight:'1200'}}>免费开店</Link>,  
            // "weapp": <Link ref="weapp" className="ontoone-btn" to="/weapp">小程序</Link>,           
            "casechannel": <Link ref="casechannel" className="casechannel-btn" to="/choosemodel" style={{fontWeight:'1200'}}>客户案例</Link>,
            // "admin": <Link ref="admin" className="casechannel-btn" to="/admin" style={{fontWeight:'1200'}}>管理中心</Link>,
            // "serviceupgrade": <Link ref="serviceupgrade" className="serviceupgrade-btn" to="/serviceupgrade">服务升级</Link>
        }
        var phoneslide = {                                                              //移动端
            "aboutus": <Link ref="abouts" className="aboutus left-link" to="/aboutus">关于我们</Link>,
            "termservice": <Link ref="termservice" className="termservice left-link" to="/termservice">服务条款</Link>,
            "copyright": <Link ref="copyright" className="rightcopy left-link" to="/copyright">软件著作权</Link>,
            "legalnotice": <Link ref="legalnotice" className="legalnotice left-link" to="/legalnotice">法律申明</Link>,
            "faq": <Link ref="faq" className="faq left-link" to="/faq">帮助中心</Link>
        }
        if(this.state.navmobile){
            items = Object.assign(items, phoneslide);
        } else {
            items = {                                                           //电脑端
                "home": <Link ref="home" className="home" to="/home" style={{fontWeight:'1200'}}>首页</Link>,               
                // "market": <Link ref="market" className="market" to="/template/market/all">设计师作品</Link>,             
                // "onetoone": <Link ref="onetoone" className="ontoone-btn" to="/onetoone">专属服务</Link>,   
                "freeuse": <Link ref="freeuse" className="ontoone-btn" to="/Choose" style={{fontWeight:'1200'}}>免费开店</Link>,  
                // "weapp": <Link ref="weapp" className="ontoone-btn" to="/weapp">小程序</Link>,               
                "casechannel": <Link ref="casechannel" className="casechannel-btn" to="/choosemodel" style={{fontWeight:'1200'}}>客户案例</Link>,
                
                // "serviceupgrade": <Link ref="serviceupgrade" className="serviceupgrade-btn" to="/serviceupgrade">服务升级</Link> 
            }
        }

        if (!isLogin) {
            var noLogin = {
                "register": <Link className="sign-up-button" ref="signup" to="/signup">注册</Link>,
                "login": <Link className="login-button" ref="login" to="/login">登录</Link>,
            }
            if(this.state.navmobile) {
                noLogin = {
                    "loginDefautImg": <div style={{textAlign: 'center'}}><div className="user-mobile-img-content"><img src={this.state.headImg || 'http://lingdaima.dotlinkface.com/user-default.png'} style={{width: '100%'}}/></div></div>,
                    "register": <div style={{textAlign: 'center',marginBottom: '25px'}}><Link className="sign-up-button" ref="signup" to="/signup" style={{color: "#fff"}}>注册</Link><span style={{color: "#fff"}}> / </span><Link className="login-button" ref="login" to="/login" style={{color: "#fff"}}>登录</Link></div>,
                } 
            }
        } else {
            if(this.state.navmobile) {
                noLogin = {
                    "loginDefautImg": <div style={{textAlign: 'center'}}><div className="user-mobile-img-content"><img src={this.state.headImg || 'http://lingdaima.dotlinkface.com/user-default.png'} style={{width: '44px'}}/></div></div>,
                    "register": <div style={{textAlign: 'center',marginBottom: '25px'}}>
                        {/* <div style={{verticalAlign: 'middle'}}><span style={{color: '#fff'}}>muzi.L</span><img style={{marginLeft: '5px'}} src="http://lingdaima.dotlinkface.com/no-vip.png" /></div> */}
                        <div className="personal-center"><Link to={ user.role == 0 ? "/admin/listPage" : "/admin/listPage" }>个人中心</Link></div>
                    </div>,
                }               
            } else if(user.role == 1) {
                // user role: 0 用户 role: 1 设计师
                var noLogin = {
                    // <Link ref="my" to="my/templatePage/1" className="avatar-center">
                    'my': <Link ref="my" to="">
                        {this.personal(user)}
                        </Link>
                }
            } else {
                var noLogin = {
                    "admin": <Link ref="admin" className="casechannel-btn" to="/admin/listPage" style={{fontWeight:'1200'}}>管理中心</Link>,
                    'my': <div>{this.personal(user)}</div>
                        
                }
            }
          
        }
        if(this.state.navmobile){
            items = Object.assign(noLogin, items);
        } else {
            items = Object.assign(items, noLogin); 
        }
        var i = 0;
        for (var p in items) {
            if (i < 5) {                
                result.push(<li key={i} onClick={this.current.bind(this, p)}
                                className={this.state.userName==null? 'right'+i : 'right'}
                                // className={this.props.cur == p ? "active"   : 'right' }
                                >{items[p]}</li>)
            } else {
               var  j = i - 2;
                result.push(<li key={i} onClick={this.current.bind(this, p)}
                                className={this.state.userName==null? 'right'+j : 'right'+j}
                                className={this.props.cur == p ? "active  "  : 'right' }>{items[p]}</li>)
            }
            i ++;
        }
        return result;
    },
    render: function () {

        var active = this.props.active || "home"
        var className = "navbar"
        if (this.props.type == "home") {
            className = "navbar home"
        }
        else {
            className += ' ' + active;
        }


        return (
            <div id="nav" className={'index-header ' + className} role="navigation">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle x collapsed header-menu" data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            {/* <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span> */}
                            <div className="icon-mobile-bar"></div>
                        </button>
                        <div className="logo-left">                          
                            {this.renderTitle()}
                        </div>
                    </div>
                    <div className="collapse navbar-collapse " id="bs-example-navbar-collapse-1">
                        {/* {this.renderLoginInfo()} */}
                        <div className='navbar-mobile-collapse'>
                            <ul className={"nav navbar-nav navbar-right main breadcrumb"}>
                                {this.renderItem()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
