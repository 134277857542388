import Header from '../../common/Header'
import Footer from '../../common/Footer'

import './index.css';
const $ = window.$;
var React = require("react");

var createReactClass = require('create-react-class');

export default createReactClass({

    componentDidMount: function () {
        $('.web').scrollTop(0);
    },
    componentWillUnmount: function () {

    },
    render: function () {
        return (
        <div id="termservice-Page" className='termservice-page'>
            <Header type="termservice" active="termservice" cur="termservice"></Header>
                <div  id='aboutus' className='container termservice-container'>

                    <div className="row">

                        <div className='term-service col-md-12 col-sm-12 col-xs-12 center-block'>
                           <div  className=' termservice-header col-md-12 col-sm-12 col-xs-12'>
                                <h3 className='col-md-4 col-sm-6 col-xs-12 '>服务条款</h3>
                            </div>
                            <div className='termservice-list container'>

                                <p>
                                欢迎阅读茜芙科技平台服务条款(下称“本条款”)，本条款阐述之条款和条件适用于您在茜芙科技平台无偿或有偿使用茜芙科技产品（包括但不限于建站、微信小程序等茜芙科技产品），茜芙科技平台是茜芙（杭州）生物科技有限公司（下称茜芙科技公司）运营的网站，本条款所述的茜芙科技产品为茜芙科技公司旗下产品。
                                </p>
                                <div className='termservice-list-container'>
                                        
                                    <p>1. 接受条款</p>

                                    <p>以任何方式使用茜芙科技产品即表示您同意自己已经与茜芙科技公司就茜芙科技平台服务事项（下称茜芙科技服务）订立本条款，且您将受本条款的条件约束。您应在第一次登录后仔细阅读本条款，并有权选择停止或继续使用茜芙科技服务；一旦您继续使用茜芙科技服务， 则表示您已接受本条款，当您与茜芙科技公司、茜芙科技平台发生争议时，应以本条款作为约束双方的条款。除另行明确声明外，任何使茜芙科技服务范围扩大或功能增强的新内容均受本条款约束。</p>



                                    <p>2.谁可使用茜芙科技平台</p>

                                    <p>茜芙科技服务仅供能够根据相关法律订立具有法律约束力的合约的主体使用，如不符合本项条件，请勿使用茜芙科技服务，茜芙科技服务不会提供给被暂时或永久中止资格的茜芙科技会员。</p>



                                    <p>3. 收费</p>
                                

                                    <p>茜芙科技公司保留在根据本条款第1条通知您后，收取茜芙科技服务费用的权利，您因进行交易、向茜芙科技公司获取有偿服务或接触茜芙科技公司服务器而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担，茜芙科技公司保留在无须发出书面通知，仅在茜芙科技平台公示的情况下，暂时或永久地更改或停止部分或全部茜芙科技服务的权利。</p>



                                    <p>4. 您的资料</p>

                                    <p>您可能会提交资料以作与茜芙科技服务有关的使用，除了茜芙科技公司授权给您使用的资料，茜芙科技公司不提出拥有对您所发布或者向茜芙科技公司提供（称为“提交”）的与茜芙科技服务有关的资料的所有权。但是，经您发布或以其他方式提交的资料，您授予茜芙科技公司免费许可： 做与服务有关的使用、复制、传播、显示、发表和修改； 在与您的提交有关时发布您的姓名或单位名称； 将这些许可授予其他人。 本节仅适用于法律所允许的内容并仅适用于在不违反法律的限度内使用和发布上述法律所允许的内容，茜芙科技公司不会为您的提交向您支付费用，茜芙科技公司有权拒绝发布，并且有权随时从茜芙科技服务中删除您的提交，您应当对您所做出的每一提交享有以实施本节所规定的授权行为所必要的完整权利。</p>



                                    <p>5. 隐私</p>

                                    <p>为了协作和提供服务，茜芙科技公司会收集您的某些信息。此外，茜芙科技公司还可以访问或者透露关于您的信息，包括您通讯的内容，以：(a) 遵守法律、响应司法要求或法律程序；(b) 保护茜芙科技公司及其客户的权利、财产，包括协议的执行和遵守适用于服务的策略；(c) 保护茜芙科技公司及其雇员、客户和公众的权利、财产或安全。 茜芙科技公司可采取技术或其他措施以保护茜芙科技服务，保护茜芙科技公司的客户，或阻止您违反本合同，这些措施可能包括，例如，通过过滤来阻止垃圾邮件或提高安全级别。这些措施可能阻止或中断您对服务的使用。 为了向您提供服务，茜芙科技公司可收集有关服务状况，您的机器和您对服务的使用的某些信息，茜芙科技公司有权从您的机器自动上传这些信息，这些数据不会构成对您私人身份的确认。</p>



                                    <p>6. 终止或访问限制</p>

                                    <p>在您未向茜芙科技支付服务费用的情况下，茜芙科技公司可自行全权决定以任何理由 (包括但不限于茜芙科技公司认为您已违反本条款的字面意义和精神，或您以不符合本条款的字面意义和精神的方式行事，或您在超过30天的时间内未以您的帐号及密码登录茜芙科技产品) 终止您对茜芙科技服务的使用，及可自行全权决定以任何理由 (包括但不限于茜芙科技公司认为您已违反本条款的字面意义和精神，或您以不符合本条款的字面意义和精神的方式行事，或您在超过60天的时间内未以您的帐号及密码登录茜芙科技产品) 终止您的茜芙科技服务密码、账户 (或其任何部份) 或并删除和丢弃您在使用茜芙科技服务中提交的 “您的资料”。根据本条款的任何规定终止您使用茜芙科技服务之措施可在不发出事先通知的情况下实施，并承认和同意，茜芙科技公司可立即使您的账户无效，或撤销您的账户以及在您的账户内的所有相关资料和档案，和/或禁止您进一步接入该等档案或茜芙科技服务。帐号终止后，茜芙科技公司没有义务为您保留原帐号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。此外，茜芙科技公司不会就终止您接入茜芙科技服务而对您或任何第三者承担任何责任，第8、9和10各条应在本条款终止后继续有效。</p>



                                    <p>7. 违反本服务条款的法律责任</p>

                                    <p>（1）在不限制其他补救措施的前提下，发生下述任一情况，茜芙科技公司可立即发出警告，暂时中止、永久中止或终止您的会员资格，不退还已交付款项并删除您的任何现有产品信息，以及您在网站上展示的任何其他资料：(i) 您违反本条款；(ii) 茜芙科技公司无法核实或鉴定您向茜芙科技公司提供的任何资料； (iii) 茜芙科技公司相信您的行为可能会使您、茜芙科技公司用户或通过茜芙科技公司或茜芙科技公司产品提供服务的第三者服务供应商发生任何法律责任；(iv)发表、传送、传播、储存个人网站类，在线音视频类，刷钻/刷QB/QQ业务/刷流量/taobao刷信誉类，色情/成人内容/低俗内容类，游戏类/代练/涉及交易的虚拟物品类，彩票预测/赌博类内容网站，盗号/外挂/私服/辅助类，代办证/代考/代开发票类，黑客/网站挂马/放置病毒/收费下载/收费传授黑客技术类，虚假信息/诈骗信息类等， 不利国家与社会稳定和谐，违反国家相关法律与政策的内容，将配合有关部门追究责任。（v）利用茜芙科技公司的邮箱系统发送垃圾邮件，垃圾邮件指未经第三方许可，而强行发送至第三方邮件中的电子邮件，或同时发送给大量用户，影响正常网络通信的电子邮件，或含有恶意、虚假、伪装等信息的电子邮件。<br/>

                                    （2）若您的网站被第三方攻击，给茜芙科技或茜芙科技其他用户造成影响的，茜芙科技公司有权选择以下任一方式处理：（i）茜芙科技公司通知您立即采取接入第三方防御服务的措施，若您于接到通知后三日内未处理，茜芙科技公司有权直接关停您的网站，且无需承担任何责任；（ii）茜芙科技公司直接关闭您的网站，并向您退回剩余服务期限对应的服务费（购买时采用代金券的，茜芙科技公司将不予退款）。本条所称的第三方攻击仅指您的网站内容未违反本服务条款的情况下所受的恶意攻击。因您的网站内容违反本服务条款而遭受的攻击，不属于本条所称的网站攻击范畴。</p>



                                    <p>8.服务“按现状”和“按可得到”的基础提供</p>

                                    <p>茜芙科技公司会尽一切努力使您在使用茜芙科技产品的过程中得到乐趣。遗憾的是，茜芙科技公司不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断，或根据市场需求，适当变更服务内容的某种功能。为此，您明确理解和同意，您使用茜芙科技服务的风险由您自行承担。茜芙科技服务以“按现状”和“按可得到”的基础提供。茜芙科技公司明确声明不作出任何种类的所有明示或暗示的保证，包括但不限于关于适销性、适用于某一特定用途和无侵权行为等方面的保证。茜芙科技公司对下述内容不作保证：(i)茜芙科技服务会符合您的要求；(ii)茜芙科技服务不会中断，且适时、安全和不带任何错误；(iii) 通过使用茜芙科技服务而可能获取的结果将是准确或可信赖的；及 (iv) 您通过茜芙科技服务而购买或获取的任何产品、服务、资料或其他材料的质量将符合您的预期。通过使用茜芙科技服务而下载或以其他形式获取任何材料是由您自行全权决定进行的，且与此有关的风险由您自行承担，对于因您下载任何该等材料而发生的您的电脑系统的任何损毁或任何数据损失，您将自行承担责任。您从茜芙科技或通过或从茜芙科技服务获取的任何口头或书面意见或资料，均不产生未在本条款内明确载明的任何保证。</p>



                                    <p>9. 责任范围</p>

                                    <p>您明确理解和同意，茜芙科技公司不对因下述任一情况而发生的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿 (无论茜芙科技公司是否已被告知该等损害赔偿的可能性)：(i) 使用或未能使用茜芙科技服务；(ii) 因通过或从茜芙科技服务购买或获取任何货物、样品、数据、资料或服务，或通过或从茜芙科技服务接收任何信息或缔结任何交易所产生的获取替代货物和服务的费用；(iii) 未经批准接入或更改您的传输资料或数据；(iv) 任何第三者对茜芙科技服务的声明或关于茜芙科技服务的行为；或 (v) 因任何原因而引起的与茜芙科技服务有关的任何其他事宜，包括疏忽。</p>



                                    <p>10. 赔偿

                                    您同意，因您违反本条款或经在此提及而纳入本条款的其他文件，或因您违反了法律或侵害了第三方的权利，而使第三方对茜芙科技公司及其子公司、分公司、董事、职员、代理人提出索赔要求（包括但不限于司法费用和其他专业人士的费用，含律师费等），您必须赔偿给茜芙科技公司及其分公司、董事、职员、代理人，使其等免遭损失。</p>

                                    <p></p>

                                    <p>11. 遵守法律</p>

                                    <p>您在茜芙科技上不得发布各类违法或违规信息。您应遵守与您使用茜芙科技服务，以及与您竞买、购买和销售任何物品以及提供商贸信息有关的所有相关的法律、法规、条例和规章。</p>



                                    <p>12. 广告和金融服务</p>

                                    <p>您与在茜芙科技服务上或通过茜芙科技服务物色的刊登广告人士通讯或进行业务往来或参与其推广活动，包括就相关货物或服务付款和交付相关货物或服务，以及与该等业务往来相关的任何其他条款、条件、保证或声明，仅限于在您和该刊登广告人士之间发生。您同意，对于因任何该等业务往来或因在茜芙科技服务上出现该等刊登广告人士而发生的任何种类的任何损失或损毁，茜芙科技公司无需负责或承担任何责任。您如打算通过茜芙科技服务创设或参与与任何公司、股票行情、投资或证券有关的任何服务，或通过茜芙科技服务收取或要求与任何公司、股票行情、投资或证券有关的任何新闻信息、警戒性信息或其他资料，敬请注意，茜芙科技公司不会就通过茜芙科技服务传送的任何该等资料的准确性、有用性或可用性、可获利性负责或承担任何责任，且不会对根据该等资料而作出的任何交易或投资决策负责或承担任何责任。</p>



                                    <p>13. 您对茜芙科技公司的通知</p>

                                    <p>您可以通过茜芙科技平台公布的茜芙科技公司联系信息向茜芙科技公司发出书面通知。</p>



                                    <p>14. 茜芙科技公司向您发出通知</p>

                                    <p>茜芙科技公司向您发出通知，同意使用电子信息。本合同系电子形式。茜芙科技公司承诺向您发送与本服务有关的特定信息，并有权向您发送某些附加信息。茜芙科技公司可能还会向您发送法律要求发送的有关本服务的其他信息。茜芙科技公司可以电子形式向您发送这些信息。您有权取消您的同意，但如果您取消，茜芙科技公司可取消对您的服务。 茜芙科技公司可以下列方式向您提供必需的信息： 通过您在注册茜芙科技服务时所指明的电子邮件地址向您发送电子邮件； 在信息可供使用时，通过在向您发送的电子邮件通知中指明供访问的某一网站；或通过访问为此目的而通常事先指定的某一网站。 通过电子邮件向您发出的通知将被视为在该电子邮件中标明的传输日期发送并且收到。 在您能够访问和使用茜芙科技服务期间，您拥有必要的软件和硬件以接受此类通知。如果您不同意以电子形式接受任何通知，那么您应当停止使用茜芙科技服务。</p>



                                    <p>15. 与第三方网站的链接</p>

                                    <p>提供与第三方网站的链接仅仅为了给您带来方便。如果您使用这些链接，将离开茜芙科技站点。茜芙科技公司没有审查过所有这些第三方站点，对任何这些站点及其内容或它们的保密政策不进行控制，也不负任何责任。因此，茜芙科技公司对这些网站上的任何信息、软件以及其它产品或材料，或者通过使用它们可能获得的任何结果不予认可，也不作任何表述。如果您决定访问本站点链接的任何第三方站点，其风险完全由您自己承担。</p>



                                    <p>16. 不可抗力</p>
                                    <p>由于自然灾害、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通电信网络、供电单位采取的限电或断电措施、供电单位的电力设施故障、通讯或其他设施故障或严重伤亡事故、黑客攻击、尚无有效防御措施的计算机病毒的发作及其他各方不能预见并且对其发生和后果不能防止并避免的不可抗力原因，致使茜芙科技公司延迟或未能履约的，茜芙科技公司不对您承担任何责任。</p>



                                    <p>17. 关于网络</p>

                                    <p>您明白由于因特网上通路的阻塞或造成访问速度下降，均是正常，不属于茜芙科技公司违约，若遇电信运营商或国家政策等原因造成的网络中断，茜芙科技公司不承担相应责任。</p>



                                    <p>18. 关于版权</p>

                                    <p>以任何方式使用茜芙科技提供的服务，包括但不限于基于茜芙科技搭建的企业网站、企业邮箱等服务，所涉及的版权归茜芙科技公司或有权的第三方所有。<br/>

                                    您在使用茜芙科技提供的服务时，应当保证相关版权的完整性，特别地，对于茜芙科技的模板图片，您不得恶意篡改、任意拆分、组合，涉及人物图片的，您不得将图片进行分割或与其他图像进行组合。否则，茜芙科技公司有权终止为您提供茜芙科技服务，并不予退还服务费。若因您的该些行为给茜芙科技公司造成损失的，您还应当承担赔偿责任。若造成图片肖像权人向茜芙科技公司主张权利的，茜芙科技公司有权向您追偿。</p>



                                    <p>19. 关于退款</p>

                                    <p>购买以下所列茜芙科技产品的客户，支付完成后7天内（从购买当天算起）终止服务的，可申请无理由全额退款，具体包括：首次购买网站版本和首次购买小程序版本。<br/>

                                    申请退款的客户需向茜芙科技公司提供：网站的茜芙科技帐号、管理员帐号和密码；接收退款的银行信息，包括完整开户行信息（如：xx省xx市分行xx支行）、银行账号、账户名（姓名）。退款不收取任何费用，退款完成后将不再享受茜芙科技提供的服务。<br/>

                                    以上退款均以您未违反本条款约定为前提。</p>



                                    <p>20. 适用法律和管辖</p>

                                    <p>本条款适用于中国法律并依照中国法律解释，不会造成任何法律的冲突，任何因有关使用茜芙科技各产品而发生的诉讼均应提交杭州仲裁委员会申请仲裁。 如果您还有疑问，您可以直接给茜芙科技公司客服中心留言。</p>



                                    <p>以上声明，您可随时在茜芙科技平台阅读、复制或下载。</p>
                                

                                </div>

                            </div>

                        </div>
                </div>
                </div>
            <Footer cur="termservice"> </Footer>
        </div>
        );
    }
});
