import {  Link } from 'react-router-dom';
var React = require("react");
var createReactClass = require('create-react-class');

const $ = window.$;
const location = window.location

export default  createReactClass({
  getInitialState: function() {
    return {secondsElapsed: 0};
  },
  tick: function() {
    this.setState({secondsElapsed: this.state.secondsElapsed + 1});
  },
  componentDidMount: function() {
    this.interval = setInterval(this.tick, 1000);
    $(window).scrollTop(0);

  },
  componentWillUnmount: function() {
    clearInterval(this.interval);
  },

  openkefu(){
    var kefu101 = "http://wpa.qq.com/msgrd?v=3&uin=2435670291&site=oicqzone.com&menu=yes";
    var kefu102 = "mqqwpa://im/chat?chat_type=wpa&uin=2435670291&version=1&src_type=web&web_src=oicqzone.com";
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.open(kefu102) ;
    } else {
      window.open(kefu101) ;
    }
  },

  render: function() {
    return (

    <footer className="footer clearfix">
      <div className='container foot-container'>
          {/* <div className='logo bot-top'>
            <img src='http://lingdaima.dotlinkface.com/footer/logo-footer-new.png'/>
            <hr className="line"/>
          </div> */}
        <div className='bot-right '>
          <div className='nav-list'> 
            <Link to="/aboutus" className={this.props.cur == 'aboutus'? 'selected': ''}>关于我们</Link>
            <Link to="/termservice" className={this.props.cur == 'termservice'? 'selected': ''}>服务条款</Link>
            <Link to="/copyright" className={this.props.cur == 'copyright'? 'selected': ''}>企业资质</Link>
            <Link to="/legalnotice" className={this.props.cur == 'legalnotice'? 'selected': ''}>法律声明</Link>
            {/* <Link to='/faq' className={this.props.cur == 'faq'? 'selected': ''}>帮助中心</Link>
            <Link to='/onetoone' className={this.props.cur == 'onetoone'? 'selected': ''}>专属服务</Link> */}
            <Link to='/choosemodel' className={this.props.cur == 'casechannel'? 'selected': ''}>客户案例</Link>
            <Link to='/Choose' className={this.props.cur == 'market'? 'selected': ''}>免费开店</Link>
          </div>
          <div className='declare'>
            <span>Copyright ©sifshop.cn</span> <span>  茜芙（杭州）生物科技有限公司 </span>  <span>  <a style={{color: '#999'}} href="https://beian.miit.gov.cn/">浙ICP备2023035503号-3</a> </span>
          </div>
        </div>
        
      </div>
    </footer>


    );
  }
});
