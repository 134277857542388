var React = require("react");var createReactClass = require('create-react-class');
require('./PromptModal.css')
// var configs = require("../config")
const $ = window.$;
const location = window.location
module.exports = createReactClass({
  getInitialState: function () {
    return {}
  },

  closeModal: function () {
    $('#prompt-modal').modal('hide');
    location.reload();
  },

  render: function () {
    console.log(this.props.infoType)
    return (
      <div ref="prompt-modal" id="prompt-modal" className="modal fade" role="dialog" data-backdrop="false" aria-labelledby="testerLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className={"prompt-header" + ' header-' + this.props.role}>
              <div className="prompt-header-title">提示</div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="icon iconfont icon-close"></span></button>
            </div>
            <div className="prompt-body">
              <div className="prompt-body-wrapper">
                <div className="prompt-body-img">
                  <span className="icon iconfont icon-caozuoshibai" style={{display: this.props.infoType ? 'none' : 'inline-block', fontSize: '26px'}}></span>
                  <img src='http://lingdaima.dotlinkface.com/prompt-success.png' style={{display: this.props.infoType ? 'inline-block' : 'none'}} alt=""/>
                </div>
                <div className="prompt-body-text">{this.props.text || '你已操作成功'}</div>
                <div className="prompt-body-btn">
                  <button className="black-btn" onClick={this.closeModal}>确定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});