import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import secret from '../../utils/secret';
import './Menu.less'
import icon from '../../assets/images/xcx.jpg'
import http from '../../http/http'
import {Ajax} from '../../utils/ajax'
import { message } from 'antd';
import Tips from '../../components/Tips/Tips';


const $ = window.$;
const MenuCinfig = [
    // {
    //     name: "店铺管理",
    //     url: "http://www.baidu.com",
    //     icon: 'icondujiayunicon-02',
    //     type: 1, //   1 外链 2 有下级 3 跳转
    //     children: []
    // },
    {
        name: "微信小程序配置",
        url: "http://www.baidu.com",
        icon: 'iconxiaochengxuguanli',
        type: 2,
        key: 1,
        children: [            
            {
                name: "小程序授权",
                url: "/admin/mainPage",
                type: 3,
                children: [],
                key: 2,
            },
            {
                name: "小程序预览",
                url: "/admin/test",
                type: 3,
                key: 11,
                children: [
                ]
            },
            // {
            //     name: "网页版预览",
            //     url: "/admin/testh5",
            //     type: 3,
            //     key: 14,
            //     children: [
            //     ]
            // },
            {
                name: "小程序审核",
                url: "/admin/Submission",
                type: 3,
                key: 12,
                children: [
                ]
            },
            // {
            //     name: "小程序信息",
            //     url: "/admin/Information",
            //     type: 3,
            //     children: [],
            //     key:3,
            // },
            {
                name: "小程序发布",
                url: "/admin/Release",
                type: 3,
                children: [],
                key: 4,
            },
            {
                name: "微信支付设置",
                url: "/admin/wechatPay",
                type: 3,
                key: 10,
                children: [
                ]
            }
            // {
            //     name: "小程序发布",
            //     url: "/admin/Release",
            //     type: 3,
            //     children: [],
            //     key:5,
            // }
        ]
    },
    // {
    //     name: "账户设置",
    //     url: "/admin/companyInfo",
    //     icon: 'iconxiaochengxuguanli',
    //     type: 2,
    //     key: 6,
    //     children: [
    //         {
    //             name: "基本资料",
    //             url: "/admin/UserSetting",
    //             type: 3,
    //             children: [],
    //             key: 7,
    //         },
    //         {
    //             name: "公司信息",
    //             url: "/admin/companyInfo",
    //             type: 3,
    //             children: [],
    //             key: 8,
    //         },
    //         {
    //             name: "修改密码",
    //             url: "/admin/modifyPassword",
    //             type: 3,
    //             children: [],
    //             key: 9,
    //         }
    //     ]
    // }
]
class Menu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: 2,
            navmobile: false,
            haveAuth: false,
            isShowTips: false,
        }
    }

    componentDidMount() {
        let haveAuth = sessionStorage.getItem("haveAuth")
        this.setState({
            haveAuth
        }, () => {
            console.log(this.state.haveAuth)
        })
    }
    componentWillMount() {
    }
    componentWillReceiveProps(nextProps) {

    }
    MenuClick(type = 2, url, index, key) {
        let activeKey = sessionStorage.getItem("key");
        if (type == 1) {
            window.location.href = url;
        } else if (type == 2) {
            if (activeKey == key) {
                sessionStorage.setItem("key", " ");
                this.setState({
                    key: " "
                })
            } else {
                let item = MenuCinfig[index];
                let arr = item.children && item.children.filter(i => {
                    return activeKey == i.key;
                })
                console.log(arr)
                if (arr.length != 0) {
                    sessionStorage.setItem("key", " ");
                    this.setState({
                        key: " "
                    })
                }
                else {
                    sessionStorage.setItem("key", key);
                    this.setState({
                        key: key
                    })
                }
            }
        } else {
            sessionStorage.setItem("key", key)
            this.props.history.replace(url)
            this.setState({
                key: key
            })
        }
    }
    url(item, key1) {
        const key = sessionStorage.getItem("key");
        if (key == item.key) {
            return item.children && item.children.length != 0 ? "select" : 'active'
        }
        let arr = item.children && item.children.filter(i => {
            return key == i.key;
        })
        return arr.length != 0 ? "select" : ''

    }
    NumeView(data = []) {
        console.log(this.props, "props")
        return data.map((item, index) => {
            return <div className={['tabs', this.url(item, this.state.key)].join(' ')} key={index}>
                <div onClick={this.MenuClick.bind(this, item.type, item.url, index, item.key)}>
                    <span className={item.icon + ' iconfontAdmin icon'}></span>
                    <span>{item.name}</span>
                    <span className="rightIcon iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing"></span>
                </div>
                <ul>
                    {
                        item.children.map((item, index) => {
                            return <li key={index} className={this.url(item)} onClick={this.MenuClick.bind(this, item.type, item.url, index, item.key)}>
                                {item.name}
                            </li>
                        })
                    }
                </ul>
            </div>
        })
    }

    closeTip() {
        this.setState({
            isShow:false,
            isShowTips: false
        })
    }
    goTo() {
        window.location.href = "/Choose"
    }

    goToAdmin = () => {
        if(this.props.adminReducer.shopData.merchantId!=undefined){
            Ajax.GET(`/ldmshop/merchant/merChantToken?merChantId=${this.props.adminReducer.shopData.merchantId}`)
            // http.get(`/ldmshop/merchant/merChantToken?merChantId=${this.props.adminReducer.shopData.merchantId}`)
                .then(res => {
                    if (res.code == 0) {
                        let data = res;
                        data.data.roleList[0] = "ROLE_SUPER_ADMIN";
                        data.data.name = data.data.phone;
                        let shopData = sessionStorage.getItem("shopData")
                        let shopName = JSON.parse(shopData).merchantName
                        let shopId = JSON.parse(shopData).merchantId
                        let logo = JSON.parse(shopData).logo
                        let menuId = JSON.parse(shopData).menuId
                        let mainAppId = JSON.parse(shopData).mainAppId
                        
                        if(logo==null){
                            logo=''
                        }
                       
                        console.log(shopName)
                        // let url = `http://admin.shop.lingdaima.cn/user/login?data=${JSON.stringify(data)}&shopName=${shopName}&miniHeadImg=${logo}&shopId=${shopId}`
                        let env = process.env.NODE_ENV;
                        let url = "";
                        let userData = `data=${JSON.stringify(data)}&shopName=${shopName}&miniHeadImg=${logo}&shopId=${shopId}&menuId=${menuId}&appId=${mainAppId}`
                        // console.log(a)
                        userData=secret.Encrypt(userData)
                        if (env === 'development') {
                            url = `http://localhost:3000/user/login?${userData}`
                          } else if (env === 'production') {
                            url = `http://admin.sifshop.cn/user/login?${userData}`
                          }
                        // let url = `http://admin.shop.lingdaima.cn/user/login?data=${JSON.stringify(data)}&shopName=${shopName}&miniHeadImg=${logo}&shopId=${shopId}`
                        // let a = decodeURI(url ? url.split('=')[2].split('&')[0] : '');
                        // let b = decodeURI(url ? url.split('=')[3].split('&')[0] : '');
                        // let c = decodeURI(url ? url.split('=')[4] : '');
                        // console.log(url)
                        // console.log(a)
                        // console.log(b )
                        // console.log(c)
                        window.location.href = url;
                    } else {
                        message.error(res.errmsg);
                    }
                })
        }
        else{
            this.setState({
                isShowTips:true
            })
        }
        
    }


    render() {
        const { logo, merchantName, mainServiceClass,menuId } = this.props.adminReducer.shopData;
        console.log(this.props.adminReducer.shopData)
        return (
            <nav>
                <div className="header">
                    <div className="icon">
                        <img src={logo || icon}>
                        </img>
                    </div>
                    <div className="right">
                        <div>{merchantName}</div>
                        <div>{mainServiceClass}{menuId == 1 ? "基础版" : ""}{menuId == 2 ? "分销版" : ""}{menuId == 3 ? "直播版" : ""}</div>
                    </div>
             
                </div>
 
                {this.NumeView(MenuCinfig)}
                <div className={'tabs'} onClick={this.goToAdmin}>
                    <div>
                        <span className="icondujiayunicon-02 iconfontAdmin icon"></span>
                        <span>店铺管理</span>
                        <span className="rightIcon iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing"></span>
                    </div>
                </div>
                <Tips
                    title={"提示"}
                    isShow={this.state.isShowTips}
                    closeTip={this.closeTip.bind(this)}
                >
                    <div className="tipView">
                        <div className="about1">
                            您还未创建小程序
                    </div>
                        <div className="confirm" onClick={this.goTo.bind(this)}>确认</div>
                    </div>
                </Tips>
            </nav>
        )
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
})
export default withRouter(connect(mapStateToProps, null)(Menu));