import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Radio } from 'antd'
import Button from 'antd/es/button';
import success from '../../assets/images/7DC91AF2-6243-4ca0-B0EE-813AF514ABE8.png'

import './Code.less'

const location = window.location
class Code extends Component {
    state = {
       name:'',
       update:true
    };
    componentDidMount() {
        document.title="妈妈赶集社交电商平台"
  
    }
    componentWillMount() {
    }
    componentWillUnmount(){
        document.title="社交电商小程序平台"
    }

   

    render() {
        console.log(this.state)
        return (
            <div id="Authsuccess1">
                <div className="icon">
                    <img src="https://dotlinkface-lingdaima.oss-cn-hangzhou.aliyuncs.com/1584526877317"></img>
                    
                </div>
                    <div className="kaitong1">长按识别进入小程序</div>
                
            </div>
        )
    }
}
export default Code;