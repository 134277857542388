import Home from '../pages/Home/Home'
import Login from '../pages/User/components/login'
import Register from '../pages/User/components/signup'
import Reset from '../pages/User/components/reset'
import Forgot from '../pages/User/components/forgot'
import admin from '../pages/Admin/admin/admin'
import mainPage from '../pages/Admin/mainPage/mainPage'
import Information from '../pages/Admin/Information/Information'
import Auth from '../pages/Admin/Auth/Auth'
import Submission from '../pages/Admin/Submission/Submission'
import Release from '../pages/Admin/Release/Release'
import Choose from '../pages/Choose/Choose'
import CreateShop from '../pages/CreateShop/CreateShop'
import OrderSure from '../pages/OrderSure/OrderSure'
import PaySuccess from '../pages/PaySuccess/PaySuccess'
import ChooseModel from '../pages/ChooseModel/ChooseModel'
import aboutus from '../../src/aboutus/components/index'  
import termservice from '../../src/termservice/components/index'
import copyright from '../../src/copyright/components/copyright'
import legalnotice from '../../src/legalnotice/components/index'
import faq from '../../src/faq/components/index'
import onetoone from '../../src/onetoone/components/onetoone'
import casechannel from '../../src/casechannel/components/casechannel'
import msgback from '../../src/msg/components/msgback'
import companyInfo from '../pages/Admin/manage/companyInfo'
import modifyPassword from '../pages/Admin/manage/modifyPassword'
import UserSetting from '../pages/Admin/manage/UserSetting'
import wechatPay from '../pages/Admin/wechatPay/wechatPay'
import AuthSuccess from '../pages/AuthSuccess/AuthSuccess'
import AuthError from '../pages/AuthError/AuthError'
import Code from '../pages/Code/Code'
import listPage from '../pages/Admin/listPage/listPage'
import test from '../pages/Admin/test/test'
import testh5 from '../pages/Admin/testh5/testh5'
import alipayConfig from '../pages/Admin/alipayConfig/alipayConfig'
import alipayWithdrawal from '../pages/Admin/alipayWithdrawal/alipayWithdrawal'
import alipayList from '../pages/Admin/alipayList/alipayList'
const config = [
    {
        name:"Home",
        Link:'/Home',
        component:Home,
        router:[]
    },
    {
        name:'Login',
        Link:'/Login',
        component:Login,
        router:[]
    },
    {
        name:'signup',
        Link:'/signup',
        component:Register,
        router:[]
    },
    {
        name:'forgot',
        Link:'/forgot',
        component:Forgot,
        router:[]
    },
    {
        name:'reset',
        Link:'/reset',
        component:Reset,
        router:[]
    },
    {
        name:'Choose',
        Link:'/Choose',
        component:Choose,
        router:[]
    },
    {
        name:'OrderSure',
        Link:'/OrderSure',
        component:OrderSure,
        router:[]
    },
    {
        name:'PaySuccess',
        Link:'/PaySuccess',
        component:PaySuccess,
        router:[]
    },
    {
        name:'AuthSuccess',
        Link:'/AuthSuccess',
        component:AuthSuccess,
        router:[]
    },
    {
        name:'Code',
        Link:'/Code',
        component:Code,
        router:[]
    },
    {
        name:'AuthError',
        Link:'/AuthError',
        component:AuthError,
        router:[]
    },
    {
        name:'ChooseModel',
        Link:'/ChooseModel',
        component:ChooseModel,
        router:[]
    },
    {
        name:'CreateShop',
        Link:'/CreateShop',
        component:CreateShop,
        router:[]
    },
    {
        name:'aboutus',
        Link:'/aboutus',
        component:aboutus,
        router:[]
    },
    {
        name:'termservice',
        Link:'/termservice',
        component:termservice,
        router:[]
    },
    {
        name:'copyright',
        Link:'/copyright',
        component:copyright,
        router:[]
    },
    {
        name:'legalnotice',
        Link:'/legalnotice',
        component:legalnotice,
        router:[]
    },
    {
        name:'faq',
        Link:'/faq',
        component:faq,
        router:[]
    },
    {
        name:'onetoone',
        Link:'/onetoone',
        component:onetoone,
        router:[]
    },
    {
        name:'casechannel',
        Link:'/casechannel',
        component:casechannel,
        router:[]
    },
    {
        name:'msgback',
        Link:'/msgback',
        component:msgback,
        router:[]
    },
    {
        name:'admin',
        Link:'/admin',
        component:admin,
        router:[
            {
                name:'mainPage',
                Link:'/admin/mainPage',
                component:mainPage
            },
            {
                name:'listPage',
                Link:'/admin/listPage',
                component:listPage
            },
            {
                name:"Information",
                Link:'/admin/Information',
                component:Information
            },
            {
                name:"Auth",
                Link:'/admin/Auth',
                component:Auth
            },
            {
                name:"Submission",
                Link:'/admin/Submission',
                component:Submission
            },
            {
                name:"Release",
                Link:'/admin/Release',
                component:Release
            },
            {
                name:"companyInfo",
                Link:'/admin/companyInfo',
                component:companyInfo
            },
            {
                name:"modifyPassword",
                Link:'/admin/modifyPassword',
                component:modifyPassword
            },
            {
                name:"UserSetting",
                Link:'/admin/UserSetting',
                component:UserSetting
            },
            {
                name:"wechatPay",
                Link:'/admin/wechatPay',
                component:wechatPay
            },
            {
                name:"test",
                Link:'/admin/test',
                component:test
            },
            {
                name:"testh5",
                Link:'/admin/testh5',
                component:testh5
            },
            {
                name:"alipayConfig",
                Link:"/admin/alipayConfig",
                component:alipayConfig
            },
            {
                name:"alipayWithdrawal",
                Link:"/admin/alipayWithdrawal",
                component:alipayWithdrawal
            },
            {
                name:"alipayList",
                Link:"/admin/alipayList",
                component:alipayList
            }
        ]
    }
]
export default config;