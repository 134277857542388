import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import {Ajax} from '../../../utils/ajax'
import http from '../../../http/http'
import { message } from 'antd';
import JSEncrypt from 'jsencrypt';
require('./forgot.css');

const $ = window.$;

const location = window.location;

var React = require("react");

var createReactClass = require('create-react-class');
message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
  });

export default createReactClass({
  getInitialState: function() {
    return {
      secondsElapsed: 0,
      phoneError:"",
      codeError:"",
      passwordError:"",
      repasswordError:"",
      showState: 1,
      disabled: false,
      sendState: 0,
      stateTime: 60,  //
      emailState: 1,
      errorMsg: ''
    };
  },
  tick: function() {
    this.setState({secondsElapsed: this.state.secondsElapsed + 1});
  },

  checkoutPhone: function(){
    var email = $(this.refs["phone-email"]);
    // $(this.refs["rua-forgot-phone"]).hide();    
    if(!email.val().trim()){
        this.setState({phoneError:"手机号码不能为空"})
        $(this.refs["rua-forgot-phone"]).show().css("display","block");
    } else if(!/^1[0-9]{10}$/.test(email.val()) && !/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(email.val())){
        this.setState({phoneError:"手机号码格式不正确"})
        $(this.refs["rua-forgot-phone"]).show().css("display","block");
    } else if (/^1[0-9]{10}$/.test(email.val())){
        this.setState({phoneError:"", emailState: 0,showState:1})
        $(this.refs["rua-forgot-phone"]).hide()
        return true;
    } else if (/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(email.val())) {
        this.setState({phoneError:"", emailState: 1,showState:0});
        $(this.refs["rua-forgot-phone"]).hide()
        return true;
    }
},

checkoutCode: function(){
    var code = $(this.refs["code"]);
    var email = $(this.refs["phone-email"]);
    var value  = this.checkoutPhone();
    var self = this;
    // $(this.refs["rua-forgot-code"]).hide()
    if(!value)return 
    if(!code.val().trim()){
        this.setState({codeError:"验证码不能为空"})
        // $(this.refs["rua-forgot-code"]).css("display","block");
        $(this.refs["rua-forgot-phone"]).show().css("display","block");
    } else{
        $.post("/json/user/verfycode/"+email.val().trim(), {code:code.val().trim()}, function(result){
            if(result.success){
                self.setState({codeError: "" })
                $(self.refs["rua-forgot-phone"]).hide()
                return true
            } else {
                self.setState({codeError:result.error})
                $(self.refs["rua-forgot-phone"]).show().css("display","block");
                return false
            }
        })
    }
},

checkoutPassword: function(){
    var password = $(this.refs["password"])
    // $(this.refs["rua-forgot-pas"]).hide()

    if(!password.val().trim()){
        this.setState({passwordError:"密码不能为空"})
        // $(this.refs["rua-forgot-pas"]).css("display","block");
        $(this.refs["rua-forgot-phone"]).show().css("display","block");
    }
    else if(!/^\w{8,16}$/.test(password.val())){
        this.setState({passwordError:"密码由8-16位的数字/字母/英文符号组成"})
        // $(this.refs["rua-forgot-pas"]).css("display","block");
        $(this.refs["rua-forgot-phone"]).show().css("display","block");
    } else{
        this.setState({passwordError:""})
        $(this.refs["rua-forgot-phone"]).hide()
        return true;
    }

},
checkoutRepassword: function(){
  var repeatPassword = $(this.refs["rePassword"]);
  var password = $(this.refs["password"])
//   $(this.refs["rua-forgot-repas"]).hide()

    if(password.val() !== repeatPassword.val()){
      this.setState({repasswordError:"两次输入的密码不相等"})
        //   $(this.refs["rua-forgot-repas"]).css("display","block");
        $(this.refs["rua-forgot-phone"]).show().css("display","block");
    } else{
      this.setState({repasswordError:""})
        $(this.refs["rua-forgot-phone"]).hide()
        return true;
   }
},
  componentDidMount: function() {
    $('.web').scrollTop(0);
    var email = $(this.refs["phone-email"]);
    var password = $(this.refs["password"]);
    var code = $(this.refs["code"]);    
    var repeatPassword = $(this.refs["rePassword"]);
    var self = this;

    Ajax.GET(`/ldmshop/admin/getKey`)
            .then(res => {
                console.log(res)
                this.setState({
                    publicKey: res.data.key
                })
            })
            .catch(error => {

            })

    email.on("blur",function(){
      self.checkoutPhone()
    })

    password.on("blur",function(){
      self.checkoutPassword()
    })


    repeatPassword.on("blur",function(){
        self.checkoutRepassword()
    })

    // code.on('blur', function () {
    //     self.checkoutCode();
    // })
  },
  componentWillUnmount: function() {
    clearInterval(this.interval);
  },

  valid:function(){
    var self = this;
      self.checkoutPhone()
      self.checkoutPassword()
      self.checkoutRepassword()
      if(self.state.phoneError == "" && self.state.passwordError == "" && self.state.repasswordError == ""){
          return true
      }
  },

  sendCode:function(ev){

    ev&&ev.preventDefault();
    var self = this;
    // var valid = this.checkoutPhone();
    // if(!valid)return;
    var email = $(this.refs["phone-email"]);
    
    
    this.setState({disabled: true});
    Ajax.POST('ldmshop/admin/sendCode', { phone: email.val().trim() })
            .then(res => {
                if(res && res.code === 0){
                    self.setState({ "sendState": 2 })
                    self.state.stateTime = 60
                    var time = setInterval(function () {
                        var nowNum = self.state.stateTime;
                        if (nowNum == 0) {
                            clearInterval(time);
                            self.setState({ "sendState": 0, "disabled": false, stateTime: 60 })
                        }
                        else {
                            self.setState({ "stateTime": nowNum - 1 })
                        }
                        
                    }, 1000)
                }
                
            })
            .catch(error => {
                console.log(error)
            })

  },
  
  submitforgot: function (e) {
    var self = this;

    var account = $(this.refs['phone-email']).val();
    var code = $(this.refs['code']).val();
    var password = $(this.refs['password']).val();
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(this.state.publicKey);
    let passwordEncrypt = encrypt.encrypt(password);
    var rePassword = $(this.refs['rePassword']).val();
    // if (!this.valid()) {
    //   return ;
    // }
    if(this.checkoutPassword() && this.checkoutRepassword() && this.checkoutPhone()) {
        $(this.refs["rua-forgot-phone"]).hide();
    } else {
        return ;
    }

    Ajax.POST('/ldmshop/admin/forgetPassword', { code:code,password:passwordEncrypt,phone:account })
            .then(res => {
                if(res && res.code === 0){
                    self.setState({ "sendState": 2 })
                    self.state.stateTime = 60
                    message.success('修改成功')
                    var time = setInterval(function () {
                        var nowNum = self.state.stateTime;
                        if (nowNum == 0) {
                            clearInterval(time);
                            self.setState({ "sendState": 0, "disabled": false, stateTime: 60 })
                        }
                        else {
                            self.setState({ "stateTime": nowNum - 1 })
                        }
    
                    }, 1000)
                    window.location.href('/login')
                }
                
                
            })
            .catch(error => {
                console.log(error)
                
            })


    // $.post('/json/user/login/forgot', { account: account, restpassword: password, code: code }, function (result) {
    //     if (result.success && !self.state.emailState) {
    //         if(sessionStorage &&  sessionStorage.getItem("redirect")){
    //             var redirect = sessionStorage.getItem("redirect")
    //             sessionStorage.removeItem("redirect");
    //             location.href ="/user/login" || redirect;
    //         } else  if (result.defaultReturnUrl) {
    //             location.href = result.defaultReturnUrl;
    //         } else{
    //             location.href = "/user/login"
    //         }
    //     } else if (result.success && self.state.emailState){
    //         location.href = '/user/email/forget/'+account;
    //     } else {
    //         if(result.errors.length) {
    //             $(self.refs["errors"]).text(result.errors)
    //         }  else if(Object.keys(result.errfor).length !== 0){
    //             if (result.errfor.phoneerror || result.errfor.email || result.errfor.emailerror || result.errfor.accounterror ) {
    //                 self.setState({ phoneError: result.errfor.phoneerror || result.errfor.email || result.errfor.emailerror || result.errfor.accounterror})
    //                 $(this.refs["rua-forgot-phone"]).show().css("display","block");
    //             }
    //             if (result.errfor.passworderror) {
    //                 self.setState({ passwordError: result.errfor.passwordError })
    //                 $(this.refs["rua-forgot-phone"]).show().css("display","block");
    //             }
    //             if (result.errfor.codeerror) {
    //                 self.setState({ codeError: result.errfor.codeError })
    //                 $(this.refs["rua-forgot-phone"]).show().css("display","block");
    //             }
    //         }

    //     }
    // })
  },


  render: function() {
    return (
      <div>
      <Header active="forgot" type="forgot" cur="register"></Header>
         <div className="forgot-wrapper">
            <div className="container forgot-page">
                <div className="forgot-box-wrapper">
                    <div className="forgot-left-image">
                        {/* <img src="http://lingdaima.dotlinkface.com/login-registor-image.png" alt=""/> */}
                      
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/login-registor-image.png" alt="" />
                    </div>
                    <div className="forgot-box">
                        <div className="forgot-form clearfix">
                            <div className="form-row">
                                <div className="form-row-error">
                                    <span className="err-msg" ref="rua-forgot-phone">{this.state.phoneError || this.state.codeError || this.state.passwordError || this.state.repasswordError}</span>
                                    <span className="forgot-err" ref="errors"></span>
                                </div>
                                <div className="form-row-content">
                                    <label htmlFor="tel-email" className="signup-icon"><i className="icon iconfont icon-yonghu"></i></label>
                                    <input type="text" ref="phone-email" className="tel-email" id="tel-email" placeholder="请输入您的手机号码"/>
                                </div>
                            </div>
                            <div className={ 'form-row ' + (this.state.showState === 1 ? 'show' : 'hide')}>
                                {/* <div className="form-row-error">
                                    <span className="err-msg" ref="rua-forgot-code">{this.state.codeError}</span>
                                </div> */}
                                <div className="form-row-content">
                                    <label htmlFor="validate" className="signup-icon"><i className="icon iconfont icon-yanzhengma"></i></label>
                                    <div className="code-wrapper">
                                        <input type="text" ref="code" className="validate" id="validate" placeholder="输入验证码"/>
                                        <button ref="getCode" type="button" disabled={this.state.disabled} className={this.state.sendState !== 2 ? "validate-btn" : "code-button"} onClick={this.sendCode}>{ this.state.sendState !== 2 ? '获取验证码' : this.state.stateTime + 's后重新获取'}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row ">
                                {/* <div className="form-row-error">
                                    <span className="err-msg" ref="rua-forgot-pas">{this.state.passwordError}</span>
                                </div> */}
                                <div className="form-row-content">
                                    <label htmlFor="password" className="signup-icon"><i className="icon iconfont icon-mima"></i></label>
                                    <input type="password" ref="password" className="password" id="password" placeholder="请输入您的新密码"/>
                                </div>
                            </div>
                            <div className="form-row  ">
                                {/* <div className="form-row-error">
                                    <span className="err-msg" ref="rua-forgot-repas">{this.state.repasswordError}</span>
                                </div> */}
                                <div className="form-row-content">
                                    <label htmlFor="rePassword" className="signup-icon"><i className="icon iconfont icon-mima"></i></label>
                                    <input type="password" ref="rePassword" className="password" id="rePassword" placeholder="请再次输入您的新密码"/>
                                </div>
                            </div>
                            
                            <div className="form-row  ">
                                <div className="form-row-content  forgot-btn">
                                    <button className="black-btn" type="button" onClick={this.submitforgot}>完成</button>
                                </div>
                                <div className="forgot-cli-log">
                                    <a href="/login">点击登录</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
        <Footer></Footer>
      </div>
    );
  }
});
