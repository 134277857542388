import React from 'react'
import  './onetoone.css'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
const $ = window.$;
export default  class OnetoOne extends React.Component{
    constructor(props){
        super(props)
        this.state={
            designerInfo: [
                {
                    img: 'http://lingdaima.dotlinkface.com/imgonetoone/onetoone-user-img-demo.png',
                    name: '沈晓言',
                    alialsName: 'SEASON',
                    experience: '拥有10年以上设计经验，曾经供职于阿里、中国移动、吉利控股等企业，参与过淘宝双11视觉设计，中国移动和阅读安卓客户端设计师',
                    hobbies: '运动、旅游、摄影',
                    speciality: '移动端设计能力出众',
                    sex: 0
                },
                {
                    img: 'http://lingdaima.dotlinkface.com/imgonetoone/onetoone-designer-czh.png',
                    name: '陈忠恒',
                    alialsName: '黑眼圈',
                    experience: '多年资深设计经验，曾为多个国内品牌设计品牌网站官网及全案设计。',
                    hobbies: '设计',
                    speciality: '全案设计，动画设计',
                    sex: 1
                },
                {
                    img: 'http://lingdaima.dotlinkface.com/imgonetoone/onetoone-designer-wl.png',
                    name: '王蕾',
                    alialsName: 'RAY',
                    experience: '5年视觉及交互设计工作经验。参与并主导过多个线上项目，熟悉项目的运作流程及方式，能够把控整个项目UI/UE设计',
                    hobbies: '美食、游戏',
                    speciality: 'UE设计',
                    sex: 0
                },
                {
                    img: 'http://lingdaima.dotlinkface.com/imgonetoone/zmj-desinger.jpg',
                    name: '钟梦姣',
                    alialsName: '姣姣',
                    experience: '3年视觉交互设计经验，结合用户体验对产品进行持续的优化，参与过多个线上项目',
                    hobbies: '旅游、美食',
                    speciality: 'UI设计',
                    sex: 0
                },
                {
                    img: 'http://lingdaima.dotlinkface.com/imgonetoone/WechatIMG150.jpeg',
                    name: '马甜甜',
                    alialsName: '甜甜',
                    experience: '3年视觉及交互设计工作经验，参与过多个线上项目',
                    hobbies: '摄影、游戏',
                    speciality: '绘画',
                    sex: 0
                },
                {
                    img: 'https://dotlinkface-avatar.oss-cn-shanghai.aliyuncs.com/zUSdM4hB332GM3Sz58QomEymtiSGnW2Nxf8Bn0kA.jpg',
                    name: '苏晓',
                    alialsName: 'SuXiao',
                    experience: '多年视觉及交互设计工作经验，参与过多个线上项目',
                    hobbies: '运动、音乐、美食',
                    speciality: 'UI设计',
                    sex: 0
                }
            ],
            btmBannerInfo: [
                {
                    title: '噗心农场',
                    info: '做网站像种田，自然农品的标准是没有标准，就是最好的标准。心法是门内无门，门外有门；唯借假修真而已。在纷繁的社会形态中，守好本分二字不只是小难。SIFSHOP关心客户需求，注重消费者体验，高效沟通，溯源本质，快速产出。优秀的团队，力荐。',
                    bannerImg: 'http://lingdaima.dotlinkface.com/casechannel/casechannel-1.png',
                    webUrl: ''
                },
                {
                    title: '农家土货',
                    info: 'SIFSHOP公司是一家非常专业的网站一体化解决方案服务商，能提供全方位的网站设计、运营、维护等一系列专业服务，满足各行业对网站的综合需求，所设计的web站点兼顾美感、灵动、科学、体验等特点，是一家非常理想的合作伙伴。其团队工作人员热情、专业、服务意识强，能准确理解客户的需求，提供完美的设计方案和交付成果。',
                    bannerImg: 'http://lingdaima.dotlinkface.com/casechannel/casechannel-4.png',
                    webUrl: ''
                },
                {
                    title: '山丘工业设计',
                    info: '专业的人，做专业的事，靠谱！ 网页的设计风格主流，大气和国际范，网页整体配色和我提供的案例背景色同色系，视觉感受柔和，比较意外的是网页还可以自动匹配不同尺寸的屏幕，手机，相当的强大。很多客户看过我的新网站，都会向我打听是谁家做的，好评比较一致。SIFSHOP真是个不错的选择。',
                    bannerImg: '',
                    webUrl: ''
                },
                {
                    title: '百纳汇商贸网',
                    info: '我是浙江文辰贸易有限公司的，初次相识SIFSHOP科技，一个阳光的团队。能按照客户的要求进行制作，更多的为客户考虑。我公司定制的“百纳汇商贸网”宣传网页，在百度上上线，推广宣传的效果不错！有了这次的满意合作体验，希望以后有更多的合作机会!祝愿SIFSHOP科技越走越远，合作的平台越来越多，更加优秀',
                    bannerImg: 'http://lingdaima.dotlinkface.com/casechannel/casechannel-10.png',
                    webUrl: ''
                }
            ]           
        }
    }

    windowResize() {
        if($(window).width() <= 768){
            $('.onetoone-banner img').attr({'src':'http://lingdaima.dotlinkface.com/imgonetoone/onetoone-banner-mobile.png'})
        } else {
            window.firstLeftMove = '-23%'
            $('.onetoone-banner img').attr({'src':'http://lingdaima.dotlinkface.com/imgonetoone/onetoone-banner.png'})
        }                
    }

    componentWillMount() {
        
    }
    componentDidMount(){
        $('.web').scrollTop(0);
        this.windowResize()
        window.addEventListener('resize', this.windowResize.bind(this))
        new Swiper('.swiper-container-designer', {
            slidesPerView: 3,
            loop: true,
            grabCursor: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            autoplay: {
              delay: 2500,
              disableOnInteraction: false
            },
            breakpoints: {
              768: {
                slidesPerView: 1
              }
            }
        });
        new Swiper('.swiper-container-process', {
            slidesPerView: 2,
            grabCursor: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false
            }
        })
        var _this = this;
        var mySwiper = initSwiper()
        window.onresize = function() {
            mySwiper.destroy(true);
            mySwiper = initSwiper()                  
        }     
        $('#btm-prev').click(function(){
            mySwiper.slidePrev();
          })
        $('#btm-next').click(function(){
        mySwiper.slideNext();
        })  
        function initSwiper() {
            var currentPage = $(".current-page");
            var currentIndex = 0;
            var bgTitle = $('.bg-title');
            var bgContent = $('.bg-content');
            var spaceBetween = '6.1%';
            var activeSpaceBetween = "9.9%"
            var touchStartEle = null;
            if($(window).width() < 768) {
                spaceBetween = '8.5%';
                activeSpaceBetween = "10.5%"
            }
            return new Swiper('.swiper-container', {
                spaceBetween: spaceBetween,
                centeredSlides: true,
                slidesPerView: 'auto',
                loop: true,
                grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },               
                on: {
                    slideChangeTransitionStart: function(){                                    
                        $(".swiper-container .swiper-slide.swiper-slide-active").prev().attr("style", "margin-right: " + activeSpaceBetween + ";").siblings().attr("style", "margin-right: " + spaceBetween +";")
                    },
                    slideChangeTransitionEnd: function() {     
                        currentIndex = parseInt($(".swiper-container .swiper-slide.swiper-slide-active").attr("index"));                    
                        var htmlIndex = currentIndex > 10 ? (currentIndex + 1) : '0' + (currentIndex + 1);
                        currentPage.html(htmlIndex);
                    },
                    transitionEnd: function() {
                        currentIndex = parseInt($(".swiper-container .swiper-slide.swiper-slide-active").attr("index"));
                        var title = _this.state.btmBannerInfo[currentIndex].title
                        var content = _this.state.btmBannerInfo[currentIndex].info                      
                        bgTitle.html(title);
                        bgContent.html(content);
                        bgTitle.fadeIn(300);
                        bgContent.fadeIn(300);     
                    },
                    touchStart: function(event){
                        touchStartEle = $(".swiper-container .swiper-slide.swiper-slide-active");
                    },   
                    touchEnd: function() {
                        var touchEndEle = $(".swiper-container .swiper-slide.swiper-slide-active");
                        if(touchStartEle[0] != touchEndEle[0]) {
                            bgTitle.fadeOut();
                            bgContent.fadeOut();
                        }
                        touchEndEle.prev().attr("style", "margin-right: " + activeSpaceBetween + ";").siblings().attr("style", "margin-right: " + spaceBetween +";")
                    }
                }
            });
        }
    }

    renderDesigner() {
        return this.state.designerInfo.map((item, index) => {
            return <div className="swiper-slide" key={index}>
            <div className="user-img">
                <div className="user-img-content"><img src={item.img} /></div>
                <div className="sex-icon" style={{backgroundImage: item.sex == 0 ? 'url(http://lingdaima.dotlinkface.com/imgonetoone/onetoone-girl-icon.png)':'url(http://lingdaima.dotlinkface.com/imgonetoone/onetoone-boy-icon.png)'}}></div>
            </div>
            <div className="user-name">{item.name} ({item.alialsName})</div>
            <div className="experience info-list">
                <p>【经历】</p>
                <p>{item.experience}</p>
            </div>
            <div className="speciality info-list">
                <p>【特长】</p>
                <p>{item.speciality}</p>
            </div>
            <div className="hobbies info-list">
                <p>【爱好】</p>
                <p>{item.hobbies}</p>
            </div>
        </div>
        })
    }

    // openkefu(){
    //     var kefu101 = "http://wpa.qq.com/msgrd?v=3&uin=2435670291&site=oicqzone.com&menu=yes";
    //     var kefu102 = "mqqwpa://im/chat?chat_type=wpa&uin=2435670291&version=1&src_type=web&web_src=oicqzone.com";
    //     if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    //         window.open(kefu102) ;
    //     } else {
    //         window.open(kefu101) ;
    //     }
    // }

    render(){
        return (
        <div className="onetoone">
            <div className="container-fluid">
                <div className="row">

                    <div className="onetoone-banner">
                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-banner.png" alt="" />                       
                    </div>
                    
                    <div className="container designer-team">
                        <div className="row">
                            <h3 className="txtcenter">设计师服务团队</h3>
                            <div className="txt-en">
                                <span className="txt-left"><i></i></span>
                                <span className="txt-center">DESIGN TEAM</span>
                                <span className="txt-right"><i></i></span>
                            </div>
                        </div>

                        <div className="container examples-content">
                            <div className="swiper-container-designer">
                                <div className="swiper-wrapper">
                                    {this.renderDesigner()}                                                                                                
                                </div>
                                <div className="swiper-pagination"></div>
                                <div className="swiper-button-next"><span className="icon iconfont icon--daxiajiantou"></span></div>
                                <div className="swiper-button-prev"><span className="icon iconfont icon--daxiajiantou"></span></div>
                            </div>                       
                        </div>
                    </div>

                    <div className="my-advantage">
                        <div className="container">
                            <div className="row">
                            <h3 className="txtcenter">我们的优势</h3>
                            <div className="txt-en">
                                <span className="txt-left"><i></i></span>
                                <span className="txt-center">OUR  ADVANTAGES</span>
                                <span className="txt-right"><i></i></span>
                            </div>
                            <div className="phone-advantage-list">
                                <div className="content">
                                    <h1>01</h1>
                                    <div className="first-boder"></div>                                            
                                    <div className="contet-right"></div>
                                    <div className="title">1对1服务</div>
                                    <div className="content-left">设计师1对1电话服务，
                                        <br/> 近距离洞察用户真实需求
                                    </div>
                                </div>
                                <div className="content">
                                    <h1>02</h1>
                                    <div className="first-boder"></div>                                            
                                    <div className="contet-right"></div>
                                    <div className="title">视觉量身设计</div>
                                    <div className="content-left">结合用户需求，利用
                                        <br/> 完美的网站视觉充分体现
                                    </div>
                                </div>
                                <div className="content">
                                    <h1>03</h1>
                                    <div className="first-boder"></div>                                            
                                    <div className="contet-right"></div>
                                    <div className="title">平台级功能支撑</div>
                                    <div className="content-left">平台级智能建站系统，
                                        <br/> 满足企业所需各项功能
                                    </div>
                                </div>
                                <div className="content">
                                    <h1>04</h1>
                                    <div className="first-boder"></div>                                            
                                    <div className="contet-right"></div>
                                    <div className="title">内容维护便捷</div>
                                    <div className="content-left">在线可视化编辑网站内容，
                                        <br/> 做你所想，即刻发布
                                    </div>
                                </div>
                            </div>
                            <div className="my-advantage-list col-md-12 col-xs-12">
                                <div className="row">
                                    <div className="col-md-3 col-xs-12">
                                        <div className="contet-right"></div>
                                        <div className="title">1对1服务</div>
                                        <div className="hover-content">
                                            <h1>01</h1>
                                            <div className="first-boder"></div>                                            
                                            <div className="contet-right"></div>
                                            <div className="title">1对1服务</div>
                                            <div className="content-left">设计师1对1电话服务，
                                                <br/> 近距离洞察用户真实需求
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-12">                                        
                                        <div className="contet-right "></div>
                                        <div className="title">视觉量身设计</div>
                                        <div className="hover-content">
                                            <h1>02</h1>
                                            <div className="first-boder"></div>                                            
                                            <div className="contet-right"></div>
                                            <div className="title">视觉量身设计</div>
                                            <div className="content-left">结合用户需求，利用
                                                <br/> 完美的网站视觉充分体现
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-12">
                                        <div className="contet-right "></div>
                                        <div className="title">平台级功能支撑</div>                                        
                                        <div className="hover-content">
                                            <h1>03</h1>
                                            <div className="first-boder"></div>                                            
                                            <div className="contet-right"></div>
                                            <div className="title">平台级功能支撑</div>
                                            <div className="content-left">平台级智能建站系统，
                                                <br/> 满足企业所需各项功能
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-12">
                                        <div className="contet-right "></div>
                                        <div className="title">内容维护便捷</div>                                           
                                            <div className="hover-content">
                                                <h1>04</h1>
                                                <div className="first-boder"></div>                                            
                                                <div className="contet-right"></div>
                                                <div className="title">内容维护便捷</div>
                                                <div className="content-left">在线可视化编辑网站内容，
                                                    <br/> 做你所想，即刻发布
                                                </div>
                                            </div>   
                                        </div>                                                                           
                                    </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="desginer-compared">
                        <div className="container">
                            <div className="row">
                            <h3 className="txtcenter">建站模式对比</h3>
                            <div className="txt-en">
                                <span className="txt-left"><i></i></span>
                                <span className="txt-center">PATTERN MATCHING</span>
                                <span className="txt-right"><i></i></span>
                            </div>
                            <div className="Compared-content col-md-12 col-xs-12">
                                <div className="compared-content-top">
                                    
                                    <div className="vs"></div>
                                    <div>
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-newlogo-icon.png" alt="" />
                                        <span>SIFSHOP智能建站</span>
                                    </div>
                                    <div>
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-net-icon.jpg" alt="" />
                                        <span>传统建站</span>
                                    </div>
                                </div>
                                <div className="compared-content-list">
                                    <div className="compared-content-item">
                                        <div className="item-2">
                                            <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-service-icon.png" alt="" />
                                            <span>服务</span>
                                        </div>
                                        <div className="item-1">
                                            <span>全程设计师1对1</span>
                                        </div>                                        
                                        <div className="item-3">
    
                                            <span>无</span>
                                        </div>
                                    </div>
                                    <div className="compared-content-item">
                                        <div className="item-2">
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-time-icon.png" alt="" />
                                            <span>耗时</span>
                                        </div>
                                        <div className="item-1">
                                            <span>1天时间</span>
                                        </div>                                       
                                        <div className="item-3">
                                            <span>反复沟通，3个月</span>
                                        </div>
                                    </div>
                                    <div className="compared-content-item">
                                        <div className="item-2">
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-design-icon.png" alt="" />
                                            <span>设计</span>
                                        </div>
                                        <div className="item-1">
                                            <span>根据需求，专人设计</span>
                                        </div>                                        
                                        <div className="item-3">
                                            <span>页面单一、模块化</span>
                                        </div>
                                    </div>
                                    <div className="compared-content-item">
                                        <div className="item-2">
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-show-icon.png" alt="" />
                                            <span>展示多样式</span>
                                        </div>
                                        <div className="item-1">
                                            <span>一套设计自动兼容PC、PAD、
                                                <br/> H5、微信等用户端
                                            </span>
                                        </div>                                       
                                        <div className="item-3">
                                            <span>只有PC端其他另收费</span>
                                        </div>
                                    </div>
                                    <div className="compared-content-item">
                                        <div className="item-2">
                                            <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-update-icon.png" alt="" />
                                            <span>内容更新</span>
                                        </div>
                                        <div className="item-1">
                                            <span>可视化修改内容，随时更新</span>
                                        </div>                                        
                                        <div className="item-3">
                                            <span>请专人维护修改麻烦</span>
                                        </div>
                                    </div>
                                    <div className="compared-content-item">
                                        <div className="item-2">
                                            <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-safe-icon.png" alt="" />
                                            <span>安全</span>
                                        </div>
                                        <div className="item-1">                                           
                                            <span>阿里云独立云服务器，<br/>7*24小时安全监控</span>
                                        </div>                                        
                                        <div className="item-3">
                                            <span>服务器公用，安全风险大，<br/>维护另收费</span>
                                        </div>
                                    </div>
                                    <div className="compared-content-item">
                                        <div className="item-2">
                                            <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-yuming-icon.png" alt="" />
                                            <span>域名</span>
                                        </div>
                                        <div className="item-1">
                                            <span>永久免费二级域名</span>
                                        </div>                                        
                                        <div className="item-3">
                                            <span>单独购买</span>
                                        </div>
                                    </div>
                                    {/* <div className="compared-content-item">
                                        <div className="item-2">
                                            <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-money-icon.png" alt="" />
                                            <span>费用</span>
                                        </div>
                                        <div className="item-1">
                                            <span>1198元/年</span>
                                        </div>                                        
                                        <div className="item-3">
                                            <span>5000元/年以上</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
    
                    <div className="process">
                        <div className="row">
                            <h3 className="txtcenter">服务流程</h3>
                            <div className="txt-en">
                                <span className="txt-left"><i></i></span>
                                <span className="txt-center">SERVICE PROCESS</span>
                                <span className="txt-right"><i></i></span>
                            </div>
                        </div>
                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-bottom-bg.png" />
                        <div className="process-phone">        
                            <div className="swiper-container-process process-phone-carousel">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide process-phone-item">
                                        <h1 className="process-step">01</h1>
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-1v1-icon.png" />
                                        <p>电话1对1沟通需求</p>
                                    </div>
                                    <div className="swiper-slide process-phone-item">
                                        <h1 className="process-step">02</h1>
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-hezuo-icon.png" />
                                        <p>确认合作关系</p>
                                    </div>
                                    <div className="swiper-slide process-phone-item">
                                        <h1 className="process-step">03</h1>
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-designpage-icon.png" />
                                        <p>设计网站</p>
                                    </div>
                                    <div className="swiper-slide process-phone-item">
                                        <h1 className="process-step">04</h1>
                                        <img src="http://lingdaima.dotlinkface.com/imgonetoone/onetoone-yanshou-icon.png" />
                                        <p>网站验收</p>
                                    </div>
                                </div>
                            </div>                    
                            <span className="center-bolder"></span>
                            <span className="icon iconfont icon-weibiaoti40"></span>                           
                        </div>
                    </div>
                   
                    <div className="onetoone-examples">
                        <div className="container">
                            <div className="row">
                                <h1 className="txtcenter">用户案例</h1>
                                <div className="txt-en">
                                    <span className="txt-left"><i></i></span>
                                    <span className="txt-center">CUSTOMER CASE</span>
                                    <span className="txt-right"><i></i></span>
                                </div>
                            </div>                        
                        </div>
                        <div className="white-bg">
                            <h4 className="bg-title"></h4>
                            <p className="bg-content"></p>
                        </div>
                        <div className="swiper-container btm-banner">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide" index="0">
                                    <img src="http://lingdaima.dotlinkface.com/product-01.png" />
                                    <div className="btm-hover-content">
                                        <a href="http://puxin.lingdaima.cn"></a>
                                    </div>
                                </div>
                                <div className="swiper-slide" index="1">
                                    <img src="http://lingdaima.dotlinkface.com/product-02.png" />
                                    <div className="btm-hover-content">
                                        <a href="http://njth.lingdaima.cn"></a>
                                    </div>
                                </div>
                                <div className="swiper-slide" index="2">
                                    <img src="http://lingdaima.dotlinkface.com/product-03.png" />
                                    <div className="btm-hover-content">
                                        <a href="http://shanqiu.lingdaima.cn"></a>
                                    </div>
                                </div>
                                <div className="swiper-slide" index="3">
                                    <img src="http://lingdaima.dotlinkface.com/product-04.png" />
                                    <div className="btm-hover-content">
                                        <a href="http://www.duoleduo.video/"></a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                        <ul className="web-pointer">
                            <li><span style={{cursor: 'pointer'}} className="icon iconfont icon-weibiaoti39" id="btm-prev"></span></li>
                            <li>
                                <span className="current-page" style={{fontSize: '16px'}}>01</span><span className="total-page" style={{color: '#ededed'}}>/04</span>
                            </li>
                            <li><span style={{cursor: 'pointer'}} className="icon iconfont icon-weibiaoti40" id="btm-next"></span></li>
                        </ul>                        
                    </div> 

                </div>
            </div>    
        </div>
        )
    }
}
