import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../../components/Header/Header'
import Menu from '../../../components/Menu/Menu'
import { Switch, RouteWithSubRoutes } from 'react-router-dom'
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute'
import { withRouter } from 'react-router-dom'
// import Menu from '../components/Menu/Menu'
import * as Admin from '../store/admin';
import Tips from '../../../components/Tips/Tips'
import http from '../../../http/http'
import { message, Input, Button, Icon,Upload } from 'antd'
import { Ajax } from '../../../utils/ajax'
import './wechatPay.less'
import gou from '../../../assets/images/7DC91AF2-6243-4ca0-B0EE-813AF514ABE8.png'

class admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            baomi: false,
            shanghuhao: '',
            merchantId:"",
            serialNumber:'',
            key: '',
            keyV3: '',
            isShowTips: false,
            message:'',
            certStatusStr:"",
            fileList: [
                {
                  uid: '-1',
                  name: 'xxx.png',
                  status: 'done',
                  url: 'http://www.baidu.com/xxx.png',
                },
              ],
              fileList2: [
                {
                  uid: '-1',
                  name: 'xxx.png',
                  status: 'done',
                  url: 'http://www.baidu.com/xxx.png',
                },
              ],
        }
    }

    componentDidMount() {
        // if (!this.props.adminReducer.selectData.authAppId) {
        //     this.setState({
        //         isShowTips: true
        //     })
        // }
        let moren = sessionStorage.getItem("shopData")
        let shanghuhao = JSON.parse(moren).merchantNumber
        let key = JSON.parse(moren).merchantKey
        let merchantId = JSON.parse(moren).merchantId
        let certStatusStr = JSON.parse(moren).certStatusStr
        let keyV3 = JSON.parse(moren).merchantKeyV3
        let serialNumber = JSON.parse(moren).serialNumber
        this.setState({
            shanghuhao,
            merchantId,
            certStatusStr,
            key,
            keyV3,
            serialNumber
        })
    }
    componentWillMount() {

    }
    componentWillUnmount() {

    }

    changeBaomi() {
        let baomi = this.state.baomi
        this.setState({
            baomi: !baomi
        })
    }
    changeShanghu(e) {
        this.setState({
            shanghuhao: e.target.value
        })
    }
    changeSerialNumber(e) {
        this.setState({
            serialNumber: e.target.value
        })
    }
    changeKey(e) {
        this.setState({
            key: e.target.value
        })
    }
    changeKeyV3(e) {
        this.setState({
            keyV3: e.target.value
        })
    }
    handIn() {
        // let data = sessionStorage.getItem("selectData")
        let data = this.props.adminReducer.shopData;
        let obj = {
            merchantId: data.merchantId,
            merchantNumber: this.state.shanghuhao,
            merchantKey: this.state.key,
            merchantKeyV3: this.state.keyV3,
            serialNumber: this.state.serialNumber

        }
        if (!this.props.adminReducer.shopData.mainAppId) {
            this.setState({
                isShowTips: true
            })
        }
        else {
            Ajax.POST('/ldmshop/merchant/weChatMerchant', obj)
                .then(res => {
                    if (res && res.code == 0) {
                        console.log(res)
                        this.setState({
                            isShow:true,
                            message:res.data
                        })
                    }
                    
                }).catch(errmsg => {
                    this.setState({
                        isShow:true,
                        message:errmsg
                    })
                    console.log(errmsg)
                })
        }

    }
    closeTip() {
        this.setState({
            isShow:false,
            isShowTips: false
        })
    }
    goTo() {
        window.location.href = "/admin/mainPage"
    }


    handleChange = info => {
        let fileList = [...info.fileList];
    
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
    
        this.setState({ fileList });
      };
    render() {
        let backToken = sessionStorage.getItem("backToken") == null ? '' : sessionStorage.getItem("backToken")
        const props = {
            action: `/ldmshop/merchant/upload?merchantId=${this.state.merchantId}`,
            onChange: this.handleChange,
            multiple: true,
            data:{
                merchantId:this.state.merchantId
            },
            headers:{
                'mark': 'back',
                'back_token': backToken
            },
            name:"multipartFile",
          };
          const pemProps = {
            action: `/ldmshop/merchant/upload/pem?merchantId=${this.state.merchantId}`,
            onChange: this.handleChange,
            multiple: true,
            data:{
                merchantId:this.state.merchantId
            },
            headers:{
                'mark': 'back',
                'back_token': backToken
            },
            name:"multipartFile",
          };
        return (
            <section>
            <Menu></Menu>

<div id="wechatPay">
                <p style={{ fontSize: '20px', fontWeight: '600' }}>微信支付设置  <span style={{color:'red',fontSize:'18px'}}>(交易处理完才可修改)</span></p>
                <div className="pay-item">
                    <p>请输入您的商户号:</p>
                    <Input value={this.state.shanghuhao} autoComplete="off" onChange={this.changeShanghu.bind(this)}></Input>
                    <a style={{color:'red',marginLeft:'30px'}} target="_blank" href="https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F">登录微信支付商户平台获取商户号</a>
                </div>
                <div className="pay-item">
                    <p>请输入证书序列号:</p>
                    <Input value={this.state.serialNumber} autoComplete="off" onChange={this.changeSerialNumber.bind(this)}></Input>
                    <a style={{color:'red',marginLeft:'30px'}} target="_blank" href="https://pay.weixin.qq.com/index.php/core/cert/api_cert#/api-cert-manage">登录微信支付商户平台获取商户序列号</a>
                </div>
                <div className="pay-item">
                    <p>请输入您的V2支付密钥:</p>
                    <div className="pay-password">
                        <Input type={this.state.baomi == true ? '' : 'password'} autocomplete="new-password" value={this.state.key} onChange={this.changeKey.bind(this)}></Input>
                        <Icon type={this.state.baomi == true ? 'eye' : 'eye-invisible'} onClick={this.changeBaomi.bind(this)}></Icon>
                        <a style={{color:'red',marginLeft:'30px',marginTop:'6px'}} target="_blank"  href="https://kf.qq.com/faq/180830UVRZR7180830Ij6ZZz.html" >怎么获取V2密钥?</a>
                    </div>
                </div>
                <div className="pay-item">
                    <p>请输入您的V3支付密钥:</p>
                    <div className="pay-password">
                        <Input type={this.state.baomi == true ? '' : 'password'} autocomplete="new-password" value={this.state.keyV3} onChange={this.changeKeyV3.bind(this)}></Input>
                        <Icon type={this.state.baomi == true ? 'eye' : 'eye-invisible'} onClick={this.changeBaomi.bind(this)}></Icon>
                        <a style={{color:'red',marginLeft:'30px',marginTop:'6px'}} target="_blank"  href="https://kf.qq.com/faq/180830E36vyQ180830AZFZvu.html" >怎么获取V3密钥?</a>
                    </div>
                </div>
                <div className="pay-item">
                <p>API证书:</p>
                <Upload {...props} fileList={this.state.fileList} customRequest = {this.state.customRequest}>
                    <Button>
                    <Icon type="upload" /> 上传证书
                    </Button>
                </Upload>
                <p style={{padding:"0"}}>提示：此证书用于售后退款及提现，可到微信商户平台登录>帐户中心>API安全>下载证书，证书下载完需要解压，只需上传里面的pkcs12格式(apiclient_cert.p12)</p>
                </div>
                <div className="pay-item">
                    <p>API公匙:</p>
                    <Upload {...pemProps} fileList={this.state.fileList2} customRequest = {this.state.customRequest}>
                        <Button>
                        <Icon type="upload" /> 上传公匙
                        </Button>
                    </Upload>
                    <p style={{padding:"0"}}>提示：此公匙用于售后退款及提现，可到微信商户平台登录>帐户中心>API安全>下载证书，证书下载完需要解压，只需上传里面的pem格式(apiclient_key.pem)</p>
                </div>
                <div className="tips">
                    <Button type="primary" onClick={this.handIn.bind(this)}>提交</Button>
                </div>
                <Tips
                    title={"提示"}
                    isShow={this.state.isShowTips}
                    closeTip={this.closeTip.bind(this)}
                >
                    <div className="tipView">
                        <div className="about1">
                            请先授权小程序
                    </div>
                        <div className="confirm" onClick={this.goTo.bind(this)}>确认</div>
                    </div>
                </Tips>
                <Tips
                    title={"提示"}
                    isShow={this.state.isShow}
                    closeTip={this.closeTip.bind(this)}
                >
                    <div className="tipView">
                        <div className="about1">
                            {this.state.message}
                        </div>
                        <div><img src={gou}></img></div>
                    </div>
                </Tips>
            </div>
            </section>
            

        );
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(admin));