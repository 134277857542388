
import SlideModal from '../../../common/SlideModal';
import { Ajax } from '../../../utils/ajax';
import { message } from 'antd';
require('./PhoneModal.css') 
const $ = window.$;
const location = window.location 
var React = require("react");
var createReactClass = require('create-react-class');
export default createReactClass({

    getInitialState: function () {
        return {
            showModal: this.props.showModal,
            stateTime: 60,
            closeTime: 5,
            disabled: false,
            error: '',
            phoneError: '',
            codeGet: 0
        };
    },
 
    checkoutPhone: function(){
        var tel = $("#rest-phone");

        if(!tel.val().trim()){
            this.setState({phoneError:"手机号码不能为空"})
        }
        else if(!/^1[0-9]{10}$/.test(tel.val())){
            this.setState({phoneError:"手机或邮箱格式不正确"})
        }
        else if (/^1[0-9]{10}$/.test(tel.val())){
            this.setState({phoneError:""})
            return true;
        }
        return false;
    },


    nextStep: function () {
        var bodyWrapper = $('.info-body-list');
        console.log(111)
        setTimeout(function () {
            bodyWrapper
                .animate({
                    left: '-470px',
                    opacity: 1
                }, 400, function () {
                    console.log('success');
                });
        }, 500);
    },

    sendCode:function(ev){

        ev&&ev.preventDefault();
        var self = this;
        var codeBtn = $('.code-btn')
        Ajax.POST("/ldmshop/admin/sendCode",{phone:this.props.phone})
        .then(res => {
            if(res && res.code == 0){
                self.setState({disabled: true});
                codeBtn.addClass('hide')
                self.state.stateTime = 60
                var time = setInterval(function(){
                    var nowNum = self.state.stateTime;
                    if(nowNum == 0 ){
                        clearInterval(time);
                        self.setState({"disabled": false})
                        codeBtn.removeClass('hide')
                    }
                    else{
                        self.setState({"stateTime":nowNum -1})
                    }

                },1000)
            }
            else{
                message.error(res.errmsg)
            }
        })
        .catch(err => {
            message.error(err)
        })
        // $.post("/json/user/sendcode/"+this.props.phone, function(result){
        //     if(result.success){
        //         self.setState({disabled: true});
        //         codeBtn.addClass('hide')
        //         self.state.stateTime = 60
        //         var time = setInterval(function(){
        //             var nowNum = self.state.stateTime;
        //             if(nowNum == 0 ){
        //                 clearInterval(time);
        //                 self.setState({"disabled": false})
        //                 codeBtn.removeClass('hide')
        //             }
        //             else{
        //                 self.setState({"stateTime":nowNum -1})
        //             }

        //         },1000)
        //     } else {
        //         self.setState({ codeGet: 1 });
        //     }
        // })

    },

    validateCode: function () {
        var code = $('.input-code').val();
        var phone = this.props.phone;
        $.post('/json/user/reset/restphone', { account: phone, code: code },  (result) => {
            if (result.success) {
                this.nextStep();
            } else {
                this.setState({ error: result.errfor.codeerror });
            }
        });
    },

    modifyPhone: function () {
        var code = $('.input-code').val();
        var restphone = $('#rest-phone').val();
        var oldphone = this.props.phone;
        var bodyWrapper = $('.info-body-list');
        var self = this;

        if (!this.checkoutPhone()) {
            return;
        }

        $.post('/json/user/reset/updatephone', { oldphone: oldphone, code: code, restphone: restphone }, function (result) {
            if (result.success) {
                bodyWrapper.animate({
                    left: '-940px',
                    opacity: 1
                }, 400, function () {
                    console.log('success');
                });
                self.setState({closeTime: 5});
                var time = setInterval(function(){
                    var nowNum = self.state.closeTime;
                    if(nowNum == 0 ){
                        clearInterval(time);
                        self.setState({showModal: false});
                        location.reload()
                    }
                    else{
                        self.setState({"closeTime":nowNum -1})
                    }
                },1000)
            } else {
                self.setState({ phoneError: result.errfor ? result.errfor.codeerror + ', ' + result.errfor.restphoneerror : '该手机号码已经被注册了，亲'});
            }
        })
    },

    closeModal: function () {
        this.props.closeModal();
    },

    renderError: function () {
        if (this.state.codeGet === 1) {
            return <span className="code-error">
        验证码获取失败
      </span>
        }
    },

    children: function(){
        return (
<div className="info-body-list">
          <div    ref={(modalName)=>{this.modalName = modalName}} className="info-body fade in">
            <div className="modal-row item-title clearfix">
              <div className="row-header">
                <span className="item-header">手机号码：</span>
              </div>
              <div className="row-body">
                <span className="phone-val">{this.props.phone || '暂无'}</span>
                <button className="code-btn" disabled={this.state.disabled} onClick={this.sendCode}>获取验证码</button>
                <button className={this.state.disabled ? 'resend' : 'hide'}>{this.state.stateTime}s后重发</button>
                  {this.renderError()}
              </div>
            </div>
            <div className="modal-row code-row">
              <div className="row-header">
                <span>验证码：</span>
              </div>
              <div className="row-body">
                <input type="text" placeholder="请输入验证码" className='input-code' /><span className="error-tips" >{this.state.error}</span>
              </div>
            </div>
            {/* <div className="modal-row">
              
            </div> */}
            <div className="modal-row">
              <button type="button" onClick={this.validateCode} className="black-btn">确定</button>
            </div>
          </div>
          <div className="info-body">
            <div className="modal-row">
              <div className="row-header">
                <span className="tel-title">手机号码：</span>
              </div>
              <div className="row-body">
                <input type="text" placeholder="请输入你的手机号码" id="rest-phone"/>
              </div>
            </div>
            <div className="modal-row">
              <button className="black-btn" type="button" onClick={this.modifyPhone}>确定</button>
            </div>
          </div>
          <div className="info-body">
            <div className="modal-row success-row">
              <p className="icon iconfont icon-chenggong"></p>
              <span>修改成功！<br/>{this.state.closeTime}秒后返回</span>
            </div>
          </div>
        </div>
        )
    },
  
    renderslideModal: function () {
       
        
        return <SlideModal modalTitle="更改手机号码：" closeModal={this.props.closeModal} children={this.children()} showModal={this.state.showModal}></SlideModal>
    },

    render: function () {

        return (
            <div id="phone-modal" ref={(modal)=>{this.phoneModal = modal}}>
                {this.renderslideModal()}
            </div>
        );
    }
})