import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Icon, message,Input, Button ,Modal,Radio} from 'antd'
import back from '../../assets/images/login-registor-image.png'
import lingdaima from '../../assets/images/2429495A-4968-4a48-B4E2-55632BD8EEF7.png'
import account from '../../assets/images/B3EAD484-D801-41bb-BFC5-D477D51DF290.png'
import password from '../../assets/images/00FF5FB9-39CF-4771-98D7-8354D5F090DB.png'
import './CreateShop.less'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import Tips from '../../components/Tips/Tips'
import {Link} from 'react-router-dom';
import { Ajax } from '../../utils/ajax';
import gou from '../../assets/images/7DC91AF2-6243-4ca0-B0EE-813AF514ABE8.png'
import { withRouter } from 'react-router-dom'
message.config({
    top: 150,
    duration: 2,
    maxCount: 3,
  });
  const { confirm } = Modal;
const menu = [
    {
        name:"购物车",
        type:1
    },
    {
        name:"优惠券",
        type:1
    },
    {
        name:"限时促销",
        type:1
    },
    {
        name:"消息系统",
        type:1
    },
    {
        name:"分销系统",
        type:2
    },
    {
        name:"会员系统",
        type:2
    },
    {
        name:"提现管理",
        type:2
    },
    {
        name:"数据统计",
        type:2
    },
    {
        name:"专题系统",
        type:3
    },
    {
        name:"集市系统",
        type:3
    }
]
const cheap = {
    1:"188",
    2:"288",
    3:"388"
}
class CreateShop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantName:'',
            isShow:false,
            menuId:'',
            value:1,
            title:"",
            visible:false
        };
    }
    componentDidMount() {
        let token = sessionStorage.getItem("backToken")
        if(token == null){
            this.props.history.replace("/login")
            return false;
        }
    }
    componentWillMount() {
    }

    changeMerchantName(e){
        console.log(e.target.value)
        this.setState({
            merchantName:e.target.value
        })
    }
    handle(){
        let token = sessionStorage.getItem("backToken")
        console.log(token)
        if(token == null){
            message.error('您还没有登陆')
            window.location.href = "/login" 
            return false;
        }
        if(!this.state.value){
            message.error('请选择店铺版本')
            return false;
        }
        if(!this.state.merchantName){
            message.error('请输入店铺名称')
            return false;
        }
        Ajax.POST('/ldmshop/merchant/add', { merchantName: this.state.merchantName,mainServiceClass:'电商',menuId:this.state.value })
        .then(res => {
            if(res && res.code === 0){
                this.showConfirm(res.data.merchantId);
            }
            else {
                
            }
        })
        .catch(error => {
            message.error(error)
        })
    }
    showConfirm(id) {
        let that = this;
        confirm({
          title: "提示",
          content: "店铺创建成功，马上去查看效果",
          cancelText:"取消",
          okText:"确认",
          onOk() {
              that.props.history.push("/admin/testh5",{id:id});
              sessionStorage.setItem("key", 14);
          },
          onCancel() {
            that.props.history.push("/admin/listPage");
          },
        });
      }
      onChange(e) {
        console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
      mone(){
          let value = this.state.value;
          if(!value){
              message.warn("请先选择版本",2);
              return false
          }
          let title = value == 1 ? "通用版" : value == 2 ? "高级版" : "旗舰版"
          this.setState({
            title:title,
            visible:true
          })
      }
    render() {
        return (
            <div id="create">
                <Header type="home" active="home" cur="home"></Header>
                <div className="options">
                    <p className="title"><strong>创建店铺</strong></p>
                    <div className="content1">
                        <div className="item">
                            <div className="name">店铺名称:</div>
                            <Input className="input" value={this.state.merchantName} onChange={this.changeMerchantName.bind(this)}></Input>
                        </div>
                        <div className="item">
                            <div className="name">店铺版本:</div>
                            <Radio.Group onChange={this.onChange.bind(this)} value={this.state.value}>
                                <Radio value={1}>通用版</Radio>
                                <Radio value={2}>高级版[推荐]</Radio>
                                <Radio value={3}>旗舰版</Radio>
                                <div className="mone" onClick={this.mone.bind(this)}>[功能查看]</div>
                            </Radio.Group>
                            
                        </div>
                        <div className="item">
                            <div className="name">店铺费用:</div>
                            {/* <div className="value">免费试用</div> */}
                            <div className="value"><span style={{textDecoration:"line-through"}}>{cheap[this.state.value]}元/月</span>  (现30天免费试用)</div>
                        </div>
                        <div className="flex">
                        <Button type="primary" onClick={this.handle.bind(this)}>马上创建</Button>
                        </div>
                    </div>
                    <Modal
                        title="各版本功能查看"
                        visible={this.state.visible}
                        onOk={()=>this.setState({visible:false})}
                        onCancel={()=>this.setState({visible:false})}
                        cancelText="取消"
                        okText="确认"
                    >
                        <div  style={{display:'flex',justifyContent:"space-around"}}>
                            <p  style={{textAlign: 'center',color: '#000',fontWeight:"bold"}} >通用版</p>
                            <p  style={{textAlign: 'center',color: '#000',fontWeight:"bold"}} >高级版</p>
                            <p  style={{textAlign: 'center',color: '#000',fontWeight:"bold"}} >旗舰版</p>
                        </div>
                        {
                            menu.map((item,index)=>{
                                return <div key={index} style={{display:'flex',justifyContent:"space-around"}}>
                                    <p  style={item.type <= 1 ? {textAlign: 'center',color: '#000'}:{textAlign: 'center',color: '#999999',textDecoration:"line-through"}} >{item.name}</p>
                                    <p  style={item.type <= 2 ? {textAlign: 'center',color: '#000'}:{textAlign: 'center',color: '#999999',textDecoration:"line-through"}} >{item.name}</p>
                                    <p  style={item.type <= 3 ? {textAlign: 'center',color: '#000'}:{textAlign: 'center',color: '#999999',textDecoration:"line-through"}} >{item.name}</p>
                                </div>
                            })
                        }
                    </Modal>
                    {/* <div className="item-options">
                        <Input value={this.state.merchantName} onChange={this.changeMerchantName.bind(this)}></Input>
                    </div> */}
                    {/* <Button type="primary" onClick={this.handle.bind(this)}>提交</Button> */}
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
export default withRouter(CreateShop);