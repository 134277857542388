import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Radio } from 'antd'
import Button from 'antd/es/button';
import success from '../../assets/images/7DC91AF2-6243-4ca0-B0EE-813AF514ABE8.png'

import './AuthError.less'

const location = window.location
class PaySuccess extends Component {
    state = {
       name:'',
       update:true
    };
    componentDidMount() {
        var param = decodeURIComponent(location.search ? location.search.replace("?",""):"");
        let arr = param.split("&");
        arr.forEach(item=>{
            let qurey=item.split('=');
            if(qurey.length == 2){
                this.setState({
                    [qurey[0]]:qurey[1]
                })

            }
        })
  
    }
    componentWillMount() {
    }

   

    render() {
        console.log(this.state)
        return (
            <div id="Authsuccess">
                <div className="icon">
                    <img src="https://dotlinkface-lingdaima.oss-cn-hangzhou.aliyuncs.com/1583897956433"></img>
                    
                </div>
                    <div className="kaitong">{this.state.title}</div>
                
            </div>
        )
    }
}
export default PaySuccess;