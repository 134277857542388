import { Ajax } from '../../../utils/ajax';
import { Modal,message } from 'antd';
import Tips from '../../../components/Tips/Tips'
import * as React from 'react';
import * as ReactDOM from 'react-dom';


function confirm(url,title,content,reurl,key) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function closeTip(){
    document.body.removeChild(div)
  }
  // eslint-disable-next-line no-use-before-define
  ReactDOM.render(<Tips title={title||"提示"} closeTip={closeTip} isShow={true} width="400px" height="200px" >
    <div style={{margin:"10px 21px 0px 21px",position:"relative"}}>
    <div style={{fontSize:'14px',color:"#333333",height:"120px"}}>{content||'是否已完成授权'}</div>
      <div style={{ display:"flex",justifyContent:"flex-end"}}>
        <span style={{marginRight:"10px",cursor:"pointer",display:"inline-block",width:"80px",height:"30px",lineHeight:"30px",border:"1px solid #eee",borderRadius:"5px",textAlign:"center"}} onClick={()=>{
          window.open(url);
        }}>重试</span>
        <span style={{marginRight:"10px",cursor:"pointer",display:"inline-block",width:"80px",height:"30px",lineHeight:"30px",border:"1px solid #019932",borderRadius:"5px",backgroundColor:"#019932",textAlign:"center",color:"#fff"}} onClick={()=>{
          if(!!reurl){
            window.location.href = reurl;
            sessionStorage.setItem("key", key);
          }else{
              window.location.href = window.location.href;
          }
        }}>确认</span>
        </div>
    </div>
  </Tips>, div);

}

// function showConfirm(url,title,content,reurl,key) {
//     confirm({
//       title: title||'提示',
//       content: content||'是否已完成授权',
//       cancelText:"重试",
//       okText:"确认",
 
//       onOk() {
//         if(!!reurl){
//           window.location.href = reurl;
//           sessionStorage.setItem("key", key);
//         }else{
//             window.location.href = window.location.href;
//         }
//       },

//       onCancel(e) {
//         console.log(e);
//             window.open(url);
//             showConfirm(url,title,content);
//       }
//     });
//   }
function getAuthCode(merchantId,title,content,url,key){
    Ajax.GET(`/ldmshop/auth/officialGuideAuth?merchantId=${merchantId}`)
    .then(res => {
        if (res.code == 0) {
            window.open(res.data);
            confirm(res.data,title,content,url,key)
        } else {
            message.error(res.errmsg)
        }
    })
}
export  {
    getAuthCode
}