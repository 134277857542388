import React, { Component } from 'react';
import Menu from '../../../components/Menu/Menu'
import { Ajax } from '../../../utils/ajax'
import { moneyReg } from '../../../utils/regexp'
import * as Admin from '../store/admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'
import secret from '../../../utils/secret';
import './alipayWithdrawal.less'
import { Input , Button ,message } from 'antd';
class alipayWithdrawal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            money:"",
            alipay:"",
            amount:""
        }
    }
    componentDidMount() {
        this.getConfig();
    }
    getConfig(){
        // /ldmshop/withdraw/merchant/queryMerchantWithdraw?merchantId=11
        Ajax.GET(`/ldmshop/withdraw/merchant/queryMerchantWithdraw?merchantId=${this.props.adminReducer.shopData.merchantId}`)
            .then(res => {
                if (res.code == 0) {
                    this.setState({
                        alipay:res.data.aliNumber,
                        amount:res.data.amount
                    })
                } else {
                    message.error(res.errmsg,2)
                }
            })
            .catch(err=>{
                message.error(err,2)
            })
    }
    // /ldmshop/withdraw/merchant/ask?money=1&merchantId=1
    withdraw(){
        const { money } = this.state;
        if(!moneyReg.test(money)){
            message.error("请输入正确金额！", 2);
            return false;
        }
        Ajax.GET(`/ldmshop/withdraw/merchant/ask?money=${money}&merchantId=${this.props.adminReducer.shopData.merchantId}`)
        .then(res => {
            if (res.code == 0) {
               
            } else {
                message.error(res.errmsg,2)
            }
        })
        .catch(err=>{
            message.error(err,2)
        })
    }
    onChange( e) {
        this.setState({
            money: e.target.value
        })
    }
    goTo(){
        sessionStorage.setItem("key", 15)
        this.props.history.replace("/admin/alipayConfig");
        
    }
    goToOrder = () => {
        if(this.props.adminReducer.shopData.merchantId!=undefined){
            Ajax.GET(`/ldmshop/merchant/merChantToken?merChantId=${this.props.adminReducer.shopData.merchantId}`)
            // http.get(`/ldmshop/merchant/merChantToken?merChantId=${this.props.adminReducer.shopData.merchantId}`)
                .then(res => {
                    if (res.code == 0) {
                        let data = res;
                        data.data.roleList[0] = "ROLE_SUPER_ADMIN";
                        data.data.name = data.data.phone;
                        let shopData = sessionStorage.getItem("shopData")
                        let shopName = JSON.parse(shopData).merchantName
                        let shopId = JSON.parse(shopData).merchantId
                        let logo = JSON.parse(shopData).logo
                        let menuId = JSON.parse(shopData).menuId
                        let mainAppId = JSON.parse(shopData).mainAppId
                        let type = "h5Order";
                        if(logo==null){
                            logo=''
                        }
                        let env = process.env.NODE_ENV;
                        let url = "";
                        let userData = `data=${JSON.stringify(data)}&shopName=${shopName}&miniHeadImg=${logo}&shopId=${shopId}&menuId=${menuId}&appId=${mainAppId}&type=${type}`
                        userData=secret.Encrypt(userData)
                        if (env === 'development') {
                            url = `http://localhost:8000/user/login?${userData}`
                          } else if (env === 'production') {
                            url = `http://admin.sifshop.cn/user/login?${userData}`
                          }
                        window.location.href = url;
                    } else {
                        message.error(res.errmsg);
                    }
                })
        }
        else{
            this.setState({
                isShowTips:true
            })
        }
        
    }
    render() {
        return <section>
            <Menu></Menu>
            <div className="alipayWithdrawal">
                <div className="title">
                    提现申请
                </div>
                <div className="content">
                    <div className="list">
                        <div>
                            提现到支付宝帐号：
                        </div>
                        <div className="width">
                            <span className="color">{this.state.alipay}</span><span className="button1" onClick={this.goTo.bind(this)}>修改</span>
                        </div>
                    </div>
                    <div className="list">
                        <div>
                        可提金额：
                        </div>
                        <div className="width red">
                            {this.state.amount}元
                        </div>
                    </div>
                    <div className="tips">
                        提现金额来自网页端已完成的订单 <span className="button1" onClick={this.goToOrder.bind(this)}>查询订单</span>
                    </div>
                    <div className="list">
                        <div>
                            提现金额：
                        </div>
                        <div style={{display:"flex",alignItems:'center'}}>
                            <Input value={this.state.money} onChange={this.onChange.bind(this)}></Input>元
                        </div>
                    </div>
                    <div className="tips">
                        最低提现额度100元
                    </div>
                    <Button type="primary" className="button" onClick={this.withdraw.bind(this)} >确定</Button>
                </div>
            </div>
        </section>
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(alipayWithdrawal));