import ActionTypes from './action';
let shopData = sessionStorage.getItem('shopData') ? JSON.parse(sessionStorage.getItem('shopData')) : {};
let shopInfo = sessionStorage.getItem('shopInfo') ? JSON.parse(sessionStorage.getItem('shopInfo')) : {};


let xcxData = sessionStorage.getItem('xcxData') ? JSON.parse(sessionStorage.getItem('xcxData')) : {};
let selectData = sessionStorage.getItem('selectData') ? JSON.parse(sessionStorage.getItem('selectData')) : {};
let initialState = {
    shopData: shopData,
    xcxData: xcxData,
    selectData: selectData,
    shopInfo:shopInfo
};
function adminReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CHIOSCE_XCX_ID:
            sessionStorage.setItem("shopData", JSON.stringify(action.data))
            return {
                ...state,
                shopData: action.data
            };
        case ActionTypes.SELECT_XCX_DATA:
            sessionStorage.setItem("xcxData", JSON.stringify(action.data))
            return {
                ...state,
                xcxData: action.data
            }
        case ActionTypes.SET_XCX_ID:
            sessionStorage.setItem("selectData", JSON.stringify(action.data))
            return {
                ...state,
                selectData: action.data
            }
        case ActionTypes.NOW_IN_SHOP:
            sessionStorage.setItem("shopData", JSON.stringify(action.data))
            return {
                ...state,
                shopData: action.data
            }
        case ActionTypes.SHOP_INFO:
            sessionStorage.setItem("shopInfo", JSON.stringify(action.data))
            return {
                ...state,
                shopInfo: action.data
            }
        default:
            return state
    }
}

export default adminReducer;