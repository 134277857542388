import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../../components/Header/Header'
import Menu from '../../../components/Menu/Menu'
import { Switch, RouteWithSubRoutes } from 'react-router-dom'
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute'
import { withRouter } from 'react-router-dom'
import * as Admin from '../store/admin';
import Tips from '../../../components/Tips/Tips'
import http from '../../../http/http'
import { message, Input, Button, Icon } from 'antd'
import './test.less'
import { Ajax } from '../../../utils/ajax';

class admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xcxCode: '',
            mainAppId: 1,
            isShowTips: false
        }
    }
    componentDidMount() {
        this.getShopData(this.props.adminReducer.shopData.merchantId)

    }
    getShopData(id) {
        Ajax.GET(`/ldmshop/merchant/one/${id}`)
            .then(res => {
                if (res && res.code == 0) {
                    this.props.actions.setShopData(res.data);
                    if (!this.props.adminReducer.shopData.mainAppId) {
                        this.setState({
                            isShowTips: true
                        })
                    }
                
                    let selectData = this.props.adminReducer.shopData;
                    this.setState({
                        mainAppId: selectData.mainAppId
                    }, () => {
                        this.getData()
                    })

                } else {
                    message.error(res.errmsg)
                }
            })
    }
    getData() {
        Ajax.GET(`/ldmshop/mini/data/testQrCode?authAppId=${this.state.mainAppId}`)
            .then(res => {
                if (res && res.code == 0) {
                    console.log('success')
                    this.setState({
                        xcxCode: res.data
                    }, () => {
                        console.log(this.state.xcxCode)
                    })
                }
            })
    }
    closeTip() {
        this.setState({
            isShowTips: false
        })
    }
    goTo() {
        this.props.history.replace("/admin/mainPage")
        sessionStorage.setItem("key", 2);
    }
    componentWillMount() {

    }
    componentWillUnmount() {

    }
    go() {
        this.props.history.push({ pathname: "/admin/Submission" });
        sessionStorage.setItem("key", 12);
        
    }

    render() {

        return (
            <section>
                <Menu ></Menu>

                <div id="test" >
                    <div className="testPage">
                        <div className="title">微信小程序配置</div>
                        <div className="tabs">
                            <span >小程序授权</span>
                            <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                            <span className="active">小程序预览</span>
                            <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                            <span>小程序提交审核</span>
                            <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                            <span >小程序完成发布</span>
                        </div>
                        {
                            this.state.xcxCode == '' ? <div className="test"><p className="advice">您暂时没有授权的小程序</p></div>
                                :
                              
                                <div className="test">
                                    <div className="testView">
                                    {/* <div className="testViewItem">
                                    <p className="advice">您的h5网站</p>
                                    <iframe  src="http://shop.lingdaima.cn:8080/"></iframe>
                                    </div> */}
                                    <div className="testViewItem">
                                    <p className="advice">请用微信扫码预览你的小程序体验版</p>
                                    <div>
                                        <img src={this.state.xcxCode} className="test-img"></img>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="button" style={{ marginTop: '20px' }} onClick={this.go.bind(this)}>
                                        去审核<span className="iconfontAdmin iconshuangjiantou-copy"></span>
                                    </div>
                                </div>
                               

                        }
                    </div>
                    <Tips
                        title={"提示"}
                        isShow={this.state.isShowTips}
                        closeTip={this.closeTip.bind(this)}
                    >
                        <div className="tipView">
                            <div className="about1">
                                请先授权小程序
                    </div>
                            <div className="confirm" onClick={this.goTo.bind(this)}>确认</div>
                        </div>
                    </Tips>

                </div>
            </section>


        );
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(admin));