import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/Header/Header'
import config from '../../route/config'
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import {  Switch, BrowserRouter } from 'react-router-dom'
import './index.less'
function RenderRouters({routeConfig}) {
    return routeConfig.map( (item,index) => {
        return <Fragment key={index}>
            <PrivateRoute
                path={item.Link}
                component={item.component}
                hasHeader={item.hasHeader}
                router={item.router}
            ></PrivateRoute>
        </Fragment>
    })
}
class Index extends Component {
    componentDidMount() {
        let url = this.GetUrlRelativePath()
        console.log(url)
        if(url == '/'){
            window.location.href = "/home" 
        }
    }
    componentWillMount() {
    }
    tab() {

    }
    GetUrlRelativePath()
　　{
　　　　var url = document.location.toString();
　　　　var arrUrl = url.split("//");

　　　　var start = arrUrl[1].indexOf("/");
　　　　var relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符

　　　　if(relUrl.indexOf("?") != -1){
　　　　　　relUrl = relUrl.split("?")[0];
　　　　}
　　　　return relUrl;
　　}
    render() {
        return (
            <div className="Index">
                <BrowserRouter >
                {/* <Header></Header> */}
                    <Switch>
                        <RenderRouters routeConfig={config}></RenderRouters>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}
export default Index;