import React, { Component } from 'react';
import Menu from '../../../components/Menu/Menu'
import { Ajax } from '../../../utils/ajax'
import * as Admin from '../store/admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'
import './alipayList.less'
import { Input , Button , Table,message } from 'antd';
const stuteText = {
    1:"审核中",
    2:"未打款",
    3:"已打款",
    4:"不通过",
    5:"支付失败"
}
const dataSource = [
    {
        time:"2020-12-12 12:12",
        much:"1,000元",
        state:"待审核",
        remake:""
    },
    {
        time:"2020-12-12 12:12",
        much:"1,000元",
        state:"已到帐",
        remake:"支付宝单号：XXXXXX"
    },
    {
        time:"2020-12-12 12:12",
        much:"1,000元",
        state:"待审核",
        remake:"原因：XXXXXX"
    }
]

class alipayList extends Component {
    constructor(props) {
        super(props);
        this.state = {
             columns : [
                {
                  title: '提现申请时间',
                  dataIndex: 'createDate',
                },
                {
                  title: '提现金额',
                  dataIndex: 'money',
                },
                {
                  title: '状态',
                  dataIndex: 'withdrawStatus',
                  render:(withdrawStatus)=>{
                  return <div>{stuteText[withdrawStatus]}</div>
                  }
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                  },
              ],
              tableLoading:false,
              pageSize:10,
              pageNum:1,
              total:0,
              tableData:[]
        }
    }
    componentDidMount() {
        this.getData(this.state.pageSize,this.state.pageNum)
    }
    getData(pageSize, pageNum) {
        this.setState({
          tableLoading: true,
        });
    
        Ajax.GET(`/ldmshop/withdraw/merchant/queryOnApplets?pageNum=${pageNum}&pageSize=${pageSize}&merchantId=${this.props.adminReducer.shopData.merchantId}`)
        .then(res => {
            if (res.code == 0) {
                let data = res.data;
                this.setState({
                    tableData: data.content,
                    pageSize: data.size || 10,
                    pageNum: data.number || 1,
                    total: data.totalElements,
                    tableLoading: false,
                  });
            } else {
                message.error(res.errmsg,2)
            }
        })
        .catch(err=>{
            message.error(err,2)
        })
      }
    
    render() {
        return <section>
            <Menu></Menu>
            <div className="alipayList">
                <div className="title">
                提现记录
                </div>
                <div className="content">
                    <Table
                    dataSource={this.state.tableData}
                    columns={this.state.columns}
                    loading={this.state.tableLoading}
                    pagination={{
                    showSizeChanger: false,
                    showQuickJumper: true,
                    onShowSizeChange: (current, pageSize) => {
                        this.getData(pageSize, current);
                    },
                    pageSize: this.state.pageSize,
                    onChange: (page, pageSize) => {
                        this.getData(pageSize, page);
                    },
                    current: this.state.pageNum,
                    total: this.state.total,
                    }}
                    ></Table>
                </div>
            </div>
        </section>
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(alipayList));