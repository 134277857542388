var React = require("react");
var createReactClass = require('create-react-class');
require('./SlideModal.css');
const $ = window.$;
export default createReactClass({
  getInitialState: function () {
    return {
      showState: ''
    };
  },

  getDefaultProps: function () {
    
  },
  componentDidMount: function () {
    console.log(this.props.showModal);
    if (this.props.showModal) {
      $(this.refs['create-modal']).modal('show')
    } else {
      console.log('close....')
      if (this.refs['create-modal']) {
        $(this.refs['create-modal']).modal('hide')
      }
    }
    
  },

  componentDidUpdate:function () {
    if (this.props.showModal) {
      $(this.refs['create-modal']).modal('show')
    } else {
      console.log('close....')
      if (this.refs['create-modal']) {
        $(this.refs['create-modal']).modal('hide')
      }
    }
  },
  renderItem: function () {

  },

  backToInit: function () {
    this.props.closeModal();
  },

  render: function () {
    return (
      <div ref="create-modal" id="create-modal" className="modal fade"  role="dialog" data-backdrop="true" aria-labelledby="infoLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="info-header">
                <span className="info-title">{this.props.modalTitle}</span>
                <span><i className="icon iconfont icon-close" onClick={this.backToInit} style={{color: '#fff', fontSize: '20px'}}></i></span>
              </div>
              <div ref="info-body-wrapper" className="info-body-wrapper">
                  {this.props.children}
                  
              </div>
            </div>
          </div>
        </div>
    )
  }
});
