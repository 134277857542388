import React, { Component, Fragment } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Icon, Modal, Message } from 'antd'
import Button from 'antd/es/button';
import Menu from 'antd/es/menu'
import { Link } from 'react-router-dom';
import lingdaima from '../../assets/images/2429495A-4968-4a48-B4E2-55632BD8EEF7.png'
import right from '../../assets/images/7C25EC36-D3DC-4af4-8C01-D4DE2B3BDA4D.png'
import shangpin from '../../assets/images/商品.png'
import zhongcao from '../../assets/images/种草.png'
import zhuanti from '../../assets/images/专题.png'
import banner from '../../assets/images/banner.png'
import youhui from '../../assets/images/优惠券.png'
import xiaoxi from '../../assets/images/push.png'
import jinbi from '../../assets/images/金币.png'
import xianshi from '../../assets/images/促销.png'
import shuju from '../../assets/images/数据.png'

import './Home.less'
import '../../iconfont/download/iconfont.css'
import { Ajax } from '../../utils/ajax';

import Testinterface from '../../common/Testinterface'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
const $ = window.$;
const location = window.location
const { SubMenu } = Menu;
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            navmobile: false
        }
    }
    componentDidMount() {
        if ($(window).width() <= 768) {
            this.setState({
                navmobile: true
            }, () => {
                console.log(this.state.navmobile)
            })
        }

        var param = location.search ? location.search.split('=')[1] : ''
        console.log(!!param == true)
        if (!!param == true) {
            Ajax.GET(`/ldmshop/admin/logout`)
                .then(res => {
                    if (res && res.code === 0) {
                        sessionStorage.removeItem("backToken")
                        sessionStorage.removeItem("phoneNumber")
                        sessionStorage.removeItem("headImg")
                        sessionStorage.removeItem("name")
                        location.href = "/home"
                    }
                })
                .catch(error => {
                })
        }
    }
    componentWillMount() {
    }
    componentWillUnmount(){
        console.log(1111);
    }
    handleClick = e => {
        console.log('click ', e);

        this.setState({
            current: e.key,
        }, () => {

        });

    };
    showmodel() {
        let show = this.state.show
        this.setState({
            show: !show
        })
    }

    pcCate() {
        return (
            <div className="cate">
                <div className="cate-one">
                    {/* <div className="cate-body">
                                <img src={cate1}></img>
                                <div><p>全部</p></div>
                            </div> */}
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/111.png"></img>
                        <div><p>饰品</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/222.png"></img>
                        <div><p>茶叶</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/333.png"></img>
                        <div><p>保健品</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/444.png"></img>
                        <div><p>数码</p></div>
                    </div>
                    {/* </div> */}

                    {/* <div className="cate-one"> */}
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/555.png"></img>
                        <div><p>服饰</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/666.png"></img>
                        <div><p>化妆品</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/777.png"></img>
                        <div><p>土地产</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/888.png"></img>
                        <div><p>美食</p></div>
                    </div>
                    {/* <div className="cate-body">
                                <img src={cate10}></img>
                                <div><p>其他</p></div>
                            </div> */}
                </div>
            </div>
        )
    }

    phoneCate() {
        return (
            <div className="cate">
                <div className="cate-one">

                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/111.png"></img>
                        <div><p>饰品</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/222.png"></img>
                        <div><p>茶叶</p></div>
                    </div>
                </div>
                <div className="cate-one">
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/333.png"></img>
                        <div><p>保健品</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/444.png"></img>
                        <div><p>数码</p></div>
                    </div>
                </div>



                <div className="cate-one">
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/555.png"></img>
                        <div><p>服饰</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/666.png"></img>
                        <div><p>化妆品</p></div>
                    </div>
                </div>
                <div className="cate-one">
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/777.png"></img>
                        <div><p>土地产</p></div>
                    </div>
                    <div className="cate-body">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/888.png"></img>
                        <div><p>美食</p></div>
                    </div>
                </div>
            </div>
        )

    }

    pcProcess() {
        return (
            <div className="process">
                <div className="process-one">
                    <div>
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/faxian.png" ></img>
                        <p>底部发现栏</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/fenxiang.png" ></img>
                        <p>好友间分享</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/liaotian.png" ></img>
                        <p>聊天顶部</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/tubiao-.png" ></img>
                        <p>微信搜索</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/guanlian.png" ></img>
                        <p>公众号关联</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/zhifu.png" ></img>
                        <p>支付完成页</p>
                    </div>
                </div>

                <div className="process-one">
                    <div>
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/xiaochengxu.png" ></img>
                        <p>附近小程序</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/qianbao.png" ></img>
                        <p>我的钱包</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/saoma.png" ></img>
                        <p>线下扫码</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/qiabao.png" ></img>
                        <p>我的卡包</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/hudong.png" ></img>
                        <p>小程序间互链</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/churukou.png" ></img>
                        <p>更多入口</p>
                    </div>
                </div>


            </div>
        )
    }

    phoneProcess() {
        return (
            <div className="process">
                <div className="process-one">
                    <div>
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/faxian.png" ></img>
                        <p>底部发现栏</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/fenxiang.png" ></img>
                        <p>好友间分享</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/liaotian.png" ></img>
                        <p>聊天顶部</p>
                    </div>

                </div>
                <div className="process-one">
                    <div >
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/tubiao-.png" ></img>
                        <p>微信搜索</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/guanlian.png" ></img>
                        <p>公众号关联</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/zhifu.png" ></img>
                        <p>支付完成页</p>
                    </div>

                </div>

                <div className="process-one">
                    <div >
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/xiaochengxu.png" ></img>
                        <p>附近小程序</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/qianbao.png" ></img>
                        <p>我的钱包</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/saoma.png" ></img>
                        <p>线下扫码</p>
                    </div>
                </div>

                <div className="process-one">
                    <div >
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/qiabao.png" ></img>
                        <p>我的卡包</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/hudong.png" ></img>
                        <p>小程序间互链</p>
                    </div>
                    <div className="kont">
                        <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/churukou.png" ></img>
                        <p>更多入口</p>
                    </div>
                </div>
            </div>
        )
    }

    pckino() {
        return (
            <div>

                <div className="kino" style={{ marginTop: '41px' }}>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E7%9B%B4%E6%92%AD%E5%B8%A6%E8%B4%A7.png" ></img>
                        <p>直播带货</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%95%86%E5%93%81%E4%BA%A4%E6%98%93.png" ></img>
                        <p>商品交易</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E9%9B%86%E5%B8%82%E5%88%86%E4%BA%AB%EF%BC%88%E6%94%B9%E6%88%90%E7%A7%8D%E8%8D%89%E5%88%86%E4%BA%AB%EF%BC%89.png" ></img>
                        <p>集市分享</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E4%B8%93%E9%A2%98%E8%81%9A%E5%90%88.png" ></img>
                        <p>专题聚合</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/banner%E7%81%B5%E6%B4%BB.png" ></img>
                        <p>banner灵活</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A4%9A%E7%A7%8D%E4%BC%98%E6%83%A0%E5%88%B8.png" ></img>
                        <p>多种优惠券</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src={"https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E6%B6%88%E6%81%AF%E9%80%9A%E7%9F%A5.png"} ></img>
                        <p>消息通知</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E9%87%91%E5%B8%81%E5%95%86%E5%9F%8E.png" ></img>
                        <p>金币商城</p>
                    </div>
                </div>
                <div className="kino" >
                    <div >
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A4%9A%E7%AB%AF%E8%A6%86%E7%9B%96.png" ></img>
                        <p>多端覆盖</p>
                    </div>
                    <div >
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%88%86%E9%94%80%E4%BD%93%E7%B3%BB.png" ></img>
                        <p>分销体系</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E4%BB%A3%E7%90%86%E6%A8%A1%E5%BC%8F.png" ></img>
                        <p>代理模式</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>

                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A4%9A%E4%BA%BA%E6%8B%BC%E5%9B%A2.png" ></img>
                        <p>多人拼团</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E9%99%90%E6%97%B6%E4%BF%83%E9%94%80.png" ></img>
                        <p>限时促销</p>
                    </div>
                    {/* <div className="kont">
                            <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/youhuiquan.png" style={{}}></img>
                            <p>优惠券</p>
                        </div> */}
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A5%BD%E5%8F%8B%E7%A0%8D%E4%BB%B7.png" ></img>
                        <p>好友砍价</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/VIP%E4%BD%93%E7%B3%BB.png" ></img>
                        <p>VIP体系</p>
                    </div>
                    <div className={this.state.navmobile == false ? 'kont' : ''}>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90.png" ></img>
                        <p>数据分析</p>
                    </div>
                </div>
            </div>
        )
    }
    phonekino() {
        return (
            <div style={{width:"100%"}}>
                <div className="kino" style={{ marginTop: '41px' }}>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E7%9B%B4%E6%92%AD%E5%B8%A6%E8%B4%A7.png" ></img>
                        <p>直播带货</p>
                    </div>
                    <div className="kont" >
                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%95%86%E5%93%81%E4%BA%A4%E6%98%93.png" ></img>
                        <p>商品交易</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E9%9B%86%E5%B8%82%E5%88%86%E4%BA%AB%EF%BC%88%E6%94%B9%E6%88%90%E7%A7%8D%E8%8D%89%E5%88%86%E4%BA%AB%EF%BC%89.png" ></img>
                        <p>集市分享</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E4%B8%93%E9%A2%98%E8%81%9A%E5%90%88.png" ></img>
                        <p>专题聚合</p>
                    </div>
                    
                </div>
                <div className="kino" style={{ marginTop: '41px' }}>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/banner%E7%81%B5%E6%B4%BB.png" ></img>
                        <p>banner灵活</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A4%9A%E7%A7%8D%E4%BC%98%E6%83%A0%E5%88%B8.png" ></img>
                        <p>多种优惠券</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E6%B6%88%E6%81%AF%E9%80%9A%E7%9F%A5.png" ></img>
                        <p>消息通知</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E9%87%91%E5%B8%81%E5%95%86%E5%9F%8E.png" ></img>
                        <p>金币商城</p>
                    </div>
                    
                </div>
                <div className="kino" style={{ marginTop: '41px' }}>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A4%9A%E7%AB%AF%E8%A6%86%E7%9B%96.png" ></img>
                        <p>多端覆盖</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%88%86%E9%94%80%E4%BD%93%E7%B3%BB.png" ></img>
                        <p>分销体系</p>
                    </div>
                    
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E4%BB%A3%E7%90%86%E6%A8%A1%E5%BC%8F.png" ></img>
                        <p>代理模式</p>
                    </div>
                    <div className="kont">

                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A4%9A%E4%BA%BA%E6%8B%BC%E5%9B%A2.png" ></img>
                        <p>多人拼团</p>
                    </div>
                </div>
                <div className="kino" style={{ marginTop: '41px' }}>
                    
                    {/* <div className="kont">
                            <img src="https://dotlinkface-cdn.oss-cn-shanghai.aliyuncs.com/sass/youhuiquan.png" style={{}}></img>
                            <p>优惠券</p>
                        </div> */}
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E9%99%90%E6%97%B6%E4%BF%83%E9%94%80.png" ></img>
                        <p>限时促销</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E5%A5%BD%E5%8F%8B%E7%A0%8D%E4%BB%B7.png" ></img>
                        <p>好友砍价</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/VIP%E4%BD%93%E7%B3%BB.png" style={{}}></img>
                        <p>VIP体系</p>
                    </div>
                    <div className="kont">
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90.png" ></img>
                        <p>数据分析</p>
                    </div>
                </div>
    
            </div>
        )
    }

    copy() {
        var clickContent = window.location.href;
        var inputElement = document.getElementById("copy_content");  //获取要赋值的input的元素
        inputElement.value = clickContent;  //给input框赋值   
        inputElement.select();//选中input框的内容
        document.execCommand("Copy");// 执行浏览器复制命令
        Message.success("复制网站成功", 2)
    }

    render() {
        return (
            <div id="home">
                <img src="https://shop.lingdaima.cn/ldmshop/withdraw/merchant/queryOnApplets?pageNum=1&pageSize=10&merchantId=22"></img>
                <input id="copy_content" style={{ visibility: false, position: "absolute" }}></input>
                <Header type="home" active="home" cur="home"></Header>
                <div className="content-blank" >
                    <div className="background" >
                        <p className="back-title">微信直播卖货，一键开启社交新零售</p>
                        <p className="back-content">零成本获客，专人运营支持，直播+会员+分销体系</p>
                        <div className="double-button">
                            <div className="build-old"><a href='/Choose'>开店直播卖货</a>1分钟预览店铺效果</div>
                            <div className="build-old"><a onClick={this.copy.bind(this)}>推荐给好友</a>好友可能感兴趣，推荐给他</div>
                            {/* <a href="/login">创建小程序</a> */}
                            {/* <Button onClick={this.showmodel.bind(this)}>体验范例</Button> */}

                        </div>
                        {/* <div className="model">
                            <img src={model}  className={this.state.show == true ? '' : 'hide'}></img>
                        </div> */}
                    </div>

                    <div className="bg">
                        <p className="itemTitle">微信生态直播卖货</p>
                        <p className="itemLable" style={{textAlign:"left",maxWidth:"700px",margin:"auto",lineHeight:"1.5em",padding:"0 20px"}}>VIP体系结合微信官方直播工具，主播快速带活店铺，直接提升转化率，销量暴增。</p>
                        <div className="view" >
                            <div className="viewItem" >
                                <div className="viewItemType" >观众端</div>
                                <div className="ViewItemImage">
                                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/zhibo.png"></img>
                                </div>
                            </div>
                            <div className="viewItem" >
                                <div className="viewItemType" >主播端</div>
                                <div className="ViewItemImage">
                                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/zhibo1.png"></img>
                                </div>
                            </div>
                            <div className="viewItem2" >
                                <div className="viewItemType" >运营端</div>
                                <div className="ViewItemImage">
                                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/zhibo2.png"></img>
                                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/zhibo3.png"></img>
                                </div>
                            </div>   
                        </div>
                    </div>

                    <div className="bg">
                        <div className="view flexwrap" >
                            <div className="left">
                                <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/daihuo.png" ></img>
                            </div>
                            <div className="right">
                                <p className="itemTitle">让无数VIP会员为你带货</p>
                                <p className="itemLable">会员邀请制开店加入，2个VIP等级，5大VIP特权，利用社交人脉，零成本邀请更多人加入，共创事业。</p>
                                <div className="imageList">
                                    <div className="item">
                                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/A.png"  ></img>
                                        <div className="itemName">邀请开店可赚钱</div>
                                    </div>
                                    <div className="item">
                                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/B.png"  ></img>
                                        <div className="itemName">分享可赚钱</div>
                                    </div>
                                    <div className="item">
                                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/C.png"  ></img>
                                        <div className="itemName">购物可赚钱</div>
                                    </div>
                                    <div className="item">
                                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/D.png"  ></img>
                                        <div className="itemName">多级分销团队</div>
                                    </div>
                                    <div className="item">
                                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/E.png"  ></img>
                                        <div className="itemName">会员专属商品</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg">
                        <div className="view flexwrap">
                            <div className="view1-left">
                                <img style={{marginTop: 85}} src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/distribution.jpg" ></img>
                            </div>

                            <div className="view1-right">
                                <p className="itemTitle">二级分销裂变传播</p>
                                <p className="itemLable">两级分销体系，根据贡献程度自动成长，邀请开店及分销订单越多等级越高，等级越高赚得越多。</p>
                                <div className="item">
                                    - 两级分销体系，可获得下级和下下级的交易分成。
                                </div>
                                <div className="item">
                                    - 邀请新成员加入，对方有交易你可获得分佣。
                                </div>
                                <div className="item">
                                    - 邀请新成员开店，你直接获得高额奖励。
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="bg">
                        <div className="view">
                            <div className="view-left">
                                <p className="itemTitle">几乎不用运营成本</p>
                                <p className="itemLable">无需庞大的运营团队，专人免费协助你注册小程序、申请开通微信支付，你只需管理好小程序商城就行，技术层面就交给我们。</p>
                                <div className="img">
                                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/operate.jpg" ></img>
                                </div>
                            </div>

                            <div className="view-right">
                                <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/250X250.jpg" ></img>
                            </div>
                        </div>
                    </div>
                    
                    

                    <div className="bg">
                    <div  style ={{padding: "0 20px" ,maxWidth:'1100px', margin:'auto'}}>
                        <p className="itemTitle">满足多种行业场景</p>
                        <p className="itemLable" style={{textAlign:"center" }} >只要您想在微信等社交渠道卖货，不管什么行业，几乎都能满足您。</p>
                        {
                            this.state.navmobile == false ? this.pcCate() : this.phoneCate()
                        }
                        </div>
                    </div>

                    <div className="bg">
                    <div  style ={{padding: "0 20px" ,maxWidth:'1100px', margin:'auto'}}>
                        <p className="itemTitle">更多功能继续更新，满足社交电商一切需求</p>
                        {
                            this.state.navmobile == false ? this.pckino() : this.phonekino()
                        }
                        </div>
                    </div>

                    <div className="double-button1">
                            <div className="build-old"><a href='/Choose'>开店直播卖货</a>1分钟预览店铺效果</div>
                            <div className="build-old"><a onClick={this.copy.bind(this)}>推荐给好友</a>好友可能感兴趣，推荐给他</div>
                            {/* <a href="/login">创建小程序</a> */}
                            {/* <Button onClick={this.showmodel.bind(this)}>体验范例</Button> */}

                        </div>

                </div>

                <Footer></Footer>

                <div className="webTip">去PC端管理更方便:https://www.sifshop.cn</div>
                <Testinterface></Testinterface>
            </div>

        )
    }
}
export default Home;