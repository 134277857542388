import ActionTypes from '../action/common';
let Data = localStorage.getItem('Data')?JSON.parse(localStorage.getItem('Data')):{};
let initialState = {
    Data,
};
function commonReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ACTION:
        localStorage.removeItem("Data");
            return {

            };
        default:
            return state
    }
}

export default commonReducer;