import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import { Ajax } from '../../../utils/ajax';
import JSEncrypt from 'jsencrypt';
import { message } from 'antd';
require("./login.css")
var React = require("react");
var createReactClass = require('create-react-class');
const $ = window.$;
const location = window.location
message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
  });
export default createReactClass({
    getInitialState: function () {
        return {
            secondsElapsed: 0,
            loginErrInfo: '',
            publicKey:'',
            count:true
        };
    },
    tick: function () {
        this.setState({ secondsElapsed: this.state.secondsElapsed + 1 });
    },
    componentDidMount: function () {
        var param = location.search ? location.search.split('=')[1] : ''
        console.log(!!param==true)
        if(!!param == true){
            Ajax.GET(`/ldmshop/admin/logout`)
            .then(res => {
                if(res && res.code === 0){
                    sessionStorage.removeItem("backToken")
                    sessionStorage.removeItem("phoneNumber")
                    sessionStorage.removeItem("headImg")
                    sessionStorage.removeItem("name")
                    sessionStorage.removeItem("key");
                    sessionStorage.removeItem("shopData")
                    sessionStorage.removeItem("selectData")
                    location.href="/login"
                }
            })
            .catch(error => {
            })
        }
        else{
            let shuaxin = sessionStorage.getItem("haveReturn")
            console.log(shuaxin)
            console.log(shuaxin == 'false')
            if(shuaxin == 'false'){
                console.log(shuaxin)
                window.location.href = "/login" 
                sessionStorage.setItem("haveReturn",true)
            }
    
            Ajax.GET(`/ldmshop/admin/getKey`)
                .then(res => {
                    console.log(res)
                    this.setState({
                        publicKey: res.data.key
                    })
                })
                .catch(error => {
    
                })
        }


        

        $('.web').scrollTop(0);
        var email = $(this.refs["phone-email"]);
        var password = $(this.refs["password"]);
        $(this.refs["error-icon"]).parent().hide();

        $("#submit-login").click(function () {
            var email = $("")
        })
        email.on('blur', function () {
            this.checkoutPhone();
        }.bind(this));

        password.on("focus", () => {
            if (/^1[0-9]{10}$/.test(email.val().trim())) {
                this.setState({ phoneError: "", emailState: 0 })
                this.checkoutPassword();
                this.setState({ passwordError: "" })
                $(this.refs["error-icon"]).parent().hide();
                return true;
            }
            // else if (/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(email.val().trim())) {
            //     this.setState({ phoneError: "", emailState: 1 });
            //     this.checkoutPassword();
            //     this.setState({ passwordError: "" })
            //     $(this.refs["error-icon"]).parent().hide();
            //     return true;
            // }
        })

        password.on('blur', function () {
            this.checkoutPassword();
        }.bind(this))

        
        $(document).on('keyup', function (e) {
            if (e.keyCode == "13") {
                this.submitLogin(e);
            }
        }.bind(this));

        
    },

    checkoutPhone: function () {
        var email = $(this.refs["phone-email"]);
        $(this.refs["error-icon"]).parent().hide();

        if (!email.val().trim()) {
            $(this.refs["errors"]).text('');
            this.setState({ phoneError: "手机号码不能为空" })
            $(this.refs["error-icon"]).parent().show();
        }
        else if (!/^1[0-9]{10}$/.test(email.val()) ) {
            $(this.refs["errors"]).text('');
            this.setState({ phoneError: "手机号码格式不正确" })
            $(this.refs["error-icon"]).parent().show();
        }
        else if (/^1[0-9]{10}$/.test(email.val().trim())) {
            this.setState({ phoneError: "", emailState: 0 })
            this.checkoutPassword();
            return true;
        }
        // else if (/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(email.val().trim())) {
        //     this.setState({ phoneError: "", emailState: 1 });
        //     this.checkoutPassword();
        //     return true;
        // }



    },

    checkoutPassword: function () {
        var password = $(this.refs["password"])
        $(this.refs["error-icon"]).parent().hide();

        if (!password.val().trim()) {
            this.setState({ passwordError: "密码不能为空" })
            $(this.refs["error-icon"]).parent().show();
        }
        // else if (!/^\w{6,15}$/.test(password.val())) {
        //     this.setState({ passwordError: "密码格式不正确" })
        //     $(this.refs["error-icon"]).parent().show();
        // }
        else if (!/^[a-zA-Z0-9\W_]{8,16}$/.test(password.val())) {
            this.setState({ passwordError: "密码格式不正确" })
            $(this.refs["error-icon"]).parent().show();

            // this.setState({ ruaAllErrors: "密码格式不正确" });
            // $(this.refs["phone-err"]).show();

        }
        else {
            this.setState({ passwordError: "" })
            return true;
        }

    },
    submitLogin: function (e) {
        var self = this;
        var emailValue = $(this.refs["phone-email"]).val();
        var password = $(this.refs["password"]).val();

        
        var param = location.search ? location.search.split('=')[1] : ''      //?之后携带的参数
        if (!this.checkoutPhone() || !this.checkoutPassword()) {
            return false;
        }
        
        
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(this.state.publicKey);
            let passwordEncrypt = encrypt.encrypt(password);
            Ajax.POST('/ldmshop/admin/loginPost', { phone: emailValue, password: passwordEncrypt, type: 1 })
            .then(res => {
                // if(res.success){
                //     if(param) {
                //         location.href = '/template/market/all'      //完整的url地址链接               
                //     } else {
                //         if(sessionStorage &&  sessionStorage.getItem("redirect")){
                //             var redirect = sessionStorage.getItem("redirect")
                //             sessionStorage.removeItem("redirect");
                //             location.href = redirect;
                //         }else  if(res.defaultReturnUrl){
                //             if(res.defaultReturnUrl.indexOf("login") != -1 || res.defaultReturnUrl.indexOf("signup") != -1) {
                //                 location.href = "/home" 
                //                 return ;
                //             }                  
                //             location.href = res.defaultReturnUrl;
                //         } else{                
                //             location.href = "/home"    
                //         }            
                //     }
                //   } else {
                //       console.log("失败")
                //       if(res.errmsg){
                //         $(this.refs["error-icon"]).parent().show();
                //           this.setState({
                //             loginErrInfo: res.errmsg
                //           })
                //           location.href = "/home" 
                //       } 

                //   }
                if (res && res.code === 0) {
                    sessionStorage.setItem("backToken",res.data.backToken)
                    sessionStorage.setItem("phoneNumber",emailValue)
                    sessionStorage.setItem("name",res.data.name)
                    sessionStorage.setItem("headImg",res.data.headImg)
                    if (sessionStorage && sessionStorage.getItem("redirect")) {
                        var redirect = sessionStorage.getItem("redirect")
                        sessionStorage.removeItem("redirect");
                        location.href = redirect;
                    }
                    else{
                        
                        $(this.refs["error-icon"]).parent().show();
                          this.setState({
                            loginErrInfo: res.errmsg
                          })
                          console.log(this);
                          setTimeout(()=>{

                          })
                          console.log(sessionStorage.getItem("backToken"));
                          setTimeout(()=>{
                            //   location.href = "/admin/listPage" 
                            this.props.history.push("/admin/listPage")
                          },1000)
                        //   this.props.history.push("/admin/listPage")
                        // location.href = "/admin/listPage" 
                    }
                }
            })
            .catch(errmsg => {
                this.setState({ passwordError: errmsg })
                $(this.refs["error-icon"]).parent().show();
            })
        
        
        

       

        //   $.post("/ldmshop/admin/loginPost",{phone:emailValue,password:password,type:1}, (result) =>{ 
        //       if(result.success){
        //         if(param) {
        //             location.href = '/template/market/all'                    
        //         } else {
        //             if(sessionStorage&&  sessionStorage.getItem("redirect")){
        //                 var redirect = sessionStorage.getItem("redirect")
        //                 sessionStorage.removeItem("redirect");
        //                 location.href = redirect;
        //             }else  if(result.defaultReturnUrl){
        //                 if(result.defaultReturnUrl.indexOf("login") != -1 || result.defaultReturnUrl.indexOf("signup") != -1) {
        //                     location.href = "/home" 
        //                     return ;
        //                 }                  
        //                 location.href = result.defaultReturnUrl;
        //             } else{                
        //                 location.href = "/home"    
        //             }            
        //         }
        //       } else {
        //           console.log("失败")
        //           if(result.errors.length){
        //             $(this.refs["error-icon"]).parent().show();
        //               this.setState({
        //                 loginErrInfo: result.errors
        //               })
        //           } 

        //       }
        //   })
    },
    componentWillUnmount: function () {

    },
    render: function () {
        return (
            <div>
                <Header active="login" type="login" cur="login" ></Header>
                <div className="login-wrapper">
                    <div className="container login-page">
                        <div className="login-box-wrapper">
                            <div className="login-left-image">
                                <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/login-registor-image.png" alt="" />

                            </div>
                            <div className="login-box">
                                <div className="login-form row">
                                    {/* <div className="form-row">
                                <div className="form-header-img"></div>
                            </div> */}
                                    <div className="form-row errInfoHei">
                                        <div className="form-row-error">
                                            <span className="err-msg" ref="error-icon">{this.state.phoneError ? this.state.phoneError : this.state.passwordError}</span>
                                            <span className="err-msg" ref="errors">{this.state.loginErrInfo}</span>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-row-content">
                                            <label htmlFor="tel-email" className="login-icon"><i className="icon iconfont icon-yonghu"></i></label>
                                            <input type="text" ref="phone-email" className="tel-email" id="tel-email" placeholder="请输入您的手机号码" />
                                        </div>
                                    </div>

                                    <div className="form-row  ">
                                        {/* <div className="form-row-error">
                                <span className="err-msg">{this.state.passwordError}</span>
                                </div> */}
                                        <div className="form-row-content">
                                            <label htmlFor="password" className="login-icon"><i className="icon iconfont icon-mima"></i></label>
                                            <input type="password" ref="password" className="password" id="password" placeholder="请输入您的密码" />
                                        </div>
                                    </div>
                                    <div className="form-row  ">
                                        <div className=" form-row-content  login-btn">
                                            <button className="black-btn" type="button" onClick={this.submitLogin}>登录</button>
                                        </div>
                                    </div>
                                    <div className="forget-pwd-row">
                                        <span className="bottom-link forget-passward" style={{color:'red'}}><a href="/forgot">忘记密码</a></span>
                                        <span className="bottom-link sign-up-button"><a href="/signup">点击注册？</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
});
